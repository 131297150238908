import React from "react";
import Checkbox from "./CheckboxComponent";
import Dropdown from "./DropdownComponent";
import Input from "./InputComponent";
import NumberComponent from "./NumberComponent";
import StatesComponent from "./StatesComponent";
import DateTimeComponent from "./DateTimeComponent";
import InformationComponent from "./InformationComponent";

const isValid = (conditionalVisibilityOnField, data, operator, selectedClass) => {
  if (conditionalVisibilityOnField) {
    if (Array.isArray(conditionalVisibilityOnField)) {
      if (Boolean(operator) && operator === "or")
        return conditionalVisibilityOnField.some((each) => {
          return data[each?.name] === each.valueWhenShown;
        });
      return conditionalVisibilityOnField.every((each) => {
        if (each.name === "Class")
          return each.valueWhenShown === selectedClass
        else
          return data[each?.name] === each.valueWhenShown;
      });
    } else
      return (
        data[conditionalVisibilityOnField?.name] ===
        conditionalVisibilityOnField.valueWhenShown
      );
  } else return true;
};

export const FieldComponent = ({ field, onFieldChange, data, fieldsResult, removeKey, selectedClass }) => {
  const conditionalField = field.conditionalVisibilityOnField
  if (isValid(conditionalField, data, field.operator, selectedClass))
    return <Fields field={field} onFieldChange={onFieldChange} data={data} fieldsResult={fieldsResult} />;
  else {
    data[field.name] && removeKey(field.name)
    return null
  }
};

export const Fields = ({ field, onFieldChange, data, fieldsResult }) => {
  switch (field.dataType) {
    case "String":
      return <Input field={field} onFieldChange={onFieldChange} data={data} fieldsResult={fieldsResult} />;
    case "List":
      return <Dropdown field={field} onFieldChange={onFieldChange} data={data} fieldsResult={fieldsResult} />;
    case "Boolean":
      return <Checkbox field={field} onFieldChange={onFieldChange} data={data} fieldsResult={fieldsResult} />;
    case "Number":
      return <NumberComponent field={field} onFieldChange={onFieldChange} data={data} fieldsResult={fieldsResult} />;
    case "KnownList:States":
      return <StatesComponent field={field} onFieldChange={onFieldChange} data={data} fieldsResult={fieldsResult} />;
    case "DateTime":
      return <DateTimeComponent field={field} onFieldChange={onFieldChange} data={data} fieldsResult={fieldsResult} />;
    case "Info":
      return <InformationComponent field={field} />;
    case "info":
      return <InformationComponent field={field} />;
    default:
      return null;
  }
};
