import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import classNames from "classnames";
import * as yup from "yup";
import queryString from "query-string";
import {
  Row,
  Col,
  Button,
  Form,
  Card,
  Alert,
  InputGroup,
} from "react-bootstrap";
import {
  selectPortalSetting
} from "../GetPrice";
import Stepper from "./Stepper";
import SupplierCard from "./SupplierCard";
import { useHistory } from "react-router-dom";
import {
  selectSteps,
  selectCustomerContractInfo,
  selectCustomerInfo,
  selectCustomerAccountsInfo,
  selectUnitsForCommodity,
  selectCustomerLegalEntityName,
  selectTaxExemptValue
} from "./priceAndCustomerDetails.selectors";
import { useSelector, useDispatch } from "react-redux";
import { actions } from "./priceAndCustomerDetails.slice";
import NumberFormat from "react-number-format";
import {
  getMonth,
  getMonths,
  getMonthIndex,
  //, ALL_PRODUCT
} from "../ShowPrices";
import BusyIndicator, { REGIONS } from "../../widgets/busyIndicator";
import {
  getUnitsForCommodity,
  saveAllInformation,
} from "./priceAndCustomerDetails.asyncActions";
import {
  selectPrice,
  // selectAllProducts,
  selectTier,
  TIERS,
  COMMODITY,
} from "../GetPrice";
// import { resetState } from "../resetState";
import { toNumber } from "lodash";
import handleCloseWidget from "../../helper/handleCloseWidget";
import { isEmpty } from "lodash";
import LargeTierCard from "./LargeTierCard";
import UploadDocuments from "./UploadDocuments";
import DocumentTable from "./DocumentTable";
import analytics from "../../helper/analytics";
import { selectClientConfig } from "../clientContext";
import { getFormattedUsage } from "../utils/getFormattedUsage";

const { addToCustomerContractInfo, setSaveStatus } = actions;
const emptyArray = [];
const emptyString = "";
const CONTRACT_LENGTH_EXPRESSION = /^\d+(,\d+)*$/m;

const Contract = () => {
  const dispatch = useDispatch();
  const clientConfig = useSelector(selectClientConfig);
  const steps = useSelector(selectSteps);
  const contractData = useSelector(selectCustomerContractInfo);
  const { oid: opportunityId, did: dealId } = queryString.parse(window.location.search);
  const [uploadedFiles, setUploadedFiles] = useState(
    contractData?.files || emptyArray
  );
  const [show, setShow] = useState(false);
  // const products = useSelector(selectAllProducts);
  const customerInfo = useSelector(selectCustomerInfo);
  const customerLegalEntityName = useSelector(selectCustomerLegalEntityName);
  const history = useHistory();
  const customerAccountInfo = useSelector(selectCustomerAccountsInfo);
  const price = useSelector(selectPrice);
  const tier = useSelector(selectTier);
  const { commodity, zipCode, referralCode } = price;
  const units = useSelector(selectUnitsForCommodity);
  const taxExemptValue = useSelector(selectTaxExemptValue)
  const { isFullScreen,brokerageId } = clientConfig;
  const portalSettings = useSelector(selectPortalSetting);
  const unitsForCommodity = units.filter(
    (each) => each.commodityId === price.commodity
  );
  const defaultUnit = COMMODITY.ELECTRIC === commodity ? "kwh" : "th";
  const contractStartingDate = (year, month) => {
    const Month = new Date(Date.parse(month + " 1, 2021")).getMonth() + 1;
    const newDate = new Date(`${year}-0${Month}-01`);
    return newDate;
  };

  const [hasErrorOnUpload, setHasErrorOnUpload] = useState(false);
  // useEffect(() => {
  //   if (saveStatus) {
  //     dispatch(resetState);
  //     history.push("/");
  //   }
  // }, [dispatch, history, saveStatus]);

  useEffect(() => {
    isEmpty(units) && dispatch(getUnitsForCommodity());
  }, [dispatch, units]);

  useEffect(() => setShow(false), []);

  const validation = yup.object().shape({
    annualUsage: yup.string().required("Approximate annual usage is required"),
    // productType: yup.string().required("Product type is required"),
    startMonth: yup.string().required("Start month is required"),
    startYear: yup.string().required("Start year is Required"),
    contractLength: yup
      .string()
      .required("Contract length is required")
      .test("contractLength", "Invalid Format of Contract Length.", (value) => {
        const isValid = CONTRACT_LENGTH_EXPRESSION.test(value);
        return isValid;
      }),
    unit: yup.string().required("Unit is required"),
    files: yup.array(),
  });

  const currentMonthAndYear = getMonth();
  const initialValues = {
    annualUsage: price.usage,
    // productType: emptyString,
    startMonth: currentMonthAndYear.month,
    startYear: `${currentMonthAndYear.year}`,
    contractLength: emptyString,
    unit: unitsForCommodity[0]?.unit || defaultUnit,
    files: undefined,
  };

  const getUnitDisplayName = (unit) =>
    unitsForCommodity.find((each) => each.unitName === unit)?.displayName;

  const removeFromUploadedFiles = (index) => {
    uploadedFiles.splice(index, 1);
    setUploadedFiles([...uploadedFiles]);
  };
  const selectedMonths = getMonths();
  const year = selectedMonths.map((year) => year.year);
  const selectedYear = year.filter(
    (value, index) => year.indexOf(value) === index
  );
  const monthsOfCurrentYear = selectedMonths.filter(
    (year) => year.year === currentMonthAndYear.year
  );
  const monthsOfNextYear = selectedMonths.filter(
    (year) => year.year === currentMonthAndYear.year + 1
  );

  const onDropHandle = (acceptedFiles, rejected) => {
    if (rejected && rejected.length > 0) setHasErrorOnUpload(true);
    else setHasErrorOnUpload(false);
    const uploads = [];
    acceptedFiles &&
      acceptedFiles.forEach((file) => {
        const reader = new FileReader();
        reader.onload = (r) => {
          uploads.push({
            base64Data: r.target.result,
            fileName: file.name,
            fileType: file.type,
            size: file.size,
          });
          if (uploads.length === acceptedFiles.length) {
            setUploadedFiles([...uploadedFiles, ...uploads]);
          }
        };
        reader.readAsDataURL(file);
      });
  };

  return (
    <div className={classNames("inner-widget", {"px-20": !isFullScreen})}>
      <Card className={classNames("p-4", {"min-h-100": isFullScreen})}>
        <div className="mb-4 py-2">
          <div className="d-flex align-items-center justify-content-between">
            <Button
              variant="outline-primary"
              onClick={() => {
                dispatch(setSaveStatus());
                history.push("accounts");
              }}
            >
              Back to Accounts
            </Button>
            <b>Contract Details</b>
            <div className='d-flex align-items-center'>
            {portalSettings.phoneWork && <h6 className="sub-heading-text f-14 text-muted">Need Assistance?: Call {portalSettings.phoneWork}</h6>}
            <Button
              variant="outline-light"
              onClick={() => handleCloseWidget(dispatch, history)}
            >
              <i className="fa fa-times fa-2x f-20"></i>
            </Button>
            </div>
          </div>
        </div>
        <Row className="justify-content-center mb-3">
          <SupplierCard />
          {tier === TIERS.LARGE && <LargeTierCard />}
          <Col lg={8}>
            <Formik
              initialValues={initialValues}
              validationSchema={validation}
              enableReinitialize={true}
              onSubmit={(values) => {
                const {
                  contractLength,
                  annualUsage,
                  // productType,
                  startMonth,
                  startYear,
                  unit,
                  files,
                } = values;
                const startingMonth = getMonthIndex(startMonth);
                const result = contractLength.split(",");
                const stringArray = result.filter((num) => num !== emptyString);
                const numberArray = stringArray.map((num) => toNumber(num));
                const documents = uploadedFiles.map(({ size, ...file }) => ({
                  data: file,
                }));
                const customPricingDetails = {
                  contractStartDate: contractStartingDate(
                    startYear,
                    startMonth
                  ),
                  approximateAnnualUsage: parseInt(
                    annualUsage.replace(/,/g, "")
                  ),
                  approximateAnnualUsageUnit: unit,
                  contractLengthOptions: contractLength.toString(),
                  // product: productType,
                };

                const newCustomerContractInfo = {
                  annualUsage,
                  // productType,
                  startMonth,
                  startYear,
                  contractLength: numberArray,
                  unit,
                  files,
                };
                const meters = customerAccountInfo.map(
                  ({ utility, ...each }) => each
                );
                dispatch(
                  saveAllInformation({
                    customerData: {
                      StartYear: startYear,
                      StartMonth: startingMonth,
                      StateId: price.state.id,
                      opportunityId: opportunityId,
                      dealId: dealId,
                      class: price.class,
                      commodityTypeId: commodity,
                      annualUsageOrMonthlyBill: price.usage,
                      zipCode: zipCode,
                      promoCode: referralCode,
                      customerLegalEntityName,
                      contact: customerInfo,
                      meters,
                      documents,
                      customPricingDetails,
                      taxExemptValue,
                      brokerageId,
                    },
                  })
                ).then((res) => {
                  if (res.payload) history.push("review");
                  else setShow(true);
                });
                const dataForAnalytics = values;
                delete dataForAnalytics.files;
                analytics.sendEventWithData("Contract", dataForAnalytics);
                dispatch(addToCustomerContractInfo(newCustomerContractInfo));
              }}
            >
              {({ values, errors, touched, setFieldValue, handleSubmit }) => {
                const monthsToMap =
                  values.startYear === `${currentMonthAndYear.year}`
                    ? monthsOfCurrentYear
                    : monthsOfNextYear;

                return (
                  <div className="wizard ">
                    <Stepper pages={steps} currentPath="contract" />
                    <BusyIndicator region={REGIONS.SAVE_ALL_INFORMATION}>
                      {show && (
                        <Alert
                          variant="danger"
                          onClose={() => setShow(false)}
                          dismissible
                        >
                          <p>Unable to save Details</p>
                        </Alert>
                      )}
                      <div className="wizard-content" data-step="step1">
                        <Form.Group>
                          <Row className="mx-n1 mb-3">
                            <Col lg={6} className="px-1">
                              <Form.Label className="required">
                                When will your next contract start?
                              </Form.Label>
                            </Col>
                            <Col lg={2} className="px-1">
                              <Form.Group>
                                <Form.Control
                                  as="select"
                                  className={classNames("f-14", {
                                    error:
                                      errors.startYear && touched.startYear,
                                  })}
                                  value={values.startYear}
                                  onChange={(e) => {
                                    const year = e.target.value;
                                    const defaultChosenMonth =
                                      year === `${currentMonthAndYear.year}`
                                        ? monthsOfCurrentYear[0].month
                                        : monthsOfNextYear[0].month;
                                    setFieldValue(
                                      "startMonth",
                                      defaultChosenMonth
                                    );
                                    setFieldValue("startYear", e.target.value);
                                  }}
                                >
                                  {selectedYear.map((year) => (
                                    <option key={year} value={year}>
                                      {year}
                                    </option>
                                  ))}
                                </Form.Control>
                                {errors.startYear && touched.startYear && (
                                  <div className="error-message mt-1">
                                    {errors.startYear}
                                  </div>
                                )}
                              </Form.Group>
                            </Col>
                            <Col lg={4} className="px-1">
                              <Form.Group>
                                <Form.Control
                                  as="select"
                                  className={classNames("f-14", {
                                    error:
                                      errors.startMonth && touched.startMonth,
                                  })}
                                  value={values.startMonth}
                                  onChange={(e) =>
                                    setFieldValue("startMonth", e.target.value)
                                  }
                                >
                                  {monthsToMap.map((months, index) => (
                                    <option value={months.month} key={index}>
                                      {months.month}
                                    </option>
                                  ))}
                                </Form.Control>
                                {errors.startMonth && touched.startMonth && (
                                  <div className="error-message mt-1">
                                    {errors.startMonth}
                                  </div>
                                )}
                              </Form.Group>
                            </Col>
                          </Row>
                          <Row className="mx-n1 mb-3">
                            <Col lg={6} className="px-1">
                              <Form.Label className="required">
                                What is your approximate annual usage?
                              </Form.Label>
                            </Col>
                            <Col lg={4} className="px-1">
                              <Form.Group className="input-group">
                                <NumberFormat
                                  thousandSeparator={true}
                                  type="numeric"
                                  className={classNames("f-14 form-control", {
                                    error:
                                      errors.annualUsage && touched.annualUsage,
                                  })}
                                  placeholder="Annual Usage"
                                  value={values.annualUsage}
                                  onChange={(e) =>
                                    setFieldValue("annualUsage", getFormattedUsage(e.target.value))
                                  }
                                  allowNegative={false}
                                  maxLength={16}
                                />
                                <InputGroup.Text className="input-group-text-right">
                                  {getUnitDisplayName(values.unit)}
                                </InputGroup.Text>
                                {errors.annualUsage && touched.annualUsage && (
                                  <div className="error-message mt-1">
                                    {errors.annualUsage}
                                  </div>
                                )}
                              </Form.Group>
                            </Col>
                            <Col lg={2} className="px-1">
                              <Form.Group>
                                <Form.Control
                                  as="select"
                                  className={classNames("f-14", {
                                    error: errors.unit && touched.unit,
                                  })}
                                  value={values.unit}
                                  onChange={(e) =>
                                    setFieldValue("unit", e.target.value)
                                  }
                                >
                                  {unitsForCommodity.map((item, index) => (
                                    <option value={item.unitName} key={index}>
                                      {item.displayName}
                                    </option>
                                  ))}
                                </Form.Control>
                                {errors.unit && touched.unit && (
                                  <div className="error-message mt-1">
                                    {errors.unit}
                                  </div>
                                )}
                              </Form.Group>
                            </Col>
                          </Row>
                          <Row className="mx-n1 mb-3">
                            <Col lg={6} className="px-1">
                              <Form.Label className="required">
                                What contract length options do you want to
                                explore?
                              </Form.Label>
                            </Col>
                            <Col lg={6} className="px-1">
                              <Form.Group>
                                <Form.Control
                                  type="text"
                                  className={classNames("f-14", {
                                    error:
                                      errors.contractLength &&
                                      touched.contractLength,
                                  })}
                                  placeholder="Comma-separated list e.g. 6,12,18"
                                  value={values.contractLength}
                                  onChange={(e) =>
                                    setFieldValue(
                                      "contractLength",
                                      e.target.value
                                    )
                                  }
                                />
                                {errors.contractLength &&
                                  touched.contractLength && (
                                    <div className="error-message mt-1">
                                      {errors.contractLength}
                                    </div>
                                  )}
                              </Form.Group>
                            </Col>
                          </Row>
                          {/* <Row className="mx-n1">
                              <Col lg={6} className="px-1">
                                <Form.Label>
                                  Which product type are you intrested in?
                                </Form.Label>
                              </Col> */}
                          {/* <Col lg={6} className="px-1">
                                <Form.Group>
                                  <Form.Control
                                    as="select"
                                    className={classNames("f-14", {
                                      error:
                                        // errors.productType &&
                                        touched.productType,
                                    })}
                                    value={values.productType}
                                    onChange={(e) =>
                                      setFieldValue(
                                        "productType",
                                        e.target.value
                                      )
                                    }
                                  >
                                    <option value={ALL_PRODUCT}>
                                      All Products
                                    </option>
                                    {products.map((product) => (
                                      <option key={product} value={product}>
                                        {product}
                                      </option>
                                    ))}
                                  </Form.Control>
                                  {errors.productType &&
                                    touched.productType && (
                                      <div className="error-message mt-1">
                                        {errors.productType}
                                      </div>
                                    )}
                                </Form.Group>
                              </Col> */}
                          {/* </Row> */}
                          {hasErrorOnUpload && (
                            <span className="error-message">
                              File size limit exceeds 100 MB.
                            </span>
                          )}

                          <UploadDocuments
                            onDropHandle={onDropHandle}
                            hasError={hasErrorOnUpload}
                          />
                        </Form.Group>
                        <DocumentTable
                          uploadedFiles={uploadedFiles}
                          removeFromUploadedFiles={removeFromUploadedFiles}
                        />
                      </div>
                      <div className="wizard-footer d-flex mt-1rem">
                        <Button
                          variant="primary"
                          className="ml-auto"
                          onClick={handleSubmit}
                        >
                          Next
                        </Button>
                      </div>
                    </BusyIndicator>
                  </div>
                );
              }}
            </Formik>
          </Col>
        </Row>
      </Card>
    </div>
  );
};

export default Contract;
