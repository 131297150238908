export const SORTING_ORDERS = [
  {
    id: 0,
    value: "Price",
    order: "ASC",
    name: "Price (Lowest)",
  },
  {
    id: 1,
    value: "Price",
    order: "DESC",
    name: "Price (Highest)",
  },
  {
    id: 3,
    value: "Term",
    order: "ASC",
    name: "Term (Lowest)",
  },
  {
    id: 4,
    value: "Term",
    order: "DESC",
    name: "Term (Highest)",
  },
  {
    id: 5,
    value: "SupplierName",
    order: "ASC",
    name: "Supplier (A to Z)",
  },
  {
    id: 6,
    value: "SupplierName",
    order: "DESC",
    name: "Supplier (Z to A)",
  },
];

export const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const ALL_PRODUCT = "all_products";
export const SELECT_UTILITY = "select_utility";
export const ALL_SUPPLIERS = "all_suppliers";

export const DEFALUT_NO_SUPPLIER_LOADED_MESSAGE =
  "There are no rates available for the options selected. Please enter your contact info and an energy advisor will contact you.";

export const supplierFields = [
  { keyName: "StateName", displayName: "State", type: "state" },
  { keyName: "Zone", displayName: "Load Zone" },
  { keyName: "RateClass", displayName: "Rate Class", shouldShowInTwoCol: true },
  { keyName: "TermType", displayName: "Term Type" },
  { keyName: "ContractSummary", displayName: "Contract Summary" },
  {
    keyName: "GreenPercentage",
    displayName: "Green Percentage",
    unit: "%",
    unitPlace: "back",
  },
  { keyName: "GreenType", displayName: "Green Type" },
  { keyName: "LDCName", displayName: "Utility", shouldShowInTwoCol: true },
  { keyName: "PlanDescription", displayName: "Description", shouldShowInTwoCol: true },
  {
    keyName: "TerminationFee",
    displayName: "Early Termination Fee",
    unit: "$",
    unitPlace: "front",
    type:"number"
  },
  { keyName: "TerminationFeeType", displayName: "Termination Fee Type" },
  { keyName: "PriceZoneCode", displayName: "Price Zone Code" },
  { keyName: "FeeThreshold", displayName: "Fee Threshold" },
  { keyName: "IncentiveDescription", displayName: "Incentive Description" },
  {
    keyName: "Minimum_Usage_FEE",
    displayName: "Minimum Usage Fee",
    unit: "$",
    unitPlace: "front",
    type:"number"
  },
  {
    keyName: "Meter_Surcharges", displayName: "Meter Surcharge", unit: "$",
    unitPlace: "front", type:"number",
    shouldShowInTwoCol : true
  },
  { keyName: "Usage_Surcharges", displayName: "Usage Surcharges", unit: "$",
    unitPlace: "front", type:"number" },
  { keyName: "GrtSutIncluded", displayName: "GRT/SUT Included", showTextWhenEnable: "Yes" },
  { keyName: "YRAAC_Link", displayName: "YRAAC", type: "link" },
  { keyName: "TnCURL", displayName: "T & C", type: "link" },
  { keyName: "WebLinkURL", displayName: "Web", type: "link" },
  { keyName: "EFL_Link", displayName: "EFL", type: "link" },
  { keyName: "ED_Link", displayName: "ED", type: "link" },
];

export const brokerageIdsForTermRange = {
   "291D47A0-A565-441B-BCBD-CF622F20F449" : {min : 12, max : 84},
   "EA3C6871-A10C-4F6A-84A2-597F097DB944" : {min : 3, max : 84}
} 

export const SUPPLIERID = {
  ENERGY_TEXAS: "57eb3b70-f6c7-4905-984c-b61ad21631a7",
  CHARIOT_ENERGY: "41c9c9c8-f2e6-4b82-bdbe-c55243d41803",
  };

export const USAGE_SURCHARGES = {
  COMMON:"Usage Surcharges",
  ENERGY_TEXAS: "Energy Charge"
};
