import React from "react";
import {
  Card,
  Image,
  Col,
  Row,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { useSelector } from "react-redux";
import { selectSelectedSupplier } from "./priceAndCustomerDetails.selectors";
import {
  COMMODITY,
  selectApplicableMargin,
  selectShouldShowRFQDetails,
} from "../GetPrice";
import { isEmpty } from "lodash";
import { selectUSStates, ENEREX_STATES } from "../PriceAndCustomerDetails";
import { getFormattedValue } from "../../helper/getFormattedValue";
import { selectFilters } from "../ShowPrices";
import { unit } from "./priceAndCustomerDetails.constant";
import { getFormattedPrice } from "../../helper/getFormattedPrice";
import { selectPreferedPriceDisplayFormat } from "../ShowPrices/showPrices.selectors"
import { SUPPLIERID, USAGE_SURCHARGES } from "../ShowPrices/showPrice.constants";
import { selectPrice,selectSuppierAddressFields,selectSuppierContactFields } from "../GetPrice/getPrice.selectors";

const { NAT_GAS, ELECTRIC } = unit;

const SupplierCard = () => {
  const applicableMargin = useSelector(selectApplicableMargin).applicableMargin;
  const shouldShowRFQDetails = useSelector(selectShouldShowRFQDetails);
  const selectedSupplier = useSelector(selectSelectedSupplier);
  const USStates = useSelector(selectUSStates);
  const filters = useSelector(selectFilters);
  const preferedPriceDisplayFormat = useSelector(selectPreferedPriceDisplayFormat)
  const basicInfo = useSelector(selectPrice);
  const isEnerexStatePresent = basicInfo.state && ENEREX_STATES.includes(basicInfo.state.name);
  const suppierAddressFields = useSelector(selectSuppierAddressFields);
  const suppierContactFields = useSelector(selectSuppierContactFields);
  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Price already includes below margin
    </Tooltip>
  );

  const termination = () => {

    if (selectedSupplier.SupplierId === SUPPLIERID.CHARIOT_ENERGY) {
      for (let keyIndex in selectedSupplier.supplierDetails) {
        if (selectedSupplier.supplierDetails[keyIndex].key === "Termination Fee Type")
          if (isNaN(selectedSupplier.supplierDetails[keyIndex].value)) {
            if (selectedSupplier.supplierDetails[keyIndex].value === 1) {
              return " one time fee"
            } else if (selectedSupplier.supplierDetails[keyIndex].value === 2) {
              return " per remaining month"
            }
          } else {
            if (selectedSupplier.supplierDetails[keyIndex].value === 1 + '') {
              return " one time fee"
            } else if (selectedSupplier.supplierDetails[keyIndex].value === 2 + '') {
              return " per remaining month"
            }
          }
      }
    }
  }
console.log(filters.class);
  return (
    <>
      {!shouldShowRFQDetails && !isEmpty(selectedSupplier) && (
        <Col lg={4} className="mb-5 mb-lg-0">
          <Card className="suplier-item">
            <Card.Header>
              <h5>
                Selected plan details with {selectedSupplier.SupplierName}
              </h5>
            </Card.Header>
            <Card.Body>
              {selectedSupplier.supplierLogo && (
                <div className="thumb mb-4" style={{ verticalAlign:"top", display:"inline-block", textAlign:"center", width:"100%", height:"90px" }}>
                  <Image
                    src={selectedSupplier.supplierLogo}
                    width='auto'
                    height='auto'
                    max-height='100%'
                    max-width='100%'
                    fluid
                    alt="supplier1"
                  />
                  {selectedSupplier.PUCT && (<div style={{display:"block"}}>
                    <p>PUCT# {selectedSupplier.PUCT}</p></div>)}
                </div>
              )}

              {selectedSupplier.ProductType && selectedSupplier.ProductType !== "" && <div className="d-flex">
                <h5>Product</h5>
                <p className="ml-auto">{selectedSupplier.ProductType}</p>
              </div>}
              {selectedSupplier.PlanName && selectedSupplier.PlanName !== "" && <div className="d-flex">
                <h5 className="spacing-between-plan">Plan Name</h5>
                <p className="ml-auto">{selectedSupplier.PlanName}</p>
              </div>}
              {selectedSupplier.Term && selectedSupplier.Term !== "" && <div className="d-flex">
                <h5 className="spacing-between-plan">Term</h5>
                <p className="ml-auto">{selectedSupplier.Term} <span>Months</span></p>
              </div>}
              <div className="d-flex">
                <h5>Price</h5>
                <p className="ml-auto">
                  {getFormattedPrice(
                    preferedPriceDisplayFormat === 0 ? selectedSupplier.Price : selectedSupplier[filters.commodity === COMMODITY.ELECTRIC
                      ? `Price${preferedPriceDisplayFormat}kwh`
                      : `Price${preferedPriceDisplayFormat}therm`],
                    selectedSupplier.PlanId
                  ).toFixed(2)}
                  &cent;/
                  {filters.commodity === COMMODITY.ELECTRIC
                    ? ELECTRIC
                    : (selectedSupplier.UOM ? selectedSupplier.UOM : NAT_GAS)}
                </p>
                {!!applicableMargin && <OverlayTrigger placement="top" overlay={renderTooltip}>
                  <i className="ml-2 mt-1 fa fa-info-circle" aria-hidden="true"></i>
                </OverlayTrigger>}
              </div>
              {!!applicableMargin && (
                <div className="d-flex">
                  <h5>Broker Margin</h5>
                  <p className="ml-auto">
                    {(applicableMargin * 100).toFixed(3)} &cent; /
                    {filters.commodity === COMMODITY.ELECTRIC
                      ? ELECTRIC
                      : (selectedSupplier.UOM ? selectedSupplier.UOM : NAT_GAS)}
                  </p>
                </div>
              )}
              <div className="supplier-card-more-detail ">
                <Row className="mb-2">
                  {selectedSupplier.supplierDetails.map((each) => (
                   (each.value !=="N/A" && <Col key={each.key} md={each.shouldShowInTwoCol ? 12 : 6} className="adjust-spacing mt-2">
                      {each.type === "link" ? (
                        getFormattedValue(
                          each.type,
                          each.value,
                          USStates,
                          isEnerexStatePresent && ((each.key === "YRAAC" ? "YRAC" : each.key === "T & C" ? "TOS" : each.key))
                        )
                      ) : (
                        (selectedSupplier.SupplierId === SUPPLIERID.CHARIOT_ENERGY && each.key !== "Termination Fee Type") ?
                          (<>
                            <span className="heading-text"> {selectedSupplier.SupplierId === SUPPLIERID.ENERGY_TEXAS && each.key === USAGE_SURCHARGES.COMMON
                              ? USAGE_SURCHARGES.ENERGY_TEXAS : each.key}: </span>
                            <span className="sub-heading-text f-14 text-muted ">
                              {each.unitPlace === "front" && !isNaN(each.value) && each.unit}
                              {each.type !== "default"
                                ? (selectedSupplier.SupplierId === SUPPLIERID.CHARIOT_ENERGY && each.key === "Early Termination Fee") ?
                                  each.value === "N/A" ? "N/A" : getFormattedValue(each.type, each.value, USStates) + termination() :
                                  each.value === "N/A" ? "N/A" :
                                    getFormattedValue(
                                      each.type,
                                      each.value,
                                      USStates
                                    )
                                : each.value}
                              {each.unitPlace === "back" && each.unit}
                            </span>
                          </>) :
                          (selectedSupplier.SupplierId !== SUPPLIERID.CHARIOT_ENERGY && each.key !== "Termination Fee Type") &&
                          (<>
                            <span className="heading-text"> {selectedSupplier.SupplierId === SUPPLIERID.ENERGY_TEXAS && each.key === USAGE_SURCHARGES.COMMON
                          ? USAGE_SURCHARGES.ENERGY_TEXAS : each.key}: </span>
                            <span className="sub-heading-text f-14 text-muted ">
                              {each.unitPlace === "front" && !isNaN(each.value) && each.unit}
                              {each.type !== "default"
                                ? each.value === "N/A" ? "N/A" :
                                  getFormattedValue(
                                    each.type,
                                    each.value,
                                    USStates
                                  )
                                : each.value}
                              {each.unitPlace === "back" && each.unit}
                            </span>
                          </>)
                      )}
                    </Col>)
                  ))}
                </Row>
                {selectedSupplier.Links.map((each) => (
                  <Row key={each}>
                    {each}
                    <a href={each} rel="noreferrer" target="_blank">
                      <i className=" ml-2 fa fa-download"></i>
                    </a>
                  </Row>
                ))}
                <br/>
                 {/* {(suppierContactFields && Object.keys(suppierContactFields).length > 0) ? <div style={{fontSize: '12px'}}><strong>Provider Contact Information:</strong>Mailing Address:{`${ suppierAddressFields.addressLine1 == undefined ? '' : suppierAddressFields.addressLine1} ${suppierAddressFields.addressLine2 == undefined ? '' : suppierAddressFields.addressLine2} ${suppierAddressFields.zip == undefined ? '' : suppierAddressFields.zip} ${suppierAddressFields.city == undefined ? '' : suppierAddressFields.city} ${suppierAddressFields.stateName == undefined ? '' : suppierAddressFields.stateName } Email:${suppierContactFields?.emailWork == undefined ? '' : suppierContactFields?.emailWork} ${suppierContactFields.description == undefined ? '' : suppierContactFields.description}`} </div>:''} */}
                 {(filters.class == 'Residential' && suppierContactFields && Object.keys(suppierContactFields).length > 0) ? 
                <div style={{fontSize: '12px'}}>
                   {suppierAddressFields.addressLine1 && <strong>Provider Contact Information:</strong>}<br/>
                   {suppierAddressFields.addressLine2 && <strong>Mailing Address:</strong>}
                  {`
                    ${suppierAddressFields.addressLine1 == undefined ? '' : suppierAddressFields.addressLine1} 
                    ${suppierAddressFields.addressLine2 == undefined ? '' : suppierAddressFields.addressLine2} 
                    ${suppierAddressFields.city == undefined ? '' : suppierAddressFields.city}
                    ${suppierAddressFields.stateName == undefined ? '' : suppierAddressFields.stateName }
                    ${suppierAddressFields.zip == undefined ? '' : suppierAddressFields.zip}`}
                    <br/>
                    {suppierContactFields && suppierContactFields.emailWork ? <><strong>{"Email: " }</strong>{suppierContactFields.emailWork}<br/></> : ''}
                    {suppierContactFields && suppierContactFields.phoneWork ? <><strong>{"Telephone (Toll free): "}</strong>{suppierContactFields.phoneWork}<br/></> : ''}
                    {suppierContactFields && suppierContactFields.description ? <><strong>{'Customer Care Hours: '}</strong>{suppierContactFields.description}<br/></>:'' }
                    {suppierContactFields && suppierContactFields.website ? <><strong>{'Website: '}</strong>{suppierContactFields.website}<br/></>:'' }
                     </div>:''}

                 
              </div>
            </Card.Body>
          </Card>
        </Col>
      )}
    </>
  );
};

export default SupplierCard;
