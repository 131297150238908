import slice from "./priceAndCustomerDetails.slice";

export const selectSlice = (state) => state[slice.name];

export const selectSteps = (state) => selectSlice(state).steps;
export const selectCustomerAccountsInfo = (state) =>
  selectSlice(state).customerAccountsInfo;
export const selectCustomerContractInfo = (state) =>
  selectSlice(state).customerContractInfo;
export const selectCustomerInfo = (state) => selectSlice(state).customerInfo;
export const selectSelectedSupplier = (state) =>
  selectSlice(state).selectedSupplier;
export const selectSearchedAccount = (state) =>
  selectSlice(state).searchedAccount;
export const selectSaveStatus = (state) => selectSlice(state).saveStatus;
export const selectUnitsForCommodity = (state) =>
  selectSlice(state).unitsForCommodity;
export const selectEnrollmentFields = (state) =>
  selectSlice(state).enrollmentFields;
export const selectFieldsResult = (state) => selectSlice(state).fieldsResult;
export const selectSupplierFields = (state) =>
  selectSlice(state).supplierFields;
export const selectUSStates = (state) => selectSlice(state).USStates;
export const selectIsSearchedAccountExist = (state) =>
  selectSlice(state).isSearchedAccountExist;
export const selectIsAccountDeleted = (state) =>
  selectSlice(state).isAccountDeleted;
export const selectCustomerLegalEntityName = (state) =>
  selectSlice(state).customerLegalEntityName;
export const selectSelectedDynamicFieldType = (state) =>
  selectSlice(state).selectedDynamicFieldType;
export const selectHasEmptyEnrollementAndSupplierFields = (state) => 
  selectSlice(state).hasEmptyEnrollementAndSupplierFields
export const selectHasEmptyAdditionalInfoFields = (state) => 
  selectSlice(state).hasEmptyAdditionalInfoFields
export const selectTaxExemptValue = (state) => 
  selectSlice(state).taxExemptValue
 export const selectAccountByAddress =(state)=> selectSlice(state).accountByAddress
