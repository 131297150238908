import { combineReducers } from "redux";
import * as busyIndicator from "./widgets/busyIndicator";
import * as pendingRequest from "./infrastructure/pendingRequest";
import * as notificationPopup from "./infrastructure/notificationPopup";
import * as httpCache from "./infrastructure/httpCache";
import * as GetPrice from "./features/GetPrice";
import * as ShowPrices from "./features/ShowPrices";
import * as PriceAndCustomerDetails from "./features/PriceAndCustomerDetails";
import * as accessToken from "./features/accessTokens";
import * as clientContext from "./features/clientContext";

export default combineReducers({
  [busyIndicator.name]: busyIndicator.reducer,
  [pendingRequest.name]: pendingRequest.reducer,
  [notificationPopup.name]: notificationPopup.reducer,
  [httpCache.name]: httpCache.reducer,
  [GetPrice.name]: GetPrice.reducer,
  [ShowPrices.name]: ShowPrices.reducer,
  [PriceAndCustomerDetails.name]: PriceAndCustomerDetails.reducer,
  [accessToken.name]: accessToken.reducer,
  [clientContext.name]: clientContext.reducer
});
