import slice from "./showPrices.slice";

export const selectSlice = (state) => state[slice.name];

export const selectSuppliers = (state) => selectSlice(state).suppliers;
export const selectProducts = (state) => selectSlice(state).products;
export const selectFilters = (state) => selectSlice(state).filters;
export const selectCustomPricingMessageStatus = (state) =>
  selectSlice(state).customPricingMessageStatus;
export const selectPreferedPriceDisplayFormat =(state)=> selectSlice(state).preferedPriceDisplayFormat;
