import React from 'react'
import { Col,Card } from 'react-bootstrap'

const LargeTierCard=()=>(
    <Col lg={4} className="mb-5 mb-lg-0">
          <Card>
            <Card.Body>
            Wow, you use a lot of energy!<br/><br/>We serve many customers just like you, typically with your usage we recommend getting a custom quotation.<br/><br/>This process will take into account how and when you use power, then we will tailor a specific product to meet your unique needs.<br/><br/> Please fill out this form and one of our Energy experts will be in touch with you soon!
            </Card.Body>
          </Card>
        </Col>
)

export default LargeTierCard