import React, { useState, useEffect } from "react";
import { Button, Row, Col, Card, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import queryString from "query-string";
import { useHistory } from "react-router";
import { Alert } from "react-bootstrap";
import {
  selectCustomerInfo,
  selectSupplierFields,
  selectSteps,
  selectCustomerAccountsInfo,
  selectSelectedSupplier,
  selectCustomerLegalEntityName,
  selectFieldsResult,
  selectTaxExemptValue,
} from "./priceAndCustomerDetails.selectors";
import BusyIndicator, { REGIONS } from "../../widgets/busyIndicator";
import CustomComponent from "../../widgets/CustomComponent";
import { saveAllInformation } from "./priceAndCustomerDetails.asyncActions";
import { actions } from "./priceAndCustomerDetails.slice";
import Stepper from "./Stepper";
import SupplierCard from "./SupplierCard";
import handleCloseWidget from "../../helper/handleCloseWidget";
import {
  selectPrice,
  CLASS,
  selectPortalSetting,
  COMMODITY,
} from "../GetPrice";
import { getUsage } from "../../helper/getUsage";
import { selectFilters } from "../ShowPrices/showPrices.selectors";
import { getMonthIndex } from "../ShowPrices/showPrices.utils";
import { unit } from "../PriceAndCustomerDetails/priceAndCustomerDetails.constant";
import analytics from "../../helper/analytics";
import { selectClientConfig } from "../clientContext";
import classNames from "classnames";
import { useUserContext } from "../utils/userContext";
import { monthNames } from "../ShowPrices";

const { NAT_GAS, ELECTRIC } = unit;
const { setFieldsResult, setSaveStatus } = actions;

const SupplierInfo = () => {
  const ctx = useUserContext();
  const steps = useSelector(selectSteps);
  const history = useHistory();
  const dispatch = useDispatch();
  const clientConfig = useSelector(selectClientConfig);
  const customerInfo = useSelector(selectCustomerInfo);
  const portalSetting = useSelector(selectPortalSetting);
  const portalSettings = useSelector(selectPortalSetting);
  const customerAccountInfo = useSelector(selectCustomerAccountsInfo);
  const customerLegalEntityName = useSelector(selectCustomerLegalEntityName);
  const fieldsData = useSelector(selectSupplierFields);
  const fields = JSON.parse(JSON.stringify(fieldsData.fieldsAsJson));
  const fieldsResult = useSelector(selectFieldsResult);
  const price = useSelector(selectPrice);
  const selectedSupplier = useSelector(selectSelectedSupplier);
  const filters = useSelector(selectFilters);
  const taxExemptValue = useSelector(selectTaxExemptValue)
  const { oid: opportunityId, did: dealId } = queryString.parse(window.location.search);
  const [validated, setValidated] = useState(false);
  const [data, setData] = useState({});
  const [show, setShow] = useState(false);
  const [fieldValue, setfieldValue]= useState([]);
  
 

  const { isFullScreen , brokerageId } = clientConfig;
  const {
    PlanId,
    SupplierId,
    SupplierName,
    Term,
    ProductType,
    Price,
    RateClass,
    PlanDescription,
    GreenPercentage,
    UDCCode,
    UDCID,
    Zone,
  } = selectedSupplier;
  // const isEnerexStatePresent =
  //   price.state && ENEREX_STATES.includes(price.state.name);
  const quote = {
    planId: price.class === CLASS.RESIDENTIAL ? PlanId : null,
    supplierId: SupplierId,
    supplierName: SupplierName,
    term: Term,
    product: ProductType,
    price: Price,
    notes: PlanDescription,
    rateClass: RateClass,
    Unit: price.commodity === COMMODITY.ELECTRIC ? ELECTRIC : NAT_GAS,
    GreenPercentage,
    UDCCode: price.class === CLASS.RESIDENTIAL ? UDCCode : undefined,
    UDCID: price.class === CLASS.RESIDENTIAL ? UDCID : undefined,
    LoadZoneName: Zone,
  };
  const contractTemplate = {
    contractTemplateId: fieldsData.id,
    contractSpecificFields: data,
  };

  useEffect(() => setShow(false), []);

  const submitHandler = (event) => {
    const form = event.currentTarget;
    const meters = customerAccountInfo.map(({ utility, ...each }) => each);
    const usage = getUsage(portalSetting.keyValue, price);

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      const documents = customerInfo?.files?.map(({ size, ...file }) => ({
        data: file,
      }));
      const contact = { ...customerInfo }
      delete contact.files
      dispatch(
        saveAllInformation({
          customerData: {
            opportunityId: opportunityId,
            customerLegalEntityName,
            dealId: dealId,
            class: price.class,
            commodityTypeId: price.commodity,
            taxExemptValue,
            annualUsageOrMonthlyBill: usage,
            zipCode: price.zipCode,
            promoCode: price.referralCode,
            quote,
            contact,
            meters,
            brokerageId,
            documents,
            contractTemplate,
            startMonth: getMonthIndex(filters.month),
            startYear: filters.year,
            stateId: price.state.id,
          },
        })
      ).then((res) => {
        if (res.payload) history.push("review");
        else setShow(true);
      });
      dispatch(setFieldsResult(data));
      analytics.sendEventWithData("Supplier Additional Information", data)
    }
    setValidated(true);
  };
  let timeVar=0;
  const showMessage =(message, cssClass)=>{
    const div = document.createElement('div');
    div.className = `alert alert-${cssClass} mt-2`;
    div.appendChild(document.createTextNode(message));

    const container = document.querySelector('#container');
    const app = document.querySelector('#card');
    container.insertBefore(div, app);
    setTimeout(function(){
      document.querySelector('.alert')?.remove();
      timeVar=0;
    },4000);
  };
  useEffect(()=>{
    setfieldValue(data)
 },[data])
 
  const validateField =(event)=>{   
   let contShow=0;
   for (var property in fieldValue) {

     if(fieldValue[property]===" " || fieldValue[property] === null || (property==="String6" && (data.String6 === undefined || data.String6 === ""))){
      if(timeVar===0){contShow=1};
      event.preventDefault();
     }

   }
   if(contShow ===1 ){showMessage("Please fill all the fields to continue","danger"); timeVar=1};
  }

  const holidays = [
    new Date(2022, 0, 17),
    new Date(2022, 1, 21),
    new Date(2022, 3, 15),
    new Date(2022, 4, 30),
    new Date(2022, 5, 20),
    new Date(2022, 6, 4),
    new Date(2022, 8, 5),
    new Date(2022, 10, 24),
    new Date(2022, 11, 26),
    new Date(2023, 0, 2),
    new Date(2023, 0, 16),
    new Date(2023, 1, 20),
    new Date(2023, 3, 7),
    new Date(2023, 4, 29),
    new Date(2023, 5, 20),
    new Date(2023, 6, 4),
    new Date(2023, 8, 4),
    new Date(2023, 10, 23),
    new Date(2023, 11, 25),
    new Date(2024, 0, 1),
    new Date(2024, 0, 15),
    new Date(2024, 1, 19),
    new Date(2024, 3, 29),
    new Date(2024, 4, 27),
    new Date(2024, 5, 19),
    new Date(2024, 6, 4),
    new Date(2024, 8, 2),
    new Date(2024, 10, 28),
    new Date(2024, 11, 25),
    new Date(2025, 0, 1),
    new Date(2025, 0, 20),
    new Date(2025, 1, 17),
    new Date(2025, 3, 18),
    new Date(2025, 4, 26),
    new Date(2025, 5, 19),
    new Date(2025, 6, 4),
    new Date(2025, 8, 1),
    new Date(2025, 10, 27),
    new Date(2025, 11, 25),
  ];
  
  const selectdate = () => {

    for (let Key in fields) {

      if (fields[Key].type === "fieldGroup" && fields[Key]["fields"]) {
        let fieldEnter = fields[Key]["fields"];
        for (let obj of fieldEnter) {

          let { name, friendlyName, dataType, daysUpto } = obj
          if (name === "DateOfBirth") {
            obj['ranges'] = {
              allowWeekends: true,
            }
          }

          if ((name === "SwitchDate" || name === "SwitchMoveDate") && (friendlyName === "Select a Date" || friendlyName === "Switch & Move-In Date") && dataType === "DateTime") {
            // const minDateJson = new Date(obj.ranges.minDate)

            obj.ranges['maxDate'] = {
              maxDate: null
            }

            obj['holidays'] = holidays

            const month = monthNames.indexOf(ctx.get());
            let date = new Date()


            if (month !== -1 && month !== new Date().getMonth()) {
              date.setDate(1)
              date.setMonth(month)

            }

            let offset = 0;
            if (date.getDay() === 0) {
              offset = 1
            } else if (date.getDay() === 6) {
              offset = 2
            }

            date.setDate(date.getDate() + offset)

            obj['date'] = new Date(date)
            const expiryDate = new Date(date)

            expiryDate.setTime(date.getTime() + (Number(daysUpto) || 90) * 86400000)

            obj.ranges.minDate = date;
            obj.ranges['maxDate'] = expiryDate;

          }

        }
      }
    }

    for (let obj of fields) {

      let { name, friendlyName, dataType, daysUpto } = obj
      if (name === "DateOfBirth") {
        obj['ranges'] = {
          allowWeekends: true,
        }
      }

      if ((name === "SwitchDate" || name === "SwitchMoveDate") && (friendlyName === "Select a Date" || friendlyName === "Switch & Move-In Date") && dataType === "DateTime") {
        // const minDateJson = new Date(obj.ranges.minDate)

        obj.ranges['maxDate'] = {
          maxDate: null
        }

        obj['holidays'] = holidays
        const month = monthNames.indexOf(ctx.get());
        let date = new Date()

        if (month !== -1 && month !== new Date().getMonth()) {
          date.setDate(1)
          date.setMonth(month)
        }

        let offset = 0;
        if (date.getDay() === 0) {
          offset = 1
        } else if (date.getDay() === 6) {
          offset = 2
        }

        date.setDate(date.getDate() + offset)

        obj['date'] = new Date(date)
        const expiryDate = new Date(date)

        expiryDate.setTime(date.getTime() + (Number(daysUpto) || 90) * 86400000)

        obj.ranges.minDate = date;
        obj.ranges['maxDate'] = expiryDate;      

      }
    }
  }

  selectdate()

  return (
    <div className={classNames("inner-widget", { "px-20": !isFullScreen })}>
      <Card className={classNames("p-4", { "min-h-100": isFullScreen })}>
        <div className="mb-4 py-2">
          <div className="d-flex align-items-center justify-content-between">
            <Button
              variant="outline-primary"
              onClick={() => {
                history.push("accounts");
                dispatch(setSaveStatus());
              }}
            >
              Back to Accounts
            </Button>
            <div className='d-flex align-items-center'>
            {portalSettings.phoneWork && <h6 className="sub-heading-text f-14 text-muted">Need Assistance?: Call {portalSettings.phoneWork}</h6>}
            <Button
              variant="outline-light"
              onClick={() => handleCloseWidget(dispatch, history)}
            >
              <i className="fa fa-times fa-2x f-20"></i>
            </Button>
            </div>
          </div>
        </div>
        <Row  className="justify-content-center">
          <SupplierCard />
          <Col  id='container'lg={8}>
            <div id='card'className="wizard ">
              <Stepper pages={steps} currentPath="supplier-info" />
              <BusyIndicator region={REGIONS.SAVE_ALL_INFORMATION}>
                <div className="wizard-content" data-step="step1">
                  {show && (
                    <Alert
                      variant="danger"
                      onClose={() => setShow(false)}
                      dismissible
                    >
                      <p>Unable Save Details</p>
                    </Alert>
                  )}
                  <Form                   
                    noValidate
                    validated={validated}
                    onSubmit={submitHandler}
                  >
                    <CustomComponent                    
                      fields={fields}
                      data={data}
                      fieldsResult={fieldsResult}
                      onFieldChange={(field, value) => {
                        setData((data) => ({ ...data, [field]: value }));
                                              
                      }}                      
                      removeKey={(keyToRemove) => {
                        delete data[keyToRemove]
                        setData(data)                    
                      }}
                      selectedClass={price.class}                  
                      />
                    <Button
                      type="submit"
                      className="wizard-footer d-flex ml-auto "
                      onClick={(event)=>(validateField(event))}
                    >
                      Next
                    </Button>
                  </Form>
                </div>
              </BusyIndicator>
            </div>
          </Col>
        </Row>
      </Card>
    </div>
  );
};

export default SupplierInfo;

/* const selectdate = () => {

    for (let Key in fields) {

      if (fields[Key].type === "fieldGroup" && fields[Key]["fields"]) {
        let fieldEnter = fields[Key]["fields"];
        for (let obj of fieldEnter) {

          let { name, friendlyName, dataType } = obj
          if(name === "DateOfBirth"){
            obj['ranges'] = {
              allowWeekends: true, 
            }
          }
          if ((name === "SwitchDate" || name === "SwitchMoveDate") && (friendlyName === "Select a Date" || friendlyName === "Switch & Move-In Date") && dataType === "DateTime") {
            const split = obj.ranges.minDate.split(/[^\d+]/).filter(v => {
              return v.length !== 0
            });

            obj['holidays'] = holidays

            const month = monthNames.indexOf(ctx.get());
            let date = new Date()

            if (month !== -1 && month !== new Date().getMonth()) {
              date.setDate(1)
              date.setMonth(month)
            }

            date.setFullYear(split[2])

            let offset = 0;
            if (date.getDay() === 0) {
              offset = 1
            } else if (date.getDay() === 6) {
              offset = 2
            }

            date.setDate(date.getDate() + offset)
            date.setMonth(date.getMonth() + 1);

            const dateThreeMonths = new Date(date);
            dateThreeMonths.setDate(dateThreeMonths.getDate() + 90)

            const strMinDate = `${date.getMonth()}/${date.getDate()}/${date.getFullYear()} 12:00:00 AM`
            date.setMonth(date.getMonth() - 1);

            obj['date'] = new Date(date)

            const strMaxDate = `${dateThreeMonths.getMonth()}/${dateThreeMonths.getDate()}/${dateThreeMonths.getFullYear()} 12:00:00 AM`

            obj.ranges.minDate = strMinDate;
            obj.ranges['maxDate'] = strMaxDate;            
          }
        }
      }
    }

    for (let obj of fields) {
      let { name, friendlyName, dataType } = obj
      if(name === "DateOfBirth"){
        obj['ranges'] = {
          allowWeekends: true, 
        }
      } 
      if ((name === "SwitchDate" || name === "SwitchMoveDate") && (friendlyName === "Select a Date" || friendlyName === "Switch & Move-In Date") && dataType === "DateTime") {
        const split = obj.ranges.minDate.split(/[^\d+]/).filter(v => {
          return v.length !== 0
        });

        obj['holidays'] = holidays

        const month = monthNames.indexOf(ctx.get());
        let date = new Date()

        if (month !== -1 && month !== new Date().getMonth()) {
          date.setDate(1)
          date.setMonth(month)
        }

        date.setFullYear(split[2])

        let offset = 0;
        if (date.getDay() === 0) {
          offset = 1
        } else if (date.getDay() === 6) {
          offset = 2
        }

        date.setDate(date.getDate() + offset)
        date.setMonth(date.getMonth() + 1);

        const dateThreeMonths = new Date(date);
        if (selectedSupplier.SupplierId === "0770f405-d176-47c4-a8dd-dd6d71d98952") {
            dateThreeMonths.setDate(dateThreeMonths.getDate() + 30)
          } else {
            dateThreeMonths.setDate(dateThreeMonths.getDate() + 90)
          }

        const strMinDate = `${date.getMonth()}/${date.getDate()}/${date.getFullYear()} 12:00:00 AM`
        date.setMonth(date.getMonth() - 1);

        obj['date'] = new Date(date)

        const strMaxDate = `${dateThreeMonths.getMonth()}/${dateThreeMonths.getDate()}/${dateThreeMonths.getFullYear()} 12:00:00 AM`

        obj.ranges.minDate = strMinDate;
        obj.ranges['maxDate'] = strMaxDate;
      }
    }
  }*/