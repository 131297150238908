import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import "font-awesome/css/font-awesome.min.css";
import { Row, Col, Button, Card, Form, Image, Alert, Container } from "react-bootstrap";
import {
  selectFilters,
  selectPreferedPriceDisplayFormat,
} from "./showPrices.selectors";
import {
  selectTier,
  TIERS,
  CLASS,
  setShouldShowRFQDetails,
  selectPrice,
  selectUtilities,
  savePrice,
  getApplicableMargin,
  selectAllProducts,
  COMMODITY,
  selectSuppliersLogo,
  getSupplierLogo,
  setIsPriceExpired,
  getRateCodes,
  setIsSupplierUpdated,
  selectUserSettings,
} from "../GetPrice";
import BusyIndicator, { REGIONS } from "../../widgets/busyIndicator";
import ShowPriceDetails from "./ShowPriceDetails";
import { actions } from "./showPrices.slice";
import { getMonth, getMonths, getYears } from "./showPrices.utils";
import {
  ALL_PRODUCT,
  DEFALUT_NO_SUPPLIER_LOADED_MESSAGE,
  SORTING_ORDERS,
} from "./showPrice.constants";
import Overlay from "../../widgets/Tooltip";
import {
  setSelectedSupplier,
  selectSteps,
  setSteps,
  unit,
  getUSStates,
  selectUSStates,
  ENEREX_STATES,
  ENEREX_STATESFORPRICE,
} from "../PriceAndCustomerDetails";
import ShowMoreFilters from "./ShowMoreFilters";
import classNames from "classnames";
import handleCloseWidget from "../../helper/handleCloseWidget";
import {
  selectSearchedPrices,
  selectSearchedPricesUtility,
  selectSearchedPricesSuppliers,
} from "../GetPrice/getPrice.selectors";
import { actions as getPricesAction } from "../GetPrice/getPrice.slice";
import {
  selectPortalSetting,
  COMMODITIES_LIST,
  getSearchPrice,
  selectRateCodes,
  selectIsPriceExpired,
  getSuppierAddressFields,
} from "../GetPrice";
import { selectClientConfig } from "../clientContext";
import { isEmpty } from "lodash";
import {
  supplierFields,
  brokerageIdsForTermRange,
} from "./showPrice.constants";
import queryString from "query-string";
import { getFormattedPrice } from "../../helper/getFormattedPrice";
import {
  getMonthlyUsage,
  getUsage,
  getTermFilteredPlans,
} from "../../helper/getUsage";
import analytics from "../../helper/analytics";
import { monthNames } from ".";
import { useUserContext } from "../utils/userContext";

const { setPriceForComparision } = getPricesAction;
const { setFilters, setPreferedPriceDisplayFormat } = actions;
const emptyArray = [];
const emptyString = "";
const { ELECTRIC, NAT_GAS } = unit;
const FIRST = 0;

const ShowPrices = () => {
  const ctx = useUserContext();
  const history = useHistory();
  const dispatch = useDispatch();
  const basicInfo = useSelector(selectPrice);
  const userSettings = useSelector(selectUserSettings);
  const filters = useSelector(selectFilters);
  const tier = useSelector(selectTier);
  const rateCodes = useSelector(selectRateCodes);
  let searchedPrices = useSelector(selectSearchedPrices);
  const initialSupplierData = useSelector(selectSearchedPrices);
  const residentialUtilities = useSelector(selectSearchedPricesUtility);
  const products = useSelector(selectAllProducts);
  const USStates = useSelector(selectUSStates);
  const loadZoneRateCodes = rateCodes.map((each) => each.LoadProfile);
  const rateClassCodes = rateCodes.map((each) => each.RateClass);
  const isPriceExpired = useSelector(selectIsPriceExpired);
  const commercialUtilities = useSelector(selectUtilities);
  const suppliersToBeCompared = searchedPrices.filter(
    (supplier) => supplier.isSelectedForPriceComparision === true
  );
  const searchedPricesSuppliers = useSelector(selectSearchedPricesSuppliers);
  const [localZipCode, setLocalZipCode] = useState(basicInfo.zipCode);
  const [productType, setProductType] = useState(ALL_PRODUCT);
  const [isOnePriceSelected, setIsOnePriceSelected] = useState(false);
  const [showUtilityError, setShowUtilityError] = useState(false);
  const [cardDetails, setCardDetails] = useState(false);
  const [chosenAccordian, setChosenAccordian] = useState([]);
  const [info, setInfo] = useState({
    class: filters?.class || basicInfo.class,
    commodity: filters?.commodity || basicInfo.commodity,
  });
  const utilities =
    info.class === CLASS.COMMERCIAL
      ? commercialUtilities
      : residentialUtilities;
  const currentMonthAndYear = getMonth();
  const getEndDateMonth = () => {
    const endDate = new Date(userSettings.endDate);
    const month = endDate ? endDate.getMonth() : "";
    const index = month === 11 ? 0 : month + 1;
    return monthNames[index];
  };
  const getEndDateYear = () => {
    const endDate = new Date(userSettings.endDate);
    const month = endDate ? endDate.getMonth() : "";
    const year = endDate ? endDate.getFullYear() : "";
    return month === 11 ? year + 1 : year;
  };
  const [filterInfo, setFilterInfo] = useState({
    month:
      (!isEmpty(userSettings) ? getEndDateMonth() : filters?.month) ||
      currentMonthAndYear.month,
    year:
      (!isEmpty(userSettings) ? getEndDateYear() : filters?.year) ||
      currentMonthAndYear.year,
    product: filters?.product || "all_products",
    utility: filters?.utility || basicInfo?.utility || utilities[0],
    sort: filters?.sort || "Price",
    order: filters?.order || "ASC",
    supplier: filters?.supplier || "all_suppliers",
    from: filters?.from || emptyString,
    to: filters?.to || emptyString,
    zipCode: filters?.zipCode || localZipCode,
    class: filters?.class || info.class,
    commodity: filters?.commodity || info.commodity,
    Suppliers: filters?.Suppliers || [],
    rateClass: basicInfo?.rateCode || "",
    usage: basicInfo?.usage || "",
  });
  const steps = useSelector(selectSteps);
  const [updatedStepsForFalseEnrollment, setUpdatedStepsForFalseEnrollment] =
    useState(emptyArray);
  const [stepsForEnrollmentandPlanId, setStepsForEnrollmentandPlanId] =
    useState(emptyArray);
  const [show, setShow] = useState(true);
  const portalSettings = useSelector(selectPortalSetting);

  const selectedUtility =
    filters.utility &&
    residentialUtilities.find(
      (each) => each.LDCName === filters.utility.LDCName
    );
  const { messageOnEmptyResult } = portalSettings; // Need to update once we know the key name
  const clientConfig = useSelector(selectClientConfig);
  const { brokerageId, isFullScreen, authKey } = clientConfig;
  const MARGIN = "Margin";
  const suppliersLogo = useSelector(selectSuppliersLogo);
  const [showPriceExpiredAlert, setShowPriceExpiredAlert] = useState(true);
  const { oid: opportunityId } = queryString.parse(window.location.search);
  const isEnerexStatePresent =
    basicInfo.state && ENEREX_STATES.includes(basicInfo.state.name);
  const isEnerexStatePresentForPrice =
    basicInfo.state && ENEREX_STATESFORPRICE.includes(basicInfo.state.name);
  const preferedPriceDisplayFormat = useSelector(
    selectPreferedPriceDisplayFormat
  );
  searchedPrices = getTermFilteredPlans(
    searchedPrices,
    brokerageId,
    brokerageIdsForTermRange
  );

  useEffect(() => {
    setTimeout(() => {
      setShowPriceExpiredAlert(false);
    }, 20000);
  }, []);

  useEffect(() => {
    isEmpty(USStates) && dispatch(getUSStates());
  }, [dispatch, USStates]);

  useEffect(() => {
    dispatch(setSteps());
    dispatch(setShouldShowRFQDetails(false));
    dispatch(setSelectedSupplier());
  }, [dispatch]);

  useEffect(() => {
    if (filterInfo.isFilterUpdated || isEmpty(filters)) {
      dispatch(setFilters(filterInfo));
      setFilterInfo({ ...filterInfo, isFilterUpdated: false });
    }
  }, [dispatch, filterInfo, filters]);

  useEffect(() => {
    setUpdatedStepsForFalseEnrollment(
      steps.filter((step) => step.label !== "Enrollment")
    );
    setStepsForEnrollmentandPlanId(
      steps.filter((step) => step.label !== "Additional Information")
    );
  }, [steps]);

  useEffect(() => {
    Promise.all(
      searchedPricesSuppliers.map(
        (each) =>
          !suppliersLogo[each.SupplierId] &&
          dispatch(getSupplierLogo(each.SupplierId))
      )
    );
  }, [searchedPricesSuppliers, suppliersLogo, dispatch]);

  const selectedMonths = getMonths(userSettings.endDate);
  const monthsOptions = selectedMonths.filter(
    (each) => each.year === Number(filterInfo.year)
  );
  const selectedYears = getYears(userSettings.endDate);

  const getMonthFromString = (mon) => {
    const updatedMonth = new Date(Date.parse(mon + " 1, 2021")).getMonth() + 1;
    if (updatedMonth < 10) return "0" + updatedMonth;
    return updatedMonth;
  };

  const startDate =
    filters.year + "-" + getMonthFromString(filters.month) + "-01";

    const getLoadZone = (rateCode, loadZoneRateCodes = []) =>{
      return rateCode === "All"  ? loadZoneRateCodes : 
         loadZoneRateCodes.includes(rateCode)
          ? [rateCode]
          : [loadZoneRateCodes[FIRST]];
    }

  useEffect(() => {
    if (filters.isFilterUpdated) {
      let formValues = {
        SortBy: filters.sort,
        SortDirection: filters.order,
        BrokerId: brokerageId,
        LDCS:
          filters.class === CLASS.RESIDENTIAL
            ? selectedUtility
              ? [selectedUtility.LDCId]
              : []
            : filterInfo.utility?.LDCIdentifier
              ? [filterInfo.utility.LDCIdentifier]
              : [],
        Commodity: COMMODITIES_LIST[filters.commodity],
        RateClassification: filters.class,
        Suppliers: filters.Suppliers,
        ZipCode: filters.zipCode,
        StartDate: startDate,
        UsageMin: getUsage(portalSettings.keyValue, basicInfo),
      };
      formValues = isEnerexStatePresent
        ? {
          ...formValues,
          LoadZone: getLoadZone(basicInfo.rateCode, loadZoneRateCodes),
        }
        : {
          ...formValues,
          RateClass: basicInfo.rateCode
            ? [basicInfo.rateCode]
            : rateClassCodes,
        };
      if (filters.from)
        formValues = { ...formValues, TermsMin: parseInt(filters.from) };
      if (filters.to)
        formValues = { ...formValues, TermsMax: parseInt(filters.to) };
      dispatch(getSearchPrice(formValues));
      dispatch(setFilters({ ...filters, isFilterUpdated: false }));
    }
  }, [
    dispatch,
    filterInfo,
    selectedUtility,
    filters,
    startDate,
    brokerageId,
    isEnerexStatePresent,
    basicInfo.rateCode,
    loadZoneRateCodes,
    rateClassCodes,
    portalSettings.keyValue,
    basicInfo,
  ]);

  useEffect(() => {
    dispatch(
      savePrice({
        ...basicInfo,
        utility: filters.utility,
        class: filters.class,
        commodity: filters.commodity,
      })
    );
    // eslint-disable-next-line
  }, [filters.utility, filters.class, filters.commodity, dispatch]);

  const handleUtility = (utility) => {
    dispatch(savePrice({ ...basicInfo, utility: utility }));
  };

  const pricesForComparison = searchedPrices.filter(
    (supplier) => supplier.isSelectedForPriceComparision === true
  );

  const isValidYear = (date) => new Date(date).getFullYear().toString() !== "1";

  const supplierDetails = (supplier) =>
    supplierFields.reduce((acc, value) => {
      if (
        supplier[value.keyName] &&
        (value.type === "date" ? isValidYear(supplier[value.keyName]) : true) &&
        (value.keyName !== MARGIN || portalSettings.discloseMargin)
      )
        acc.push({
          key: value.displayName,
          value: value.showTextWhenEnable || supplier[value.keyName],
          type: value.type || "default",
          unit: value.unit,
          unitPlace: value.unitPlace,
          shouldShowInTwoCol: value.shouldShowInTwoCol,
        });
      return acc;
    }, []);

  const sortPricesAsPerUsage = (usage) => {
    const prices = [500, 1000, 2000];
    return prices.sort((a, b) => {
      return Math.abs(usage - a) - Math.abs(usage - b);
    });
  };

  const getPricesDisplay = (supplier) => {
    const sortedPrices = sortPricesAsPerUsage(
      getMonthlyUsage(portalSettings.keyValue, filters.usage)
    );
    if (!preferedPriceDisplayFormat)
      dispatch(setPreferedPriceDisplayFormat(sortedPrices[0]));
    return (
      <>
        <Col lg={1}></Col>
        <Col md="12" lg={3}>
          <div className="adjust-spacing-prices">
            <div>
              <Row>
                <Col lg={12} className="mt-3">
                  <div className="mb-2">
                    <h4 className="heading-text mb-0">
                      {getFormattedPrice(
                        filters.commodity === COMMODITY.ELECTRIC
                          ? supplier[`Price${sortedPrices[0]}kwh`]
                          : supplier[`Price${sortedPrices[0]}therm`],
                        supplier.PlanId
                      ).toFixed(2)}
                      &cent;
                    </h4>
                    <span className="sub-heading-text f-16 text-muted">
                      {sortedPrices[0]}{" "}
                      {filters.commodity === COMMODITY.ELECTRIC ? ELECTRIC : (supplier.UOM ? supplier.UOM : NAT_GAS)}
                    </span>
                  </div>
                </Col>
                <Col lg={12} className="mt-3">
                  <Row>
                    <Col>
                      <div className="heading-margin">
                        <h6 className="heading-text mb-0">
                          {getFormattedPrice(
                            filters.commodity === COMMODITY.ELECTRIC
                              ? supplier[`Price${sortedPrices[1]}kwh`]
                              : supplier[`Price${sortedPrices[1]}therm`],
                            supplier.PlanId
                          ).toFixed(2)}
                          &cent;
                        </h6>
                        <span className="sub-heading-text f-12 text-muted">
                          {sortedPrices[1]}{" "}
                          {filters.commodity === COMMODITY.ELECTRIC ? ELECTRIC : (supplier.UOM ? supplier.UOM : NAT_GAS)}
                        </span>
                      </div>
                    </Col>
                    <Col>
                      <div className="ml-3">
                        <h6 className="heading-text mb-0">
                          {getFormattedPrice(
                            filters.commodity === COMMODITY.ELECTRIC
                              ? supplier[`Price${sortedPrices[2]}kwh`]
                              : supplier[`Price${sortedPrices[2]}therm`],
                            supplier.PlanId
                          ).toFixed(2)}
                          &cent;
                        </h6>
                        <span className="sub-heading-text f-12 text-muted">
                          {sortedPrices[2]}{" "}
                          {filters.commodity === COMMODITY.ELECTRIC ? ELECTRIC : (supplier.UOM ? supplier.UOM : NAT_GAS)}
                        </span>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </div>
        </Col>
        <Col lg={2}></Col>
      </>
    );
  };

  useEffect(() => {
    ctx.set(filters.month);
  }, [filters.month]);

  return (
    <main className={classNames("inner-widget", { "px-20": !isFullScreen })}>
      <Card className={classNames("shadow-lg", { "min-h-100": isFullScreen })}>
        <Card.Header className="p-header">
          <div className="d-flex align-items-center justify-content-between">
            <h5 className="f-15 mb-0">Show Prices</h5>
            <div className="d-flex align-items-center">
              {portalSettings.phoneWork && (
                <h6 className="sub-heading-text f-14 text-muted">
                  Need Assistance?: Call {portalSettings.phoneWork}
                </h6>
              )}
              <Button
                variant="outline-light"
                onClick={() => handleCloseWidget(dispatch, history)}
              >
                <i className="fa fa-times fa-2x f-20"></i>
              </Button>
            </div>
          </div>
        </Card.Header>
        <Card.Body>
          {tier === TIERS.MID && (
            <Button
              variant="outline-primary"
              className="mb-3"
              onClick={() => {
                history.push("select-pricing");
              }}
            >
              Back
            </Button>
          )}
          <div className="card border bg-light mb-3">
            <Card.Body className="p-3">
              {filters.class === CLASS.COMMERCIAL && (
                <Row className="mx-n1 mb-1">
                  <Col
                    xl={3}
                    lg={3}
                    md={8}
                    className="d-flex px-1 mb-3 mb-lg-0"
                  >
                    <Form.Control
                      as="select"
                      className="f-14"
                      onChange={(e) => {
                        setFilterInfo({
                          ...filterInfo,
                          month: e.target.value,
                          isFilterUpdated: true,
                        });
                        ctx.set(e.target.value);
                      }}
                      value={filterInfo.month}
                    >
                      {monthsOptions.map((each, index) => (
                        <option value={each.month} key={index}>
                          {each.month}
                        </option>
                      ))}
                    </Form.Control>
                    <Form.Control
                      as="select"
                      className="f-14 ml-8"
                      onChange={(e) => {
                        const updatedMonth = selectedMonths.filter(
                          (each) => each.year === Number(e.target.value)
                        )[0].month;
                        setFilterInfo({
                          ...filterInfo,
                          month: updatedMonth,
                          year: e.target.value,
                          isFilterUpdated: true,
                        });
                      }}
                      value={filterInfo.year}
                    >
                      {selectedYears.map((year) => (
                        <option key={year} value={year}>
                          {year}
                        </option>
                      ))}
                    </Form.Control>
                  </Col>
                  <Col
                    xl={3}
                    lg={3}
                    md={4}
                    className="px-1 mb-3 mb-md-0 mb-xl-0"
                  >
                    <Form.Control
                      as="select"
                      className="f-14"
                      onChange={(e) => {
                        const utility = JSON.parse(e.target.value);
                        dispatch(
                          setIsSupplierUpdated(
                            JSON.stringify(utility) !==
                            JSON.stringify(filters.utility) &&
                            filters.class === CLASS.RESIDENTIAL
                          )
                        );
                        dispatch(
                          getRateCodes({
                            filters: {
                              LDCIdentifiers:
                                filters.class === CLASS.COMMERCIAL
                                  ? [utility.LDCIdentifier]
                                  : [],
                              Commodity: COMMODITIES_LIST[filterInfo.commodity],
                              StateId: basicInfo.state.id,
                              StateName: basicInfo.state.name,
                              ZipCode: localZipCode,
                              BrokerId: brokerageId,
                              RateClassification: filterInfo.class,
                            },
                          })
                        ).then(() => {
                          setFilterInfo({
                            ...filterInfo,
                            utility,
                            isFilterUpdated: true,
                          });
                        });
                        handleUtility(e.target.value);
                      }}
                      value={JSON.stringify(
                        utilities.length === 1
                          ? utilities[0]
                          : filterInfo.utility
                      )}
                    >
                      {utilities.length === 1 && (
                        <option value={JSON.stringify(utilities[0].LDCName)}>
                          {utilities[0].LDCName}
                        </option>
                      )}
                      {info.class === CLASS.RESIDENTIAL &&
                        utilities.length !== 1 && (
                          <option value={JSON.stringify({})}>
                            Select Utility
                          </option>
                        )}
                      {utilities.length !== 1 &&
                        utilities.map((utilityItem, index) => (
                          <option
                            key={index}
                            value={JSON.stringify(utilityItem)}
                          >
                            {utilityItem.LDCName}
                          </option>
                        ))}
                    </Form.Control>
                  </Col>
                  <Col
                    xl={3}
                    lg={3}
                    md={4}
                    className="px-1 mb-3 mb-md-0 mb-xl-0"
                  >
                    <Form.Control
                      as="select"
                      className="f-14"
                      onChange={(e) => {
                        const selectedSorting = SORTING_ORDERS.find(
                          (sortingOrder) =>
                            sortingOrder.id === Number(e.target.value)
                        );
                        setFilterInfo({
                          ...filterInfo,
                          order: selectedSorting.order,
                          sort:
                            selectedSorting.value === "Price" &&
                              preferedPriceDisplayFormat &&
                              info.class === CLASS.RESIDENTIAL
                              ? filters.commodity === COMMODITY.ELECTRIC
                                ? `Price${preferedPriceDisplayFormat}kwh`
                                : `Price${preferedPriceDisplayFormat}therm`
                              : selectedSorting.value,
                          isFilterUpdated: true,
                        });
                      }}
                    >
                      {SORTING_ORDERS.map((each) => (
                        <option key={each.id} value={each.id}>
                          {`Sort by ${each.name}`}
                        </option>
                      ))}
                    </Form.Control>
                  </Col>
                  <Col xl={3} lg={3} md={4} className="btn-px">
                    {pricesForComparison.length >= 1 ? (
                      <Button
                        variant="info"
                        className="f-14 btn-block"
                        onClick={() => {
                          if (pricesForComparison.length === 1)
                            setIsOnePriceSelected(true);
                          else {
                            analytics.sendEventWithData("Compare Prices", {
                              pricesForComparison:
                                JSON.stringify(pricesForComparison),
                            });
                            history.push("/price-selection");
                          }
                        }}
                      >
                        <i className="fa fa-heart mr-2"></i>Compare
                      </Button>
                    ) : (
                      <Overlay content="Select options to compare.">
                        <span>
                          <Button
                            variant="info"
                            className="f-14 btn-block"
                            style={{ pointerEvents: "none" }}
                            disabled={true}
                          >
                            <i className="fa fa-heart mr-2"></i>Compare
                          </Button>
                        </span>
                      </Overlay>
                    )}
                  </Col>
                </Row>
              )}
              {filters.class === CLASS.RESIDENTIAL && (
                <Row className="mx-n1 mb-1">
                  <Col
                    xl={4}
                    lg={4}
                    md={4}
                    className="px-1 mb-3 mb-md-0 mb-xl-0"
                  >
                    <Form.Control
                      as="select"
                      className="f-14"
                      onChange={(e) => {
                        const utility = JSON.parse(e.target.value);
                        dispatch(
                          setIsSupplierUpdated(
                            JSON.stringify(utility) !==
                            JSON.stringify(filters.utility) &&
                            filters.class === CLASS.RESIDENTIAL
                          )
                        );
                        dispatch(
                          getRateCodes({
                            filters: {
                              LDCIdentifiers:
                                filters.class === CLASS.COMMERCIAL
                                  ? [utility.LDCIdentifier]
                                  : [],
                              Commodity: COMMODITIES_LIST[filterInfo.commodity],
                              StateId: basicInfo.state.id,
                              StateName: basicInfo.state.name,
                              ZipCode: localZipCode,
                              BrokerId: brokerageId,
                              RateClassification: filterInfo.class,
                            },
                          })
                        ).then(() => {
                          setFilterInfo({
                            ...filterInfo,
                            utility,
                            isFilterUpdated: true,
                          });
                        });
                        handleUtility(e.target.value);
                      }}
                      value={JSON.stringify(
                        utilities.length === 1
                          ? utilities[0]
                          : filterInfo.utility
                      )}
                    >
                      {utilities.length === 1 && (
                        <option value={JSON.stringify(utilities[0].LDCName)}>
                          {utilities[0].LDCName}
                        </option>
                      )}
                      {info.class === CLASS.RESIDENTIAL &&
                        utilities.length !== 1 && (
                          <option value={JSON.stringify({})}>
                            Select Utility
                          </option>
                        )}
                      {utilities.length !== 1 &&
                        utilities.map((utilityItem, index) => (
                          <option
                            key={index}
                            value={JSON.stringify(utilityItem)}
                          >
                            {utilityItem.LDCName}
                          </option>
                        ))}
                    </Form.Control>
                  </Col>
                  <Col
                    xl={4}
                    lg={4}
                    md={4}
                    className="px-1 mb-3 mb-md-0 mb-xl-0"
                  >
                    <Form.Control
                      as="select"
                      className="f-14"
                      onChange={(e) => {
                        const selectedSorting = SORTING_ORDERS.find(
                          (sortingOrder) =>
                            sortingOrder.id === Number(e.target.value)
                        );
                        setFilterInfo({
                          ...filterInfo,
                          order: selectedSorting.order,
                          sort:
                            selectedSorting.value === "Price" &&
                              preferedPriceDisplayFormat &&
                              info.class === CLASS.RESIDENTIAL
                              ? filters.commodity === COMMODITY.ELECTRIC
                                ? `Price${preferedPriceDisplayFormat}kwh`
                                : `Price${preferedPriceDisplayFormat}therm`
                              : selectedSorting.value,
                          isFilterUpdated: true,
                        });
                      }}
                    >
                      {SORTING_ORDERS.map((each) => (
                        <option key={each.id} value={each.id}>
                          {`Sort by ${each.name}`}
                        </option>
                      ))}
                    </Form.Control>
                  </Col>
                  <Col xl={4} lg={4} md={4} className="btn-px">
                    {pricesForComparison.length >= 1 ? (
                      <Button
                        variant="info"
                        className="f-14 btn-block"
                        onClick={() => {
                          if (pricesForComparison.length === 1)
                            setIsOnePriceSelected(true);
                          else {
                            analytics.sendEventWithData("Compare Prices", {
                              pricesForComparison:
                                JSON.stringify(pricesForComparison),
                            });
                            history.push("/price-selection");
                          }
                        }}
                      >
                        <i className="fa fa-heart mr-2"></i>Compare
                      </Button>
                    ) : (
                      <Overlay content="Select options to compare.">
                        <span>
                          <Button
                            variant="info"
                            className="f-14 btn-block"
                            style={{ pointerEvents: "none" }}
                            disabled={true}
                          >
                            <i className="fa fa-heart mr-2"></i>Compare
                          </Button>
                        </span>
                      </Overlay>
                    )}
                  </Col>
                </Row>
              )}
              {isOnePriceSelected && (
                <Row>
                  <Col md={4} lg={4}>
                    <div></div>
                  </Col>
                  <Col md={6} lg={6}>
                    <Form.Label>
                      You have only selected one option, would you like to go
                      straight to the Selected Rate page?
                    </Form.Label>
                  </Col>
                  <Col md={2} lg={2}>
                    <Button
                      variant="outline-primary"
                      className="f-14 btn-block mb-2"
                      onClick={() => {
                        setIsOnePriceSelected(false);
                        suppliersToBeCompared[0].PlanId
                          ? dispatch(setSteps(stepsForEnrollmentandPlanId))
                          : dispatch(setSteps(updatedStepsForFalseEnrollment));
                        dispatch(setShouldShowRFQDetails(false));
                        dispatch(setIsPriceExpired(false));
                        dispatch(
                          setSelectedSupplier({
                            ...suppliersToBeCompared[0],
                            supplierLogo:
                              suppliersLogo[
                              suppliersToBeCompared[0].SupplierId
                              ],
                            supplierDetails: supplierDetails(
                              suppliersToBeCompared[0]
                            ),
                          })
                        );
                        history.push("info");
                        portalSettings.discloseMargin &&
                          dispatch(
                            getApplicableMargin({
                              supplierId: suppliersToBeCompared[0].SupplierId,
                              stateId: basicInfo.state.id,
                              commodity: filters.commodity,
                              utilityId: suppliersToBeCompared[0].LDCId,
                              rateClass: suppliersToBeCompared[0].RateClass,
                              brokerageId,
                            })
                          );
                      }}
                    >
                      Yes
                    </Button>
                  </Col>
                </Row>
              )}
              <ShowMoreFilters
                filterInfo={filterInfo}
                setFilterInfo={setFilterInfo}
                setInfo={setInfo}
                setLocalZipCode={setLocalZipCode}
                info={info}
                localZipCode={localZipCode}
                suppliers={searchedPricesSuppliers}
                searchedPrices={searchedPrices}
                initialSupplierData={initialSupplierData}
                brokerageId={brokerageId}
                filters={filters}
                setIsSupplierUpdated={setIsSupplierUpdated}
                setShowUtilityError={setShowUtilityError}
              />
            </Card.Body>
          </div>
          {isPriceExpired && showPriceExpiredAlert && opportunityId && (
            <Alert
              variant="danger"
              dismissible
              onClose={() => setShowPriceExpiredAlert(false)}
            >
              <p>Price Expired. Please Select New.</p>
            </Alert>
          )}
          {(searchedPrices.length === 0 && show) || showUtilityError ? (
            <BusyIndicator region={REGIONS.GET_SUPPLIERS}>
              <Alert
                variant="danger"
                onClose={() => {
                  setShow(false);
                  setShowUtilityError(false);
                }}
              >
                <p>
                  {showUtilityError
                    ? `We're sorry, but there are no ${COMMODITIES_LIST[filters.commodity]
                    } options for your zipcode. Check back later!`
                    : messageOnEmptyResult ||
                    DEFALUT_NO_SUPPLIER_LOADED_MESSAGE}
                </p>
                <Button
                  onClick={() => {
                    history.push("contact-me");
                  }}
                  className="wizard-footer d-flex ml-auto "
                >
                  Contact Me
                </Button>
              </Alert>
            </BusyIndicator>
          ) : (
            <BusyIndicator region={REGIONS.GET_SUPPLIERS}>
              <div
                className={classNames("card-responsive", {
                  fullscreen: isFullScreen,
                })}
              >
                {searchedPrices.map((supplier, index) => {
                  const supplierLogo = suppliersLogo[supplier.SupplierId];
                  return (
                    (productType === ALL_PRODUCT ||
                      supplier.ProductType === productType) && (
                      <div
                        className="d-flex align-items-center mb-3 position-relative"
                        key={index}
                      >
                        <Button
                          variant="link"
                          className="text-dark f-32 align-btn"
                          onClick={() => {
                            setIsOnePriceSelected(false);
                            dispatch(
                              setPriceForComparision({
                                selectedPriceIndex: index,
                                supplierDetails: supplierDetails(supplier),
                              })
                            );
                          }}
                        >
                          <i
                            className={classNames("fa fa-heart", {
                              "fa-heart-o":
                                !supplier.isSelectedForPriceComparision,
                            })}
                          ></i>
                        </Button>
                        <div className="card border flex-fill">
                          <Card.Body className="p-card">
                            <Row className="mt-4">
                              <Col md={2} className="mb-2">
                                {supplierLogo && (
                                  <div
                                    className="supplier-logo mr-5 mt-4"
                                    style={{
                                      verticalAlign: "top",
                                      display: "inline-block",
                                      textAlign: "center",
                                      width: "160px",
                                      height: "90px",
                                    }}
                                  >
                                    <Image
                                      src={supplierLogo}
                                      width="110em"
                                      height="55em"
                                      alt=""
                                    ></Image>
                                    {isEnerexStatePresent && supplier.PUCT && (
                                      <div style={{ display: "block" }}>
                                        <h6 className="f-14 text-muted">
                                          PUCT# {supplier.PUCT}
                                        </h6>
                                      </div>
                                    )}
                                  </div>
                                )}
                                {!supplierLogo && (
                                  <div
                                    style={{
                                      verticalAlign: "top",
                                      display: "inline-block",
                                      textAlign: "center",
                                    }}
                                  >
                                    <span className="sub-heading-text f-14 text-muted ml-20">
                                      {supplier.SupplierName}
                                    </span>
                                    {isEnerexStatePresent && supplier.PUCT && (
                                      <div style={{ display: "block" }}>
                                        <h6 className="sub-heading-text f-14 text-muted">
                                          PUCT# {supplier.PUCT}
                                        </h6>
                                      </div>
                                    )}
                                  </div>
                                )}
                              </Col>
                              <Col md={8} className="text-center mb-3 mb-md-0">
                                <Row>
                                  <Col md={12} lg="6">
                                    <Row className="mt-4">
                                      {supplier.ProductType &&
                                        supplier.ProductType !== "" && (
                                          <Col className="adjust-spacing-column-wise">
                                            <h4 className="heading-text">
                                              {supplier.ProductType}
                                            </h4>
                                            <span className="sub-heading-text-center f-14 text-muted">
                                              Product
                                            </span>
                                          </Col>
                                        )}
                                      {supplier.PlanName &&
                                        supplier.PlanName !== "" && (
                                          <Col className="adjust-spacing-column-wise">
                                            <h4 className="heading-text">
                                              {supplier.PlanName}
                                            </h4>
                                            <span className="sub-heading-text-center f-14 text-muted">
                                              Plan Name
                                            </span>
                                          </Col>
                                        )}
                                      {supplier.Term &&
                                        supplier.Term !== "" && (
                                          <Col className="adjust-spacing-column-wise">
                                            <h4 className="heading-text">
                                              {supplier.Term}
                                              <span> Months</span>
                                            </h4>
                                            <span className="sub-heading-text-center f-14 text-muted">
                                              Term
                                            </span>
                                          </Col>
                                        )}
                                    </Row>
                                  </Col>

                                  {filters.class === CLASS.RESIDENTIAL &&
                                    supplier.PlanId &&
                                    isEnerexStatePresentForPrice &&
                                    getPricesDisplay(supplier)}
                                  {supplier.Price &&
                                    !(
                                      filters.class === CLASS.RESIDENTIAL &&
                                      supplier.PlanId &&
                                      isEnerexStatePresentForPrice
                                    ) && (
                                      <Col className="adjust-spacing-column-wise mt-4">
                                        <h4 className="heading-text mt-2">
                                          {getFormattedPrice(
                                            supplier.Price,
                                            supplier.PlanId
                                          ).toFixed(2)}
                                          &cent;/
                                          {filters.commodity ===
                                            COMMODITY.ELECTRIC
                                            ? ELECTRIC
                                            : (supplier.UOM ? supplier.UOM : NAT_GAS)}
                                        </h4>
                                        <span className="sub-heading-text-center f-14 text-muted">
                                          Price
                                        </span>
                                      </Col>
                                    )}
                                </Row>
                              </Col>
                              <Col
                                md={2}
                                className="justify-content-center align-items-center"
                              >
                                <Button
                                  variant="outline-primary"
                                  className="mt-4 btn-block select-button"
                                  onClick={() => {
                                    supplier.PlanId
                                      ? dispatch(
                                        setSteps(stepsForEnrollmentandPlanId)
                                      )
                                      : dispatch(
                                        setSteps(
                                          updatedStepsForFalseEnrollment
                                        )
                                      );
                                    dispatch(setShouldShowRFQDetails(false));
                                    dispatch(setIsPriceExpired(false));
                                    dispatch(
                                      setSelectedSupplier({
                                        ...supplier,
                                        supplierLogo,
                                        supplierDetails:
                                          supplierDetails(supplier),
                                      })
                                    );
                                    dispatch(
                                      getSuppierAddressFields({
                                        brokerageId: brokerageId,
                                        supplierId: supplier.SupplierId,
                                        subscriptionKey: authKey,
                                      })
                                    );
                                    history.push("info");
                                    portalSettings.discloseMargin &&
                                      dispatch(
                                        getApplicableMargin({
                                          supplierId: supplier.SupplierId,
                                          stateId: basicInfo.state.id,
                                          commodity: filters.commodity,
                                          utilityId: supplier.LDCId,
                                          rateClass: supplier.RateClass,
                                          brokerageId,
                                        })
                                      );
                                    analytics.sendEventWithData(
                                      "Select Price",
                                      supplier
                                    );
                                  }}
                                >
                                  Select
                                </Button>
                                <Button
                                  variant="link"
                                  className={`f-14 d-block text-center btn-block view-more-button accordion_button`}
                                  onClick={() => {
                                    setCardDetails(!cardDetails);
                                    let accordianArr = [...chosenAccordian];
                                    if (accordianArr.includes(index)) {
                                      accordianArr = accordianArr.filter(
                                        (val) => !(val === index)
                                      );
                                    } else {
                                      accordianArr.push(index);
                                    }
                                    setChosenAccordian(accordianArr);
                                  }}
                                >
                                  {chosenAccordian.includes(index)
                                    ? "Less"
                                    : "More"}{" "}
                                  Details
                                  <i
                                    className={classNames(
                                      "fa fa-chevron-up ml-2",
                                      {
                                        "fa-chevron-down":
                                          !chosenAccordian.includes(index),
                                      }
                                    )}
                                  />
                                </Button>
                              </Col>
                            </Row>
                            {/* Show price details here we can pass desription of the details as a props to the ShowPriceDetails Component */}
                            <ShowPriceDetails
                              supplier={supplier}
                              supplierDetails={supplierDetails(supplier)}
                              cardDetails={chosenAccordian.includes(index)}
                            />
                          </Card.Body>
                        </div>
                      </div>
                    )
                  );
                })}
              </div>
            </BusyIndicator>
          )}
        </Card.Body>
      </Card>
    </main>
  );
};

export default ShowPrices;
