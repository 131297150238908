import { monthNames } from "./showPrice.constants";

export const getMonths = (endDate) => {
  let date = new Date(endDate || new Date());
  date = new Date(date.getFullYear(), date.getMonth(), 1); // Note: To set the 1st date of month.
  date.setMonth(endDate ? date.getMonth() + 1 : date.getMonth());
  return Array(12)
    .fill()
    .map(() => {
      let m = date.getMonth();
      let n = date.getFullYear();
      date.setMonth(m + 1, 1);
      return { month: monthNames[m], year: n };
    });
};

export const getMonth = () => {
  const date = new Date();
  const month = date.getMonth();
  const year = date.getFullYear();
  return { month: monthNames[month], year };
};

export const getMonthIndex = (month) =>
   monthNames.findIndex((each)=>each===month)+1

export const getYears = (endDate) => {
  const date = new Date(endDate || new Date());
  const month = date.getMonth();
  let startingYear = date.getFullYear();
  return Array(month?2:1)
       .fill()
       .map(()=>startingYear++) 
}

export const getMonthsForYear =()=>{

}