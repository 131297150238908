import { createSlice } from "@reduxjs/toolkit";
import * as asyncActions from "./accessTokens.asyncActions";

const initialState = {
  accessToken:null,
};

const slice = createSlice({
  name: "accessTokens",
  initialState,
  //   reducers: {
  //
  //   },
  extraReducers: {
    [asyncActions.getAccessToken.fulfilled]: (state, action) => {
      state.accessToken = action.payload?action.payload.access_token:initialState.accessToken
    }
  }
});

export default slice;

export const { name, actions, reducer } = slice;
