export const appCues = (brokerageId) => {  
    if(window.Appcues){
        window.AppcuesSettings = {
            enableURLDetection: true
        };
        window.Appcues.identify(brokerageId);
        window.Appcues.page();
        window.Appcues.track('onclick');
    }  
}
