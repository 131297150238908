import * as selectors from "./accessTokens.selectors";
import * as asyncActions from "./accessTokens.asyncActions";
import slice from "./accessTokens.slices";

export const {
  name,
  // actions: {},
  reducer
} = slice;

export const { getAccessToken } = asyncActions;

export const { selectAccessToken } = selectors;

