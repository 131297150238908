import React from "react";
import { Fields } from "./Field";

const FieldGroup = ({ field, onFieldChange, data, fieldsResult }) => {
  const conditionalVisibilityOnField = field.conditionalVisibilityOnField;
  const operator = field.operator;

  const isValid = (conditionalVisibilityOnField) => {
    if (conditionalVisibilityOnField) {
      if (Array.isArray(conditionalVisibilityOnField)) {
        if (Boolean(operator) && operator === "or")
          return conditionalVisibilityOnField.some((each) => {
            return data[each?.name] === each.valueWhenShown;
          });

        return conditionalVisibilityOnField.every((each) => {
          return data[each?.name] === each.valueWhenShown;
        });
      } else
        return (
          data[conditionalVisibilityOnField?.name] ===
          conditionalVisibilityOnField.valueWhenShown
        );
    } else return true;
  };

  return (
    isValid(conditionalVisibilityOnField) && (
      <>
        <h5 className="mb-3">{field.friendlyName}</h5>
        {field?.fields?.map((field, index) => {
          const conditionalField = field.conditionalVisibilityOnField;

          if (field.type === "fieldGroup") {
            return (
              <React.Fragment key={index}>
                <FieldGroup
                  field={field}
                  onFieldChange={onFieldChange}
                  data={data}
                  fieldsResult={fieldsResult}
                />
              </React.Fragment>
            );
          }

          if (isValid(conditionalField))
            return (
              <React.Fragment key={index}>
                <Fields field={field} onFieldChange={onFieldChange} data={data} fieldsResult={fieldsResult}/>{" "}
              </React.Fragment>
            );

          return null;
        })}
      </>
    )
  );
};

export default FieldGroup;
