export const CLASS = {
  RESIDENTIAL: "Residential",
  COMMERCIAL: "Commercial",
};

export const COMMODITY = {
  NONE: 0,
  ELECTRIC: 1,
  NAT_GAS: 2,
};

export const COMMODITIES_LIST=["","Electric","NatGas"]

export const TIERS = {
  SMALL: "Small",
  MID: "Mid",
  LARGE: "Large",
  NONE: "None",
};

export const UNITS = {
  KWH: "kWh",
  TH: "Th"
};

export const PORTAL_SETTINGS = {
  ANNUAL_USAGE: "Annual Usage",
  MONTHLY_BILL: "Monthly Bill"
};