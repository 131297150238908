import React, { useState, useEffect, useRef } from "react";
import "font-awesome/css/font-awesome.min.css";
import { Formik } from "formik";
import queryString from "query-string";
import classNames from "classnames";
import * as yup from "yup";
import { Row, Col, Button, Form, Table, Card, Image, Alert, InputGroup, Tooltip, OverlayTrigger } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { actions } from "./priceAndCustomerDetails.slice";
import Stepper from "./Stepper";
import SupplierCard from "./SupplierCard";
import { useHistory } from "react-router-dom";
import {
  selectSteps,
  selectCustomerAccountsInfo,
  selectSelectedSupplier,
  selectUSStates,
  selectIsAccountDeleted,
  selectCustomerInfo,
  selectCustomerLegalEntityName,
  selectHasEmptyEnrollementAndSupplierFields,
  selectHasEmptyAdditionalInfoFields,
  selectSupplierFields,
  selectTaxExemptValue,
  selectAccountByAddress
} from "./priceAndCustomerDetails.selectors";
import {
  selectShouldShowRFQDetails,
  selectUtilities,
  selectRateCodes,
  getRateCodes,
  selectPrice,
  getUtilitiesFromZip,
  getUtilitiesForStateByZip,
  selectUserSettings,
  TIERS,
  selectTier,
  COMMODITIES_LIST,
  selectIsCustomPricing,
  selectPortalSetting,
  COMMODITY,
  CLASS
} from "../GetPrice";
import {
  getUSStates,
  saveAllInformation,
  searchForAccountByAddress
} from "./priceAndCustomerDetails.asyncActions";
import BusyIndicator, { REGIONS } from "../../widgets/busyIndicator";
import NumberFormat from "react-number-format";
import { ENEREX_STATES, unit } from "./priceAndCustomerDetails.constant";
import Overlay from "../../widgets/Tooltip";
import Images from "../../assets/images";
import { selectFilters } from "../ShowPrices/showPrices.selectors";
import isEmpty from "lodash/isEmpty";
import handleCloseWidget from "../../helper/handleCloseWidget";
import { saveCustomerInfo } from ".";
import { selectClientConfig } from "../clientContext";
import LargeTierCard from "./LargeTierCard";
import { getUsage } from "../../helper/getUsage";
import { getMonthIndex } from "../ShowPrices/showPrices.utils";
import { UNITS } from "../GetPrice/getPrice.constants";
import analytics from "../../helper/analytics";
import { getFormattedUsage } from "../utils/getFormattedUsage";
import Select, { components } from 'react-select';
import { getNamedBusyIndicator } from "../../widgets/busyIndicator/busyIndicator.selectors"
import {showAddressLookup, showESIIDorECLID} from "./priceAndCustomerDeatils.helpers";

const Input = (props) => <components.Input {...props} isHidden={false} />;

const NoOptionsMessage = props => {
  return (
    <components.NoOptionsMessage {...props}>
      No records found?
      Remove the street type- Drive, Court, etc - and search again.
    </components.NoOptionsMessage>
  );
};

const DropdownIndicator = props => {
  return null;

};

const { NAT_GAS, ELECTRIC } = unit;

const {
  addToCustomerAccountsInfo,
  addToCustomerAccountsInfoDetail,
  removeFromCustomerAccountsInfo,
  resetSearchedAccount,
} = actions;

const EMPTY_STRING = "";
const { TH, KWH } = UNITS

const AccountInformation = () => {
  const dispatch = useDispatch();
  const clientConfig = useSelector(selectClientConfig);
  const customerAccountInfo = useSelector(selectCustomerAccountsInfo);
  const USStates = useSelector(selectUSStates);
  const userSettings = useSelector(selectUserSettings);
  const commercialUtilities = useSelector(selectUtilities);
  const basicInfo = useSelector(selectPrice);
  const rateCodes = useSelector(selectRateCodes);
  const customerInfo = useSelector(selectCustomerInfo);
  const steps = useSelector(selectSteps);
  const [searchedAccount, setSearchedAccount] = useState({});
  const [searchedESIID, setSearchedESIID] = useState(null);
  const history = useHistory();
  const shouldShowRFQDetails = useSelector(selectShouldShowRFQDetails);
  const [isTableEmpty, setIsTableEmpty] = useState(false);
  const [hasSameAsBillingAddresses, setHasSameAsBillingAddresses] = useState(false)
  const isEnerexStatePresent = basicInfo.state && ENEREX_STATES.includes(basicInfo.state.name);
  const isStateTexas = basicInfo.state && showAddressLookup(basicInfo.state.id);
  const { did: dealId, oid: opportunityId } = queryString.parse(
    window.location.search
  );
  const isCustomPricing = useSelector(selectIsCustomPricing)
  const selectedSupplier = useSelector(selectSelectedSupplier);
  const filters = useSelector(selectFilters);
  const isAccountDeleted = useSelector(selectIsAccountDeleted);
  const tier = useSelector(selectTier);
  const taxExemptValue = useSelector(selectTaxExemptValue)
  const { brokerageId, isFullScreen } = clientConfig;
  const stateNameAbbreviation = basicInfo.state.abbreviation
  const utilities = commercialUtilities
  const updatedRateCodes = rateCodes.map((each) => ({
    ...each,
    displayName: isEnerexStatePresent ? each.LoadProfile : each.RateClass,
  }));
  const portalSetting = useSelector(selectPortalSetting)
  const customerLegalEntityName = useSelector(selectCustomerLegalEntityName);
  const hasEmptyEnrollementAndSupplierFields = useSelector(selectHasEmptyEnrollementAndSupplierFields);
  const hasEmptyAdditionalInfoFields = useSelector(selectHasEmptyAdditionalInfoFields)
  const [show, setShow] = useState(false)
  const fieldsData = useSelector(selectSupplierFields);
  const accountAddresses = useSelector(selectAccountByAddress)
  const isSearching = useSelector(getNamedBusyIndicator(REGIONS.SEARCH_ACCOUNT))

  const { dealAccount, pipelineAccount, stateId, pipelineContacts, stateName } = userSettings
  const [searchInputValue, setSearchInputValue] = useState(customerInfo.billingAddress.address)
  const [value, setValue] = useState()
  const [menuIsOpen, setMenuIsOpen] = useState(false)
  const portalSettings = useSelector(selectPortalSetting);
  const findUtility = (utilityName) =>
    utilities && utilities.find((each) => each.LDCName === utilityName);

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Remove
    </Tooltip>
  );
  const ref = useRef()
  useEffect(() => {
    if ((dealId || opportunityId) && isEmpty(userSettings)) {
      history.push("/");
    }
  }, [dealId, opportunityId, history, userSettings]);

  useEffect(() => {
    if (Object.keys(customerInfo).length === 0) {
      if (dealId || opportunityId) {
        const customerInfoData = {
          firstName: (pipelineContacts && pipelineContacts.length > 0 && pipelineContacts[0].firstName) || EMPTY_STRING,
          lastName: (pipelineContacts && pipelineContacts.length > 0 && pipelineContacts[0].lastName) || EMPTY_STRING,
        };
        dispatch(saveCustomerInfo(customerInfoData));
      }
    }
  }, [dealId, opportunityId, history, pipelineContacts, customerInfo, dispatch]);

  useEffect(() => {
    isEmpty(USStates) && dispatch(getUSStates());
  }, [dispatch, USStates]);

  useEffect(() => {
    if (dealId || opportunityId) {
      isEmpty(rateCodes) && dispatch(
        getRateCodes({
          filters: {
            LDCIdentifiers: [basicInfo?.utility?.LDCIdentifier],
            Commodity: COMMODITIES_LIST[basicInfo.commodity],
            Suppliers: filters.Suppliers,
            StateId: basicInfo.state.id,
            StateName: basicInfo.state.name,
            ZipCode: basicInfo.zipCode,
            BrokerId: brokerageId,
            RateClassification: basicInfo.class,
          },
        })
      );

      dispatch(getUtilitiesFromZip({ zipcode: basicInfo.zipCode, commodityId: basicInfo.commodity })).then(({ payload }) => {
        if (isEmpty(payload) && tier === TIERS.LARGE) {
          dispatch(getUtilitiesForStateByZip({ zipcode: basicInfo.zipCode, commodityId: basicInfo.commodity }))
        }
      })
    }
  }, [
    dispatch,
    isCustomPricing,
    basicInfo.class,
    basicInfo.zipCode,
    basicInfo.state,
    basicInfo.utility,
    basicInfo.commodity,
    brokerageId,
    filters,
    tier,
    selectedSupplier.LDCId,
    dealId,
    opportunityId,
    rateCodes
  ]);

  const getStateNameByStateId = (stateId) => {
    const stateDataArray = USStates.find(
      (states) => states.id === stateId
    );
    const stateName = stateDataArray?.displayName;
    return stateName;
  };

  const getStateIdByStateName = (stateName) => {
    const state = USStates.find(
      (each) => each.name.replaceAll("_", " ") === stateName?.toLowerCase()
    )
    return state?.id || ""
  };

  useEffect(() => {
    if (!isAccountDeleted && customerAccountInfo.length === 0) {
      if (dealAccount) {
        const accountInformations = dealAccount.map((info) => ({
          accountNumber: info.accountNumber,
          annualUsage: info.annualUsage,
          meterNumber: info.meter,
          state: info.stateName || stateName,
          utility: info.utilityName,
          utilityId: findUtility(info.utilityName)?.LDCIdentifier,
          serviceAddress:
          {
            address:
              info.serviceAddressStreet,
            stateId: getStateIdByStateName(info.stateName || stateName),
            city: info.serviceAddressCity,
            zipCode: info.serviceAddressZip,
          },
        }));
        dispatch(addToCustomerAccountsInfoDetail(accountInformations));
      }
      if (pipelineAccount) {
        const accountInformations = pipelineAccount.map(
          (info) => ({
            accountNumber: info.accountNumber,
            annualUsage: info.annualUsage,
            utility: info.utilityName,
            utilityId: findUtility(info.utilityName)?.LDCIdentifier,
            meterNumber: info.meter,
            serviceAddress: {
              address: info.serviceAddressStreet,
              stateId: getStateIdByStateName(info.stateName || stateName),
              city: info.serviceAddressCity,
              zipCode: info.serviceAddressZip,
            },
          })
        );
        dispatch(addToCustomerAccountsInfoDetail(accountInformations));
      }
    }
    // eslint-disable-next-line
  }, [
    dispatch,
    dealAccount,
    pipelineAccount,
    stateId,
    customerAccountInfo,
    isAccountDeleted,
    basicInfo,
    stateName
  ]);

  const validation = yup.object().shape({
    accountNumber: yup
      .string()
      .required(
        `${showESIIDorECLID(basicInfo?.state?.id) ? "ESIID" : "Account Number"}  is required`
      )
      .test(
        "test-number",
        `${showESIIDorECLID(basicInfo?.state?.id) ? "ESIID" : "Account Number"} already exists`,
        (value) =>
          !customerAccountInfo.some((each) => each.accountNumber === value)
      ).matches(/^[a-zA-Z0-9]*$/, "Should not contain special characters"),
    annualUsage: yup.string()
      .test(
        "annual usasge",
        "Annual Usage is required.",
        (value) => shouldShowRFQDetails ? !!value : true),
    city: yup.string().required("City is required"),
    meterNumber: yup.string().matches(/^[a-zA-Z0-9]*$/, "Should not contain special characters"),
    address: yup.string().required("Service Address is required"),
    state: yup.string().required("State is required"),
    utility: yup.string().required("Utility is Required."),
    zipCode: yup
      .string()
      .min(5, "Zip Code must contain at least 5 digits")
      .required("Zip Code is required"),
    rateCode: yup.string(),
  });

  const getStateName = (stateAbbreviation) => {
    const stateName = USStates.find(
      (states) => states.abbreviation === stateAbbreviation
    );
    return (
      stateName &&
      stateName.displayName
    );
  };
     
  const initialValues = {
    accountNumber:
      searchedAccount?.accountNumber || searchedAccount?.esiId || "",
    annualUsage: searchedAccount?.annualUsage || "",
    city: searchedAccount?.city || basicInfo?.state?.city ||"",
    meterNumber: searchedAccount?.meterNumber || "",
    address: searchedAccount?.serviceAddress || searchedAccount?.address || searchedAccount?.serviceAddress1 || "",
    state:
      getStateName(searchedAccount?.state) ||
      getStateName(basicInfo?.state?.abbreviation) ||
      "",
    utility: searchedAccount?.ldc ||
      selectedSupplier?.LDCName ||
      basicInfo?.utility?.LDCName ||
      "",
    zipCode: searchedAccount?.zipCode?.substring(0, 5) || searchedAccount?.zip?.substring(0, 5) ||  basicInfo?.zipCode || "",
    rateCode: searchedAccount?.rateCode || basicInfo?.rateCode || "",

  };

  const submitData = () => {
    const {
      PlanId,
      SupplierId,
      SupplierName,
      Term,
      ProductType,
      Price,
      RateClass,
      PlanDescription,
      GreenPercentage,
      UDCCode,
      UDCID,
      Zone,
      UOM
    } = selectedSupplier;

    const isElectricCommodity = basicInfo.commodity === COMMODITY.ELECTRIC

    const quote = {
      planId: basicInfo.class === CLASS.RESIDENTIAL ? PlanId : null,
      supplierId: SupplierId,
      supplierName: SupplierName,
      term: Term,
      product: ProductType,
      price: Price,
      notes: PlanDescription,
      rateClass: RateClass,
      Unit: isElectricCommodity
        ? ELECTRIC
        : UOM || NAT_GAS,
      GreenPercentage,
      UDCCode: basicInfo.class === CLASS.RESIDENTIAL ? UDCCode : undefined,
      UDCID: basicInfo.class === CLASS.RESIDENTIAL ? UDCID : undefined,
      LoadZoneName: Zone,
    };
    const meters = customerAccountInfo.map(({ utility, ...each }) => each);

    const usage = getUsage(portalSetting.keyValue, basicInfo)

    const documents = customerInfo?.files?.map(({ size, ...file }) => ({
      data: file,
    }));
    const contact = { ...customerInfo }
    delete contact.files
    analytics.sendEventWithData("Account Information", customerAccountInfo)
    const dataToSave = {
      opportunityId: opportunityId,
      dealId: dealId,
      customerLegalEntityName,
      taxExemptValue,
      class: basicInfo.class,
      commodityTypeId: basicInfo.commodity,
      annualUsageOrMonthlyBill: usage,
      zipCode: basicInfo.zipCode,
      promoCode: basicInfo.referralCode,
      quote,
      contact,
      meters,
      documents,
      startMonth: getMonthIndex(filters.month),
      startYear: filters.year,
      stateId: basicInfo.state.id,
      brokerageId,
    }
    if (fieldsData?.id)
      dataToSave.contractTemplate = {
        contractTemplateId: fieldsData?.id,
        contractSpecificFields: {},
      }
    dispatch(
      saveAllInformation({
        customerData: dataToSave,
      })
    ).then((res) => {
      if (res.payload)
        history.push("review")
      else
        setShow(true)
    });
  }

  const checkIsBillingAddressValid = (customerInfo) =>
    customerInfo && Object.values(customerInfo.billingAddress).every(each => !!each);

  const handleFormatUsage = (e) => e.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  const options = showESIIDorECLID(basicInfo?.state?.id) ? accountAddresses.map((each, index) => ({
    label: each.address,
    value: each.esiId,
    ind : index
  })) : accountAddresses.map((each, index) => ({
    label: each.fullAddress,
    value: each.accountNumber,
    ind : index
  }));

  const loadSearchAccountOptions = (q) => {
    if (q !== "") {
      const data = showESIIDorECLID(basicInfo?.state?.id) ? {
        "ESIID": "",
        "SearchText": q,
        "PageSize": 1000,
        "PageNumber": 1,
        "StateName": stateNameAbbreviation,
        "LDCName": initialValues.utility,
        "ZipCode": basicInfo.zipCode,
        "SearchType": "Address",
        "OrderBy": "address",
        "CustomerClass": [],
        "Type" : "esiid"
      } : {
        "StateName": stateNameAbbreviation,
        "SortColumnName": "CustomerName",
        "Start": 0,
        "Length": 1000,
        "SearchText": q,
        "Type": "ecl"
      }
      dispatch(searchForAccountByAddress(data))
    }
  }

  return (
    <div className={classNames("inner-widget", { "px-20": !isFullScreen })}>
      <Card className={classNames("p-4", { "min-h-100": isFullScreen })}>
        <div className="mb-4 py-2">
          <div className="d-flex align-items-center justify-content-between">
            <Button
              variant="outline-primary"
              onClick={() => history.push("info")}
            >
              Back to Info
            </Button>
            {shouldShowRFQDetails && <b>Custom Pricing Details</b>}
            <div className='d-flex align-items-center'>
            {portalSettings.phoneWork && <h6 className="sub-heading-text f-14 text-muted">Need Assistance?: Call {portalSettings.phoneWork}</h6>}
            <Button
              variant="outline-light"
              onClick={() => handleCloseWidget(dispatch, history)}
            >
              <i className="fa fa-times fa-2x f-20"></i>
            </Button>
            </div>
          </div>
        </div>
        <Row className="justify-content-center mb-3">
          {!shouldShowRFQDetails && (
            <BusyIndicator region={REGIONS.GET_SUPPLIERS}>
              <SupplierCard />
            </BusyIndicator>
          )}
          {tier === TIERS.LARGE && <LargeTierCard />}

          <Col lg={8}>

            <div className="wizard ">
              <Stepper pages={steps} currentPath="accounts" />
              <BusyIndicator region={REGIONS.SAVE_ALL_INFORMATION}>
                {show && (
                  <Alert
                    variant="danger"
                    onClose={() => setShow(false)}
                    dismissible
                  >
                    <p>Unable To Save Details</p>
                  </Alert>
                )}
                <div className="wizard-content" data-step="step1">
                  {/* Need to add logic to show for allowedRegions 
                
                This check of states mignt need to remove in future
                */}
                  {isStateTexas && (<Form.Group as={Row} className="mb-0 align-items-center">
                    <Col xs={12} sm={3} md={3} className="mb-15">
                      <Form.Label>
                        Search by Address
                      </Form.Label>
                    </Col>

                    <Col xs={12} sm={6} md={6} className="mb-15 d-flex align-items-center">

                      <div className="flex-fill">
                        <Select classNamePrefix="mySelect"
                          ref={ref}
                          onFocus={(e) => {
                            setSearchInputValue(value || searchedAccount?.address || customerInfo.billingAddress.address)
                            loadSearchAccountOptions(value || searchedAccount?.address || customerInfo.billingAddress.address)
                            setMenuIsOpen(true)
                          }}
                          options={options}
                          placeholder={searchInputValue}
                          onChange={(e) => {
                            const data = accountAddresses.find((each, index) => each.esiId === e.value || (each.accountNumber ? each.accountNumber === e.value : index === e.ind))
                            setSearchedAccount(data)
                            setSearchedESIID(data?.esiId || data?.accountNumber);
                            setSearchInputValue(e.label)
                            setValue(e.label)
                          }

                          }
                          show={isSearching}
                          inputValue={searchInputValue}
                          isLoading={isSearching}
                          value={value}
                          onInputChange={(e, { action }) => {
                            if (action === "input-change") {
                              setSearchInputValue(e)
                              setValue(e)
                              loadSearchAccountOptions(e)
                              setMenuIsOpen(true)

                            }
                            if (action === "menu-close") {
                              setMenuIsOpen(false)
                            }
                          }}
                          menuIsOpen={menuIsOpen}
                          onBlur={() => { setMenuIsOpen(false) }}
                          components={{
                            Input, DropdownIndicator, NoOptionsMessage
                          }}
                          filterOption={() => (options)}
                        />
                      </div>
                      <Button className="ml-8" onClick={() => {
                        if (ref && ref.current) {
                          ref.current.onInputFocus()
                          setMenuIsOpen(true)
                        }
                      }}>Search</Button>
                    </Col>
                  </Form.Group>)}
                  <Formik
                    initialValues={initialValues}
                    validationSchema={validation}
                    enableReinitialize
                    onSubmit={(values, { resetForm }) => {
                      const {
                        accountNumber,
                        meterNumber,
                        annualUsage,
                        address,
                        city,
                        zipCode,
                        rateCode,
                        state,
                        utility,
                      } = values;
                      const selectedStateId = getStateIdByStateName(state);
                      const utilitySelected = findUtility(utility);

                      let accountValues = {
                        utilityId: isCustomPricing ? utilitySelected?.LDCIdentifier : selectedSupplier.LDCId,
                        accountNumber,
                        serviceAddress: {
                          address: address,
                          city: city,
                          stateId: selectedStateId,
                          zipCode: zipCode,
                        },
                        utility,
                      };
                      if (meterNumber)
                        accountValues = { ...accountValues, meterNumber }
                      if (shouldShowRFQDetails)
                        accountValues = { ...accountValues, rateClassOrLoadProfile: rateCode, annualUsage }

                      dispatch(resetSearchedAccount());
                      setIsTableEmpty(false);
                      resetForm(initialValues);
                      setHasSameAsBillingAddresses(false);
                      dispatch(addToCustomerAccountsInfo(accountValues));
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      setFieldValue,
                      handleSubmit,
                      handleChange,
                      handleBlur
                    }) => {
                      const handleSameAsBillingAddress = (billingAddress, ev) => {
                        const shouldBeFilled = !hasSameAsBillingAddresses;
                        setHasSameAsBillingAddresses(shouldBeFilled);
                        if (shouldBeFilled) {
                          const { address, city, stateId, zipCode } = billingAddress;
                          const stateName = initialValues.state
                          setFieldValue("address", address);
                          setFieldValue("city", city);
                          setFieldValue("state", stateName);
                          setFieldValue("zipCode", zipCode);
                        }
                        else {
                          const zipCode = searchedAccount?.zipCode?.substring(0, 5) || basicInfo?.zipCode || "";
                          const state = getStateName(searchedAccount?.state) || getStateName(basicInfo?.state?.abbreviation) || "";
                          setFieldValue("address", "");
                          setFieldValue("city", "");
                          setFieldValue("state", state);
                          setFieldValue("zipCode", zipCode);
                        }
                        setTimeout(() => {
                          handleChange(ev)
                        })
                      };

                      const selectedUtility =
                        !isEmpty(values.utility) &&
                        utilities.find((e) => e.LDCName === values.utility);
                      const format = selectedUtility && selectedUtility.format;

                      return (
                        <Form.Group>
                          <Form.Label className="required">Account Information to Add</Form.Label>
                          <Row className="mx-n1 mb-3">
                            <Col lg={6} className="px-1 mt-3">
                              <Form.Group>
                                {!isCustomPricing ? <Form.Control
                                  as="select"
                                  disabled={true}
                                  value={values.utility}
                                >
                                  <option value={values.utility}>
                                    {values.utility}
                                  </option>
                                </Form.Control> :
                                  <Form.Control
                                    as="select"
                                    name="utility"
                                    className={classNames({
                                      error: errors.utility && touched.utility,
                                    })}
                                    value={values.utility}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  >
                                    <option key="0" value="">
                                      Select Utility
                                    </option>
                                    {utilities.map((utility) => (
                                      <option
                                        key={utility.LDCName}
                                        value={utility.LDCName}
                                      >
                                        {utility.LDCName}
                                      </option>
                                    ))}
                                  </Form.Control>}
                                {errors.utility && touched.utility && (
                                  <div className="error-message mt-1">
                                    {errors.utility}
                                  </div>
                                )}
                              </Form.Group>
                            </Col>
                            <Col lg={isEnerexStatePresent ? 6 : 3} md={isEnerexStatePresent ? 12 : 6} className="px-1 mt-3">
                              <Form.Group>
                                <Form.Control
                                  type="text"
                                  name="accountNumber"
                                  className={classNames({
                                    error:
                                      errors.accountNumber &&
                                      touched.accountNumber,
                                    "form-input-tooltip": !values.accountNumber, //TODO Need to add this class when format doesn't matches
                                  })}
                                  placeholder={
                                    showESIIDorECLID(basicInfo?.state?.id)
                                      ? "ESIID"
                                      : "Account Number"
                                  }
                                  value={values.accountNumber || searchedESIID}
                                  onChange={(e) => { setSearchedESIID(e.currentTarget.value); handleChange(e); }}
                                  onBlur={handleBlur}
                                  maxLength={100}
                                />
                                {!values.accountNumber &&
                                  !isEmpty(format) && ( //TODO Need to add condition for format too.
                                    <Overlay
                                      content={
                                        showESIIDorECLID(basicInfo?.state?.id)
                                          ? `Enter ESIID Of This Format ${format}`
                                          : `Enter Account Number Of This Format ${format}`
                                      }
                                    >
                                      <Image
                                        src={Images.tooltipIcon}
                                        alt="tooltip-icon"
                                        className="tooltip-icon"
                                      />
                                    </Overlay>
                                  )}
                                {errors.accountNumber &&
                                  touched.accountNumber && (
                                    <div className="error-message mt-1">
                                      {errors.accountNumber}
                                    </div>
                                  )}
                              </Form.Group>
                            </Col>
                            {!isEnerexStatePresent && <Col lg={3} md={6} className="px-1 mt-3">
                              <Form.Group>
                                <Form.Control
                                  type="text"
                                  name="meterNumber"
                                  placeholder="Meter Number"
                                  className={classNames("form-control", {
                                    error:
                                      errors.meterNumber && touched.meterNumber,
                                    "form-input-tooltip": !values.meterNumber, //TODO Need to add this class when format doesn't matches
                                  })}
                                  value={values.meterNumber}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  maxLength={100}
                                />
                                {!values.meterNumber &&
                                  !isEmpty(format) && ( //TODO Need to add condition for format too.
                                    <Overlay
                                      content={`Enter Meter Number Of This Format ${format}`}
                                    >
                                      <Image
                                        src={Images.tooltipIcon}
                                        alt="tooltip-icon"
                                        className="tooltip-icon"
                                      />
                                    </Overlay>
                                  )}
                                {errors.meterNumber && touched.meterNumber && (
                                  <div className="error-message mt-1">
                                    {errors.meterNumber}
                                  </div>
                                )}
                              </Form.Group>
                            </Col>}
                          </Row>
                          {checkIsBillingAddressValid(customerInfo) && <Form.Group>
                            <Form.Label>Service Address</Form.Label>
                            <Form.Check
                            style={{width:'auto',height:'auto',clip:'inherit'}}
                              className='custom-form ml-4 d-inline'
                              id="same-as-billing-address"
                              label="Same as Billing Address"
                              onChange={(ev) => handleSameAsBillingAddress(customerInfo.billingAddress, ev)}
                              checked={hasSameAsBillingAddresses}
                            />
                          </Form.Group>}
                          <Row className="mx-n1 mb-3">
                            <Col lg={6} className="px-1 mt-3">
                              <Form.Group>
                                <Form.Control
                                  type="text"
                                  name="address"
                                  placeholder="Service Address"
                                  className={classNames({
                                    error: errors.address && touched.address,
                                  })}
                                  value={values.address}
                                  disabled={hasSameAsBillingAddresses}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  maxLength={100}
                                />
                                {errors.address && touched.address && (
                                  <div className="error-message mt-1">
                                    {errors.address}
                                  </div>
                                )}
                              </Form.Group>
                            </Col>
                            <Col lg={2} md={4} className="px-1 mt-3">
                              <Form.Group>
                                <Form.Control
                                  type="text"
                                  name="city"
                                  className={classNames({
                                    error: errors.city && touched.city,
                                  })}
                                  placeholder="City"
                                  value={values.city}
                                  disabled={hasSameAsBillingAddresses}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  maxLength={100}
                                />
                                {errors.city && touched.city && (
                                  <div className="error-message mt-1">
                                    {errors.city}
                                  </div>
                                )}
                              </Form.Group>
                            </Col>
                            <Col lg={2} md={4} className="px-1 mt-3">
                              <Form.Group>
                                <Form.Control
                                  as="select"
                                  name="state"
                                  className={classNames({
                                    error: errors.state && touched.state,
                                  })}
                                  placeholder="State"
                                  disabled={
                                    searchedAccount?.state || basicInfo.state || hasSameAsBillingAddresses
                                  }
                                  value={initialValues.state}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                >
                                  <option value="">Select state</option>
                                  {USStates.filter((states) =>
                                    states.country.includes("US")
                                  ).map((state) => {
                                    const stateName = state.displayName
                                    return (
                                      <option value={stateName} key={state.id}>
                                        {stateName}
                                      </option>
                                    );
                                  })}
                                </Form.Control>
                                {errors.state && touched.state && (
                                  <div className="error-message mt-1">
                                    {errors.state}
                                  </div>
                                )}
                              </Form.Group>
                            </Col>
                            <Col lg={2} md={4} className="px-1 mt-3">
                              <Form.Group>
                                <NumberFormat
                                  type="text"
                                  name="zipCode"
                                  allowLeadingZeros={true}
                                  placeholder="Zip Code"
                                  className={classNames("f-14 form-control", {
                                    error: errors.zipCode && touched.zipCode,
                                  })}
                                  value={values.zipCode}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  allowNegative={false}
                                  maxLength={100}
                                />
                                {errors.zipCode && touched.zipCode && (
                                  <div className="error-message mt-1">
                                    {errors.zipCode}
                                  </div>
                                )}
                              </Form.Group>
                            </Col>
                          </Row>

                          <Row className="mx-n1 mb-3">
                            <Col lg={5} className="px-1">
                              {shouldShowRFQDetails && <Form.Group className="input-group">
                                <NumberFormat
                                  type="text"
                                  name="annualUsage"
                                  className={classNames("form-control", {
                                    error:
                                      errors.annualUsage && touched.annualUsage,
                                  })}
                                  placeholder="Annual Usage"
                                  value={values.annualUsage}
                                  onChange={(e) =>
                                    setFieldValue("annualUsage", getFormattedUsage(e.target.value))
                                  }
                                  onBlur={handleBlur}
                                  allowNegative={false}
                                  thousandSeparator={true}
                                  maxLength={16}
                                />
                                <InputGroup.Text className="input-group-text-right">{basicInfo.commodity === COMMODITY.NAT_GAS ? TH : KWH}</InputGroup.Text>
                                {errors.annualUsage && touched.annualUsage && (
                                  <div className="error-message mt-1">
                                    {errors.annualUsage}
                                  </div>
                                )}
                              </Form.Group>}
                            </Col>
                            <Col lg={5} md={6} className="px-1">
                              {shouldShowRFQDetails && <Form.Group>
                                <Form.Control
                                  as="select"
                                  name="rateCode"
                                  className={classNames({
                                    error: errors.rateCode && touched.rateCode,
                                  })}
                                  value={values.rateCode}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                >
                                  <option key="0" value="">
                                    {isEnerexStatePresent
                                      ? "Select Load Profile"
                                      : "Select Rate Code"}
                                  </option>
                                  {updatedRateCodes.map((rateCode) => (
                                    <option
                                      key={rateCode.RateClass}
                                      value={isEnerexStatePresent ?
                                        rateCode.LoadProfile :
                                        rateCode.RateClass}
                                    >
                                      {rateCode.RateClass}
                                    </option>
                                  ))}
                                </Form.Control>
                                {errors.rateCode && touched.rateCode && (
                                  <div className="error-message mt-1">
                                    {errors.rateCode}
                                  </div>
                                )}
                              </Form.Group>}
                            </Col>
                            <Col lg={2} md={6} className="px-1">
                              <Form.Group>
                                <Button
                                  type="submit"
                                  className="btn btn-secondary btn-block"
                                  onClick={() => {
                                    handleSubmit();
                                  }}
                                >
                                  Add
                                </Button>
                              </Form.Group>
                            </Col>
                          </Row>
                        </Form.Group>
                      );
                    }}
                  </Formik>


                  <BusyIndicator region={REGIONS.GET_CUSTOMER_ACCOUNT_INFO}>
                    <div className="table-responsive">
                      <Table className="table table-bordered table-striped pd-half">
                        <thead>
                          <tr>
                            <th>Utility</th>
                            <th>
                            {showESIIDorECLID(basicInfo?.state?.id) ? "ESIID" : "Account Number"}
                            </th>
                            {!isEnerexStatePresent && <th>Meter Number</th>}
                            {shouldShowRFQDetails && <th>Annual Usage</th>}
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {customerAccountInfo.length === 0 ? (
                            <tr>
                              <td colSpan="5" className="text-center">
                                No accounts have been added yet
                              </td>
                            </tr>
                          ) : (
                            customerAccountInfo.map(
                              (
                                {
                                  utility,
                                  accountNumber,
                                  meterNumber,
                                  annualUsage,
                                },
                                index
                              ) => {
                                return (
                                  <tr key={index}>
                                    <td>{utility}</td>
                                    <td>{accountNumber}</td>
                                    {!isEnerexStatePresent && <td>{meterNumber}</td>}
                                    {shouldShowRFQDetails && <td>{handleFormatUsage(annualUsage)}</td>}
                                    <td className="text-center">
                                      <Button
                                        variant="link"
                                        className="p-0 text-danger"
                                        onClick={() =>
                                          dispatch(
                                            removeFromCustomerAccountsInfo(
                                              accountNumber
                                            )
                                          )
                                        }
                                      >
                                        <OverlayTrigger
                                          placement="top"
                                          overlay={renderTooltip}
                                        >
                                          <i className="fa fa-trash trash-alt fa-2x"></i>
                                        </OverlayTrigger>
                                      </Button>
                                    </td>
                                  </tr>
                                );
                              }
                            )
                          )}
                        </tbody>
                      </Table>
                    </div>
                  </BusyIndicator>
                  {isTableEmpty && (
                    <div className="error-message mt-1">
                      Please add Account Information
                    </div>
                  )}
                </div>
                <div className="wizard-footer d-flex">
                  <Button
                    variant="primary"
                    className="ml-auto"
                    onClick={() => {
                      if (customerAccountInfo?.length > 0 || shouldShowRFQDetails) {
                        if (!hasEmptyEnrollementAndSupplierFields && !hasEmptyAdditionalInfoFields) {
                          analytics.sendEventWithData("Account Information", { accounts: JSON.stringify(customerAccountInfo) })
                          if (shouldShowRFQDetails) history.push("contract");
                          else if (selectedSupplier.PlanId) {
                            history.push("enrollment");
                          } else {
                            history.push("supplier-info");
                          }
                        }
                        else submitData()
                      } else setIsTableEmpty(true);
                    }}
                  >
                    Next
                  </Button>
                </div>
              </BusyIndicator>
            </div>
          </Col>
        </Row>
      </Card>
    </div>
  );
};

export default AccountInformation;
  