import React, { useState, useEffect } from "react";
import { Button, Card, Image } from "react-bootstrap";
import Slider from "react-slick";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  setSelectedSupplier,
  selectSteps,
  setSteps,
  ENEREX_STATES
} from "../PriceAndCustomerDetails";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { setShouldShowRFQDetails, selectSuppliersLogo, COMMODITY, CLASS, selectPrice } from "../GetPrice";
import PriceSelectionAccordion from "./PriceSelectionAccordion";
import handleCloseWidget from "../../helper/handleCloseWidget";
import { selectSearchedPrices } from "../GetPrice/getPrice.selectors";
import { selectFilters } from "../ShowPrices";
import { unit } from "../PriceAndCustomerDetails";
import { getFormattedPrice } from "../../helper/getFormattedPrice";
import analytics from "../../helper/analytics"
import { actions as getPricesAction } from "../GetPrice/getPrice.slice";
import { selectClientConfig } from "../clientContext";
import classNames from "classnames";
import {selectPreferedPriceDisplayFormat} from "../ShowPrices/showPrices.selectors"

const { NAT_GAS, ELECTRIC } = unit;
const emptyArray = [];
const { setSearchedPrices } = getPricesAction;

const PriceSelection = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const clientConfig = useSelector(selectClientConfig);
  const supplierCards = useSelector(selectSearchedPrices);
  const steps = useSelector(selectSteps);
  const suppliersToBeCompared = supplierCards.filter(
    (supplier) => supplier.isSelectedForPriceComparision === true
  );
  const suppliersLogo = useSelector(selectSuppliersLogo);
  const filters = useSelector(selectFilters);
  const basicInfo = useSelector(selectPrice);
  const isEnerexStatePresent =
    basicInfo.state && ENEREX_STATES.includes(basicInfo.state.name);
  const { isFullScreen } = clientConfig;

  const [stepsForEnrollmentandPlanId, setStepsForEnrollmentandPlanId] =
    useState(emptyArray);
  const [updatedStepsForFalseEnrollment, setUpdatedStepsForFalseEnrollment] =
    useState(emptyArray);
    const preferedPriceDisplayFormat = useSelector(selectPreferedPriceDisplayFormat)

  useEffect(() => {
    setUpdatedStepsForFalseEnrollment(
      steps.filter((step) => step.label !== "Enrollment")
    );
    setStepsForEnrollmentandPlanId(
      steps.filter((step) => step.label !== "Additional Information")
    );
  }, [steps]);

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          dots: true,
          infinite: false,
          speed: 500,
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 786,
        settings: {
          dots: true,
          infinite: false,
          speed: 500,
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          dots: true,
          infinite: false,
          speed: 500,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className={classNames("inner-widget", {"px-20": !isFullScreen})}>
      <main className={classNames("py-5 card", {"min-h-100": isFullScreen})}>
        <div className="mb-4 d-flex align-items-center ml-4">
          <Button
            variant="outline-primary"
            onClick={() => {
              dispatch(setSearchedPrices())
              history.push("show-prices")
            }}
          >
            Back to Prices
          </Button>
          <Button
            variant="outline-light"
            className="text-dark f-32 ml-auto"
            onClick={() => handleCloseWidget(dispatch, history)}
          >
            <i className="fa fa-times fa-2x f-20"></i>
          </Button>
        </div>

        {suppliersToBeCompared.length > 0 && (
          <Slider className="px-4" {...settings}>
            {suppliersToBeCompared.map((supplierCard) => {
              const supplierLogo = suppliersLogo[supplierCard.SupplierId];
              return (
                <div className="carousel-slick-card" key={supplierCard.id}>
                  <Card className="suplier-item">
                    <Card.Header>
                      <h5>{supplierCard.SupplierName}</h5>
                    </Card.Header>
                    <Card.Body>
                      {supplierLogo && (
                        <div className="thumb mb-4">
                          <Image src={supplierLogo} fluid alt="supplier-logo" />
                        </div>
                      )}
                     {supplierCard.ProductType && supplierCard.ProductType!=="" && <div className="d-flex">
                        <h5>Product</h5>
                        <p className="ml-auto">{supplierCard.ProductType}</p>
                      </div>}
                     {supplierCard.PlanName && supplierCard.PlanName !=="" && <div className="d-flex">
                        <h5>Plan Name</h5>
                        <p className="ml-auto">{supplierCard.PlanName}</p>
                      </div>}
                     {supplierCard.Term && supplierCard.Term !=="" && <div className="d-flex">
                        <h5>Term</h5>
                        <p className="ml-auto">{supplierCard.Term} <span>Months</span></p>
                      </div>}
                      {filters.class === CLASS.RESIDENTIAL &&
                        supplierCard.PlanId && isEnerexStatePresent &&
                        <>
                          <div className="d-flex">
                            <h5>Price</h5>
                            <p className="ml-auto">
                            {getFormattedPrice(
                   preferedPriceDisplayFormat===0 ? supplierCard.Price :supplierCard[filters.commodity === COMMODITY.ELECTRIC
                    ? `Price${preferedPriceDisplayFormat}kwh`
                    : `Price${preferedPriceDisplayFormat}therm`],
                    supplierCard.PlanId
                  )?.toFixed(2)}
                            &cent;/
                            {filters.commodity === COMMODITY.ELECTRIC
                              ? ELECTRIC
                              : NAT_GAS}
                          </p>
                          </div>
                        </>}
                      {!(filters.class === CLASS.RESIDENTIAL &&
                        supplierCard.PlanId && isEnerexStatePresent) && <div className="d-flex">
                          <h5>Price</h5>
                          <p className="ml-auto">
                            {getFormattedPrice(
                              supplierCard.Price,
                              supplierCard.PlanId
                            ).toFixed(2)}
                            &cent;/
                            {filters.commodity === COMMODITY.ELECTRIC
                              ? ELECTRIC
                              : NAT_GAS}
                          </p>
                        </div>}
                      <PriceSelectionAccordion supplierCard={supplierCard} />
                    </Card.Body>
                    <Card.Footer className="text-center">
                      <Button
                        variant="primary"
                        onClick={() => {
                          dispatch(setShouldShowRFQDetails(false));
                          supplierCard.PlanId
                            ? dispatch(setSteps(stepsForEnrollmentandPlanId))
                            : dispatch(
                              setSteps(updatedStepsForFalseEnrollment)
                            );
                          dispatch(
                            setSelectedSupplier({
                              ...supplierCard,
                              supplierLogo,
                              supplierDetails: supplierCard.supplierDetails,
                            })
                          );
                          analytics.sendEventWithData("Select Price After comparison", supplierCard)
                          history.push("info");
                        }}
                      >
                        Select
                      </Button>
                    </Card.Footer>
                  </Card>
                </div>
              );
            })}
          </Slider>
        )}
      </main>
    </div>
  );
};

export default PriceSelection;
