import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  client: { brokerageId: "", apiKey: "", googleAnalyticsId: "", isFullScreen: false , parameters:"" },
  authKey:""
};

const slice = createSlice({
  name: "clientContext",
  initialState,
  reducers: {
    updateClientConfig(state, action) {
      state.client = action.payload;
    },
  }
});

export default slice;

export const { name, actions, reducer } = slice;
