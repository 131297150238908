import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import analytics from "../helper/analytics";
import { selectClientConfig } from "../features/clientContext";

const useGoogleAnalytics = () => {
  const location = useLocation();
  const clientConfig = useSelector(selectClientConfig);
  useEffect(() => {
    if (clientConfig.googleAnalyticsId)
      analytics.init(clientConfig.googleAnalyticsId);
  }, [clientConfig.googleAnalyticsId]);
  useEffect(() => {
    const currentPath = location.pathname + location.search;
    const pathTitle = RoutesPathWithTitle.find(each => each.path === currentPath)?.title
    analytics.sendPageView(currentPath, pathTitle);
  }, [location]);
};

export default useGoogleAnalytics;


const RoutesPathWithTitle = [{ path: "", title: "" },
{ path: "/", title: "Get Prices" },
{ path: "/enrollment", title: "Enrollment" },
{ path: "/supplier-info", title: "Additional Information" }, //TODO: Update
{ path: "/contract", title: "New Contract" },
{ path: "/review", title: "Review" },
{ path: "/info", title: "Your Info" },
{ path: "/accounts", title: "Accounts" },
{ path: "/price-selection", title: "Price Selection" },
{ path: "/show-prices", title: "Show Prices" },
{ path: "/select-pricing", title: "Select Pricing" }]
