import localStorage from "localStorage";
import { selectAccessToken, getAccessToken } from "../../features/accessTokens";
import { REACT_APP_API_URL } from "./http.constants";

import { selectClientConfig } from "../../features/clientContext";
// import { getState } from "../store";
// import * as actionTypes from "../userContext/userContext.actionTypes";
// import { getStore } from "../../createStore";

// eslint-disable-next-line
export default {
  get,
  post,
  put,
  patch,
  delete: callDelete
};

const ASYNC_DELAY = 2000;

function get(url, config, { stubSuccess, stubError } = {}, getState, dispatch) {
  return doFetch(url, config, { stubSuccess, stubError }, getState, dispatch);
}

function post(
  url,
  config,
  { stubSuccess, stubError } = {},
  getState,
  dispatch
) {
  config = {
    ...config,
    method: "POST"
  };

  return doFetch(url, config, { stubSuccess, stubError }, getState, dispatch);
}

function put(url, config, { stubSuccess, stubError } = {}, getState, dispatch) {
  config = {
    ...config,
    method: "PUT"
  };

  return doFetch(url, config, { stubSuccess, stubError }, getState, dispatch);
}

function patch(
  url,
  config,
  { stubSuccess, stubError } = {},
  getState,
  dispatch
) {
  config = {
    ...config,
    method: "PATCH"
  };

  return doFetch(url, config, { stubSuccess, stubError }, getState, dispatch);
}

function callDelete(
  url,
  config,
  { stubSuccess, stubError } = {},
  getState,
  dispatch
) {
  config = {
    ...config,
    method: "DELETE"
  };

  return doFetch(url, config, { stubSuccess, stubError }, getState, dispatch);
}

// If stubSuccess or stubError is defined then we will fake a successful call to the
// server and return stubSuccess as the response. This allows for easily
// faking calls during development when APIs aren't ready. A warning
// will be written out for each stubbed response to help prevent forgetting
// about the stubs.
function doFetch(url, config, { stubSuccess, stubError }, getState, dispatch) {
  if (!url) {
    throw new Error("You must specify a url");
  }

  if (process.env.NODE_ENV !== "test") {
    if (stubSuccess) {
      return new Promise(resolve =>
        setTimeout(() => {
          console.warn(`Stubbed service call made to url: ${url}`);
          resolve(stubSuccess);
        }, ASYNC_DELAY)
      );
    }

    if (stubError) {
      return new Promise((resolve, reject) =>
        setTimeout(() => {
          console.warn(`Stubbed service error was returned from url: ${url}`);
          reject(stubError);
        }, ASYNC_DELAY)
      );
    }
  }

  const getRefreshToken = async () => {
    await dispatch(getAccessToken());
  };


  const fetchUrl = (hasAttemptedWithRefreshedToken = false) => {
    return fetch(buildUrl(url, getState),addJwtToken(config, getState)).then(
      response => {
        if (response.headers) {
          const authHeader = response.headers.get("Authorization");

          setJwtTokenFromHeaderResponse(authHeader);
          // updateSessionToken(parseJwtTokenFromHeader(authHeader));
        }

        if (response.ok) {
          if (
            response.headers &&
            response.headers.map &&
            response.headers.map["content-type"].includes("stream")
          ) {
            return response;
          }
          if (response.url.includes("supplierLogo")) {
            // TODO: Make it generic
            return response.text();
          }
          return response.json();
        }

        const unauthorized = 401;
        if (response.status === unauthorized && !response.url.includes("auth")) {
          if (!hasAttemptedWithRefreshedToken) getRefreshToken();
          // All else failed so redirect user ot FMS to reauthenticate
          localStorage.removeItem("jwtToken");
          // response.json().then(() => redirectToSignOut());
        }

        return Promise.reject(response);
      }
    );
  };

  return fetchUrl();
}

function buildUrl(url, getState) {
  const clientConfig = selectClientConfig(getState());
  const authKey = clientConfig.authKey;
  url = url.includes("?")
    ? `${url}&subscription-key=${authKey}`
    : `${url}?subscription-key=${authKey}`;
    return REACT_APP_API_URL ? `${REACT_APP_API_URL}/${url}` : url;
}

function addJwtToken(config, getState) {
  const jwtToken = selectAccessToken(getState());

  if (!jwtToken || !config) {
    return config;
  }

  const authorization = `Bearer ${jwtToken}`;
  return {
    ...config,
    headers: {
      ...config.headers,
      Authorization: authorization
    }
  };
}

function setJwtTokenFromHeaderResponse(authorizationHeader) {
  const jwtToken = parseJwtTokenFromHeader(authorizationHeader);
  if (jwtToken) {
    localStorage.setItem("jwtToken", jwtToken);
  } else {
    localStorage.removeItem("jwtToken");
  }
}

function parseJwtTokenFromHeader(authorizationHeader) {
  if (!authorizationHeader) {
    return;
  }
  const tokens = authorizationHeader.match(/\S+/g);

  // We are getting the second token because the first token will be Bearer.
  // EX: Bearer woeirweoirjw....
  return tokens.length > 1 ? tokens[1] : null;
}

// const updateSessionToken = token => dispatch => {
// TODO: Do we need this?
// dispatch({
//   type: actionTypes.JWT_TOKEN_ASYNC.RECEIVED,
//   payload: token
// });
// };
