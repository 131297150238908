import React from "react"
import { Button, OverlayTrigger, Table, Tooltip } from "react-bootstrap";

const DocumentTable = ({ uploadedFiles, removeFromUploadedFiles }) => {
    return (<div className="table-responsive">
        <Table className="table table-bordered table-striped">
            <thead>
                <tr>
                    <th>Document - File Name</th>
                    <th>Size</th>
                    <th width="100">Action</th>
                </tr>
            </thead>

            <tbody>
                {uploadedFiles.length === 0 ? (
                    <tr>
                        <td colSpan="3" className="text-center">
                            No documents have been uploaded yet
                        </td>
                    </tr>
                ) : (
                        uploadedFiles.map(
                            ({ fileName, size, base64Data }, index) => {
                                return (
                                  <tr key={index}>
                                    <td>{fileName}</td>
                                    <td>{size}</td>
                                    <td className="text-center">
                                      <Button
                                        variant="link"
                                        className="text-dark"
                                      >
                                        <a
                                          href={base64Data}
                                          download={fileName}
                                        >
                                          <OverlayTrigger
                                            placement="top"
                                            overlay={
                                              <Tooltip id="button-tooltip-2">
                                                Download
                                              </Tooltip>
                                            }
                                          >
                                            <i className="fa fa-download"></i>
                                          </OverlayTrigger>
                                        </a>
                                      </Button>
                                      <Button
                                        variant="link"
                                        className="p-0 text-danger"
                                        onClick={() =>
                                          removeFromUploadedFiles(index)
                                        }
                                      >
                                        <OverlayTrigger
                                          placement="top"
                                          overlay={
                                            <Tooltip id="button-tooltip-2">
                                              Remove
                                            </Tooltip>
                                          }
                                        >
                                          <i className="fa fa-trash trash-alt"></i>
                                        </OverlayTrigger>
                                      </Button>
                                    </td>
                                  </tr>
                                );
                            }
                        )
                    )}
            </tbody>
        </Table>
    </div>)
}

export default DocumentTable