import GetPrice from "./GetPrice";

import * as selectors from "./getPrice.selectors";
import slice from "./getPrice.slice";

export * from "./getPrice.constants";
export * from "./getPrice.asyncActions";
export const {
  name,
  actions: { 
    savePrice, 
    setShouldShowRFQDetails, 
    resetPrices, 
    setTier, 
    setIsPriceExpired, 
    setIsStateUpdated, 
    setIsCustomPricing,
    setIsSupplierUpdated,
    setIsFetchingSettings
  },
  reducer
} = slice;

// we prefix all selectors with the the "select" prefix
export const {
  selectPrice,
  selectTier,
  selectShouldShowRFQDetails,
  selectUtilities,
  selectRateCodes,
  selectUserSettings,
  selectPortalSetting,
  selectApplicableMargin,
  selectSearchedPricesUtility,
  selectAllProducts,
  selectSearchedPrices,
  selectSuppliersLogo,
  selectIsPriceExpired,
  selectIsStateUpdated,
  selectPriceForm,
  selectIsCustomPricing,
  selectIsFetchingSettings,
  selectIsReferralCodeValid,
} = selectors;

// we export the component most likely to be desired by default
export default GetPrice;
