import React, { useState } from "react";
import { Row, Col, Button, Form, Collapse } from "react-bootstrap";
import NumberFormat from "react-number-format";
import {
  CLASS,
  COMMODITY,
  savePrice,
  selectPrice,
  getStateFromZip,
  setTier,
  TIERS,
  getUtilitiesFromZip,
  getRateCodes,
  COMMODITIES_LIST,

} from "../GetPrice";
import { useDispatch, useSelector } from "react-redux";
import { ALL_SUPPLIERS } from "./showPrice.constants";
import Overlay from "../../widgets/Tooltip";
import classNames from "classnames";
import { selectPortalSetting } from "../GetPrice";

const ShowMoreFilters = ({
  filterInfo,
  setFilterInfo,
  setInfo,
  setLocalZipCode,
  info,
  localZipCode,
  suppliers,
  brokerageId,
  filters,
  setIsSupplierUpdated,
  setShowUtilityError
}) => {
  const dispatch = useDispatch();
  const [moreFiltersOn, setMoreFiltersOn] = useState(false);
  const [isClassChanged, setIsClassChanged] = useState(true)
  const basicInfo = useSelector(selectPrice);
  const portalSettings = useSelector(selectPortalSetting);

  const handleZipCode = (zipCode) => {
    dispatch(savePrice({ ...basicInfo, zipCode: zipCode }));
  };

  const getSuppliers = (supplierName) => {
    const selectedSupplierId = suppliers.find(
      (supplierData) => supplierData.SupplierName === supplierName
    );
    if (selectedSupplierId) return [selectedSupplierId.SupplierId];
    return suppliers.map((supplierId) => supplierId.SupplierId);
  };

  return (
    <>
      <Collapse in={moreFiltersOn}>
        <div>
          <Row className="mx-n1">
            <Col xl={4} lg={5} md={8} className="px-1 mb-3 mb-lg-0 mt-2">
              <div className="d-flex align-items-center">
                <span className="f-14 mr-2 text-nowrap">Contract Length</span>
                <NumberFormat
                  type="text"
                  className="f-14 form-control"
                  onChange={(e) =>
                    setFilterInfo({
                      ...filterInfo,
                      from: e.target.value,
                    })
                  }
                  onBlur={() => {
                    const formattedValue = parseInt(filterInfo.to) >= parseInt(filterInfo.from) || !filterInfo.to ? filterInfo.from : ""
                    setFilterInfo({
                      ...filterInfo,
                      from: formattedValue,
                    })
                  }}
                  value={filterInfo.from}
                  allowNegative={false}
                />
                <span className="f-14 px-1">To</span>
                <NumberFormat
                  type="text"
                  className="f-14 form-control"
                  onChange={(e) =>
                    setFilterInfo({
                      ...filterInfo,
                      to: e.target.value,
                    })
                  }
                  onBlur={() => {
                    const formattedValue = parseInt(filterInfo.to) >= parseInt(filterInfo.from) || !filterInfo.from ? filterInfo.to : ""
                    setFilterInfo({
                      ...filterInfo,
                      to: formattedValue,
                    })
                  }}
                  value={filterInfo.to}
                  allowNegative={false}
                />
                <span className="f-14 px-1">Months</span>
              </div>
            </Col>
            <Col xl={3} lg={2} md={4} className="px-1 mb-3 mb-lg-0 mt-lg-2 mt-md-2">
              <Form.Control
                as="select"
                className="f-14"
                onChange={(e) => {
                  const value = e.target.value;
                  setFilterInfo({
                    ...filterInfo,
                    supplier: value,
                    Suppliers: getSuppliers(value),
                  });
                }}
              >
                {suppliers.length === 1 && <option value={suppliers[0].SupplierName}>{suppliers[0].SupplierName}</option>}
                {suppliers.length !== 1 && <option value={ALL_SUPPLIERS}>All Suppliers</option>}
                {isClassChanged && suppliers.length !== 1 && suppliers.map((supplier, index) => (
                  <option key={index} value={supplier.SupplierName}>
                    {supplier.SupplierName}
                  </option>
                ))}
              </Form.Control>
            </Col>
            <Col xl={2} lg={2} md={4} className="px-1 mb-3 mb-md-0 mb-lg-0 mt-lg-2">
              <NumberFormat
                type="text"
                allowLeadingZeros={true}
                placeholder="Zip Code"
                className="f-14 form-control"
                value={filterInfo.zipCode || localZipCode}
                onChange={(e) => {
                  setLocalZipCode(e.target.value);
                  setFilterInfo({
                    ...filterInfo,
                    zipCode: e.target.value,
                  });
                }}
                allowNegative={false}
                maxLength={100}
              />
              {localZipCode && localZipCode.length < 5 && (
                <div className="error-message mt-1">
                  Zip Code must contain at least 5 digits.
                </div>
              )}
            </Col>
            <Col xl={3} lg={3} md={8} className="px-1 mb-0 d-flex mt-lg-2">
              {portalSettings.showClassSelection && (
                <div className="radio-group d-inline-flex mb-1 w-50">
                  <Form.Label className="mb-0 radio-group-item w-50">
                    <Form.Control type="radio" name="class" className="d-none" />
                    <Overlay content={CLASS.RESIDENTIAL}>
                      <Button
                        variant={
                          info.class === CLASS.RESIDENTIAL
                            ? "primary"
                            : "outline-primary"
                        }
                        className="tab-btn btn-custom-left w-100"
                        onClick={() => {
                          setInfo({
                            ...info,
                            class: CLASS.RESIDENTIAL,
                          });
                          setFilterInfo({
                            ...filterInfo,
                            supplier: ALL_SUPPLIERS,
                            Suppliers: [],
                            class: CLASS.RESIDENTIAL,
                          });
                          setIsClassChanged(filters.class === CLASS.RESIDENTIAL)
                          dispatch(setTier(TIERS.SMALL));
                        }}
                      >
                        <i className="fa fa-home fa-lg"></i>
                      </Button>
                    </Overlay>
                  </Form.Label>

                  <Form.Label className="mb-0 radio-group-item w-50">
                    <Form.Control type="radio" name="class" className="d-none" />
                    <Overlay content={CLASS.COMMERCIAL}>
                      <Button
                        variant={
                          info.class === CLASS.COMMERCIAL
                            ? "primary"
                            : "outline-primary"
                        }
                        className="tab-btn btn-custom-right w-100"
                        onClick={(e) => {
                          setFilterInfo({
                            ...filterInfo,
                            supplier: ALL_SUPPLIERS,
                            Suppliers: [],
                            class: CLASS.COMMERCIAL,
                          });
                          setIsClassChanged(filters.class === CLASS.COMMERCIAL)
                          setInfo({
                            ...info,
                            class: CLASS.COMMERCIAL,
                          });
                        }}
                      >
                        <i className="fa fa-building fa-lg"></i>
                      </Button>
                    </Overlay>
                  </Form.Label>
                </div>
              )}

              {portalSettings.showCommoditySelection && (
                <div className="radio-group d-inline-flex mb-1 w-50 ml-2">
                  <Form.Label className="mb-0 radio-group-item w-50">
                    <Form.Control
                      type="radio"
                      name="commodity"
                      className="d-none"
                    />
                    <Overlay content="Electricity">
                      <Button
                        variant={
                          info.commodity === COMMODITY.ELECTRIC
                            ? "primary"
                            : "outline-primary"
                        }
                        className="tab-btn btn-custom-left w-100"
                        onClick={() => {
                          setInfo({
                            ...info,
                            commodity: COMMODITY.ELECTRIC,
                          });
                          setFilterInfo({
                            ...filterInfo,
                            commodity: COMMODITY.ELECTRIC,
                          });
                        }}
                      >
                        <i className="fa fa-bolt fa-lg"></i>
                      </Button>
                    </Overlay>
                  </Form.Label>

                  <Form.Label className="mb-0 radio-group-item w-50">
                    <Form.Control
                      type="radio"
                      name="commodity"
                      className="d-none"
                    />
                    <Overlay content="Gas">
                      <Button
                        variant={
                          info.commodity === COMMODITY.NAT_GAS
                            ? "primary"
                            : "outline-primary"
                        }
                        className="tab-btn btn-custom-right w-100"
                        onClick={() => {
                          setInfo({
                            ...info,
                            commodity: COMMODITY.NAT_GAS,
                          });
                          setFilterInfo({
                            ...filterInfo,
                            commodity: COMMODITY.NAT_GAS,
                          });
                        }}
                      >
                        <i className="fa fa-fire fa-lg"></i>
                      </Button>
                    </Overlay>
                  </Form.Label>
                </div>
              )}
            </Col>
          </Row>
          <Row className="mx-n1">
            <Col xl={10} lg={10} md={8} />
            <Col xl={2} lg={2} md={4} sm={12} className = 'btn-px'>
              <span>
                <Button
                  variant="primary"
                  className="f-14 btn-block my-2"
                  disabled={localZipCode && localZipCode.length < 5}
                  onClick={() => {
                    dispatch(getStateFromZip({ zipcode: localZipCode }))
                    handleZipCode(localZipCode, filters);
                    if (localZipCode.length >= 5 && filterInfo.class === CLASS.COMMERCIAL && (!isClassChanged || filters.zipCode !== localZipCode || filters.commodity !== filterInfo.commodity))
                      dispatch(
                        getUtilitiesFromZip({ zipcode: localZipCode, commodityId: filterInfo.commodity })
                      ).then(
                        (res) => {
                          if (res.payload) {
                            if (res.payload.length === 0) {
                              setShowUtilityError(true)
                              setFilterInfo({
                                ...filterInfo,
                                isFilterUpdated: true,
                                utility: res.payload[0],
                                zipCode: localZipCode,
                              })
                              return
                            }
                            setShowUtilityError(false)
                            dispatch(
                              getRateCodes({
                                filters: {
                                  LDCIdentifiers: [res.payload[0].LDCIdentifier],
                                  Commodity:
                                    COMMODITIES_LIST[filterInfo.commodity],
                                  StateId: basicInfo.state.id,
                                  StateName: basicInfo.state.name,
                                  ZipCode: localZipCode,
                                  BrokerId: brokerageId,
                                  RateClassification: filterInfo.class,
                                },
                              })
                            ).then(
                              (response) => {
                                if (response) {
                                  dispatch(
                                    savePrice({
                                      ...basicInfo,
                                      rateCode: '',
                                    }))
                                  setFilterInfo({
                                    ...filterInfo,
                                    isFilterUpdated: true,
                                    utility: res.payload[0],
                                    zipCode: localZipCode,
                                  })
                                }
                              }
                            );
                          }
                        });

                    else if (localZipCode.length >= 5 && filterInfo.class === CLASS.RESIDENTIAL && !isClassChanged && filters.zipcode !== localZipCode && filters.commodityId !== filterInfo.commodity) {
                      dispatch(getRateCodes(
                        {
                          filters: {
                            LDCIdentifiers: [],
                            Commodity:
                              COMMODITIES_LIST[filterInfo.commodity],
                            StateId: basicInfo.state.id,
                            StateName: basicInfo.state.name,
                            ZipCode: localZipCode,
                            BrokerId: brokerageId,
                            RateClassification: filterInfo.class,
                          }
                        }
                      )).then(
                        (res) => {
                          if (res) {
                            dispatch(
                              savePrice({
                                ...basicInfo,
                                rateCode: '',
                              }))
                            setFilterInfo({
                              ...filterInfo,
                              isFilterUpdated: true,
                              utility: {},
                              zipCode: localZipCode,
                            })
                          }
                        }
                      );
                    }
                    else
                      setFilterInfo({
                        ...filterInfo,
                        isFilterUpdated: true,
                      })
                    setIsClassChanged(true)
                    dispatch(setIsSupplierUpdated(filterInfo.supplier !== ALL_SUPPLIERS))
                  }}
                >
                  Search
                </Button>
              </span>
            </Col>
          </Row>
        </div>
      </Collapse>
      <Row className='mb-2'>
        <Col lg={10} md={9} />
        <Col lg={2} md={3} className="d-flex">
          <Button
            variant="link"
            className={`f-14 ml-auto accordion_button pb-0 pt-0`}
            onClick={() =>
              setMoreFiltersOn((prevValue) => !prevValue)
            }
          >
            {moreFiltersOn ? "Fewer" : "More"} Filters
            <i
              className={classNames("fa fa-chevron-up ml-2", {
                "fa-chevron-down": !moreFiltersOn,
              })}
            />
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default ShowMoreFilters;
