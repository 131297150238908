import { createSlice } from "@reduxjs/toolkit";
import {
  getEnrollmentFields,
  getUnitsForCommodity,
  saveAllInformation,
  searchForAccount,
  getSupplierFields,
  getUSStates,
  searchForAccountByAddress
} from "./priceAndCustomerDetails.asyncActions";
import { resetState } from "../resetState";

const initialState = {
  steps: [
    {
      label: "Your Info",
      path: "info",
    },
    {
      label: "Accounts",
      path: "accounts",
    },
    {
      label: "Additional Information",
      path: "supplier-info",
    },
    {
      label: "Enrollment",
      path: "enrollment",
    },
    {
      label: "Review",
      path: "review",
    },
  ],
  customerAccountsInfo: [],
  customerContractInfo: {},
  selectedSupplier: {},
  searchedAccount: {},
  customerInfo: {},
  saveStatus: null,
  unitsForCommodity: [],
  enrollmentFields: [],
  USStates: [],
  fieldsResult: {},
  supplierFields: { fieldsAsJson: [] },
  isAccountDeleted: false,
  isSearchedAccountExist: false,
  customerLegalEntityName: "",
  selectedDynamicFieldType: "",
  hasEmptyEnrollementAndSupplierFields: false,
  hasEmptyAdditionalInfoFields: false,
  taxExemptValue: "No",
  accountByAddress:[]
};

const slice = createSlice({
  name: "pricesAndCustomerDetails",
  initialState,
  reducers: {
    // synchronous actions
    addToCustomerAccountsInfo(state, action) {
      state.customerAccountsInfo = [
        ...state.customerAccountsInfo,
        action.payload,
      ];
    },
    addToCustomerAccountsInfoDetail(state, action) {
      state.customerAccountsInfo = action.payload;
    },
    addToCustomerContractInfo(state, action) {
      state.customerContractInfo = action.payload;
    },
    removeFromCustomerAccountsInfo(state, action) {
      state.isAccountDeleted = true;
      var customerAccountsInfoTemp = state.customerAccountsInfo;
      customerAccountsInfoTemp = customerAccountsInfoTemp.filter(
        (item) => item.accountNumber !== action.payload
      );
      state.customerAccountsInfo = customerAccountsInfoTemp;
    },
    removeFromCustomerContractInfo(state, action) {
      var customerContractInfoTemp = state.customerContractInfo;
      customerContractInfoTemp = customerContractInfoTemp.filter(
        (item) => item.fileName !== action.payload
      );
      state.customerContractInfo = customerContractInfoTemp;
    },
    setSelectedSupplier(state, action) {
      state.selectedSupplier = action.payload || initialState.selectedSupplier;
    },
    setSteps(state, action) {
      state.steps = action.payload || initialState.steps;
    },
    saveCustomerInfo(state, action) {
      state.customerInfo = action.payload;
    },
    resetSearchedAccount(state, action) {
      state.searchedAccount = {};
    },
    setFieldsResult(state, action) {
      state.fieldsResult = action.payload;
    },
    setIsSearchedAccountExist(state) {
      state.isSearchedAccountExist = false;
    },
    setCustomerLegalEntityName(state, action) {
      state.customerLegalEntityName = action.payload;
    },
    setSaveStatus(state, action) {
      state.saveStatus = action.payload || initialState.saveStatus
    },
    setEnrollmentFields(state, action) {
      state.enrollmentFields = action.payload || initialState.enrollmentFields
    },
    setSupplierFields(state, action) {
      state.supplierFields = action.payload || initialState.supplierFields
    },
    setSelectedDynamicFieldType
      (state, action) {
      state.selectedDynamicFieldType = action.payload
    },
    setHasEmptyEnrollementAndSupplierFields(state, action) {
      state.hasEmptyEnrollementAndSupplierFields = action.payload
    },
    setHasEmptyAdditionalInfoFields(state, action) {
      state.hasEmptyAdditionalInfoFields = action.payload
    },
    setTaxExemptValue(state, action) {
      state.taxExemptValue = action.payload
    } 
  },
  extraReducers: {
    [resetState.type]: (state) => initialState,
    [searchForAccount.fulfilled]: (state, action) => {
      state.isSearchedAccountExist = !action.payload;
      state.searchedAccount = action.payload ? action.payload.accounts[0] : {};
    },
    [saveAllInformation.fulfilled]: (state, action) => {
      state.saveStatus = action.payload || initialState.saveStatus;
    },
    [getUnitsForCommodity.fulfilled]: (state, action) => {
      state.unitsForCommodity =
        action.payload || initialState.unitsForCommodity;
    },
    [getEnrollmentFields.fulfilled]: (state, action) => {
      state.enrollmentFields = action.payload || initialState.enrollmentFields;
    },
    [getUSStates.fulfilled]: (state, action) => {
      state.USStates = action.payload || initialState.USStates;
    },

    [getSupplierFields.fulfilled]: (state, action) => {
      try
      {
        state.supplierFields = action.payload
          ? {
            fieldsAsJson: JSON.parse(action.payload.FieldsAsJson),
            id: action.payload.Id,
          } // getting stringify fields
          : initialState.supplierFields;
      }
      catch(err){
        console.warn(err.message)
        state.supplierFields = initialState.supplierFields
      }
    },
    [searchForAccountByAddress.fulfilled]: (state, action) => {
      state.accountByAddress = action.payload ? action.payload.eligibleCustomers || action.payload.accounts : initialState.accountByAddress;
    }
  },
});

export default slice;

export const { name, actions, reducer } = slice;
