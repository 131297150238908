import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

export const Overlay = ({ children, content, placement = "bottom" }) => (
  <OverlayTrigger
    placement={placement}
    delay={{ show: 250, hide: 400 }}
    overlay={<Tooltip key="tooltip">{content}</Tooltip>}
  >
    {children}
  </OverlayTrigger>
);
