export const REGIONS = {
  GLOBAL: "global",
  GET_CUSTOMER_ACCOUNT_INFO: "getCustomerAccountInfo",
  GET_CUSTOMER_INFO: "getCustomerInfo",
  SEARCH_ACCOUNT: "searchAccount",
  SAVE_ALL_INFORMATION: "saveAllInformation",
  GET_SUPPLIERS: "getSuppliers",
  GET_UTILITIES: "getUtilities",
  GET_RATE_CODES: "getRateCodes"
};
