import React from "react";
import "./page.css";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { selectPortalSetting } from "../../features/GetPrice/getPrice.selectors";
import { isEmpty } from "lodash";

export default function Page({ path,children }) {
  const portalSettings = useSelector(selectPortalSetting)
  if (isEmpty(portalSettings) && path !=="/")
    return <Redirect to="/" />;
  return <div>{children}</div>;
}