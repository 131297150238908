import { createAsyncThunk } from "@reduxjs/toolkit";
import doAsync from "../../infrastructure/doAsync";

export const getAccessToken = createAsyncThunk(
  "getToken/exchangeLive",
  async ({useCaching, noBusySpinner } = {}, thunkArgs) =>
    await doAsync({
      url: "auth",
      httpMethod: "post",
      useCaching,
      noBusySpinner,
      errorMessage: "Unable to get access token. Please try again later.",
      ...thunkArgs
    })
);
