import React, { useEffect, useState } from 'react'
import {
    Card,
    Row,
    Button,
} from 'react-bootstrap'
import handleCloseWidget from "../../helper/handleCloseWidget";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form, Field } from 'formik';
import { selectClientConfig } from "../clientContext";
import { selectAccessToken, getAccessToken } from "../accessTokens";
import { selectPrice, selectPortalSetting, CLASS, TIERS, COMMODITIES_LIST, UNITS } from '../GetPrice';
import { URLEndPoint } from "./contactme_constants"
import { mask, unMask } from "remask"


export default function ContactMe() {

    const clientConfig = useSelector(selectClientConfig);
    const portalSettings = useSelector(selectPortalSetting);
    const basicInfo = useSelector(selectPrice)
    const history = useHistory();
    const accessToken = useSelector(selectAccessToken);
    const dispatch = useDispatch();
    const authKey = clientConfig.authKey;
    const brokerageId = clientConfig.brokerageId

    useEffect(() => {
        if (authKey && brokerageId) dispatch(getAccessToken());
    }, [authKey, brokerageId, dispatch]);

    const [success, setSuccess] = useState(null)
    const usage = basicInfo.usage

    const selectBoundary = () => {
        const { mediumBoundaryMin, largeBoundaryMin, smallBoundaryMin, smallBoundaryMax, mediumBoundaryMax, largeBoundaryMax } = portalSettings;
        const { smallBoundaryMinElectric, smallBoundaryMaxElectric, mediumBoundaryMinElectric, mediumBoundaryMaxElectric, largeBoundaryMinElectric, largeBoundaryMaxElectric } = portalSettings;
        const { smallBoundaryMinGas, smallBoundaryMaxGas, mediumBoundaryMinGas, mediumBoundaryMaxGas, largeBoundaryMinGas, largeBoundaryMaxGas } = portalSettings;
        if (basicInfo.class === CLASS.RESIDENTIAL) { return TIERS.SMALL }
        else if ((usage >= smallBoundaryMin && usage <= smallBoundaryMax) ||
            (usage >= smallBoundaryMinElectric && usage <= smallBoundaryMaxElectric) ||
            (usage >= smallBoundaryMinGas && usage <= smallBoundaryMaxGas)) { return TIERS.SMALL }
        else if ((usage >= mediumBoundaryMin && usage <= mediumBoundaryMax) ||
            (usage >= mediumBoundaryMinElectric && usage <= mediumBoundaryMaxElectric) ||
            (usage >= mediumBoundaryMinGas && usage <= mediumBoundaryMaxGas)) { return TIERS.MID }
        else if ((usage >= largeBoundaryMin && usage <= largeBoundaryMax) ||
            (usage >= largeBoundaryMinElectric && usage <= largeBoundaryMaxElectric) ||
            (usage >= largeBoundaryMinGas && usage <= largeBoundaryMaxGas)) { return TIERS.LARGE }
    }

    const typeBoundary = selectBoundary();

    const enviarDatos = async (dataaux) => {
        try {
        await fetch(URLEndPoint + `${authKey}`, {
            method: 'POST',
            headers: {
                "Authorization": "Bearer " + accessToken,
                'Content-Type': 'application/json;charset=utf-8'
            },
            body: JSON.stringify(dataaux)

        }).then(res => res.json())
            .then(data => {
                setSuccess(data.isSuccess);
            })

        } 
        catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        removeSuccessMessage()
    }, [success])

    const removeSuccessMessage = () => {
        if (success === true) {
            setTimeout(() => {
                setSuccess(null)
                handleCloseWidget(dispatch, history)
            }, 5000)
        } else if (success !== true && success !== null) {
            setTimeout(() => {
                setSuccess(null)
                handleCloseWidget(dispatch, history)
            }, 5000)
        }
    }
    
    return (
        <>
            <div className="py-5 container">
                <div className="widget-card">
                    <Card className='card-responsive-contactMe'>
                        <Card.Header className='p-header'>
                            <div className='d-flex align-items-center justify-content-between'>
                                <h5 className='f-15 mb-0'>Contact Me</h5>
                                <Button
                                    variant='outline-light'
                                    onClick={() => handleCloseWidget(dispatch, history)}
                                >
                                    <i className='fa fa-times fa-2x f-20'></i>
                                </Button>
                            </div>
                        </Card.Header>
                        <Card.Body >
                            <Formik
                                initialValues={{
                                    name: '',
                                    lastName: '',
                                    companyName: '',
                                    email: '',
                                    phoneNumber: '',
                                    message: ''
                                }}

                                validate={(valores) => {
                                    let errores = {};

                                    if (!valores.name || valores.name.trim().length === 0) {
                                        errores.name = "Please enter the First Name"
                                    }
                                    if (!valores.lastName || valores.lastName.trim().length === 0) {
                                        errores.lastName = "Please enter the Last Name"
                                    }

                                    if (!valores.phoneNumber && !valores.email) {
                                        errores.phoneNumber = "If you don't have a phone number, please enter an email"

                                        errores.email = "If you do not have an email, please enter a phone number"
                                    }


                                    return errores;
                                }}


                                onSubmit={(valores, { resetForm }) => {
                                    resetForm();

                                    valores.phoneNumber = valores.phoneNumber.replace('(', "")
                                    valores.phoneNumber = valores.phoneNumber.replace(')', "")
                                    valores.phoneNumber = valores.phoneNumber.replace('-', "")
                                    valores.phoneNumber = valores.phoneNumber.trim()

                                    let data = {
                                        "BrokerId": brokerageId,
                                        "FirstName": valores.name,
                                        "LastName": valores.lastName,
                                        "CustomerLegalEntityName": valores.companyName,
                                        "CustomerDealLevelName": typeBoundary,
                                        "CustomerDealLevelId": "",
                                        "Email": valores.email,
                                        "PhoneNumber": valores.phoneNumber,
                                        "Message": valores.message,
                                        "Class": basicInfo.class,
                                        "CommodityName": COMMODITIES_LIST[basicInfo.commodity],
                                        "CmmodityId": basicInfo.commodity,
                                        "ZipCode": basicInfo.zipCode,
                                        "StateName": basicInfo.state.displayName,
                                        "StateId": basicInfo.state.id,
                                        "PromoCode": basicInfo.referralCode,
                                        "AnnualUsageOrMonthlyBill": basicInfo.usage,
                                        "Unit": COMMODITIES_LIST[basicInfo.commodity] === "Electric" ? UNITS.KWH : COMMODITIES_LIST[basicInfo.commodity] === "NatGas" && UNITS.TH

                                    }
                                    enviarDatos(data);
                                }}
                            >
                                {({ errors, touched, values, setFieldValue }) => (
                                    <Form >
                                        <div >
                                            <Row >
                                                <div className='col-5 mt-2'>
                                                    <label className='required' htmlFor="name" >First Name</label>
                                                </div>
                                                <div className='col-7 mt-2 text-center'>
                                                    <Field
                                                        onBlur={e => { setFieldValue("name", e.target.value.trim()) }}
                                                        className="form-control"
                                                        type='text'
                                                        id='name'
                                                        name='name'
                                                        pattern="([a-zA-Z',.-]+( [a-zA-Z',.-]+)*){2,30}"
                                                    />
                                                    {touched.name && errors.name && <div className='error-message'>{errors.name}</div>}
                                                </div>
                                            </Row>
                                            <Row className='mb-2'>
                                                <div className='col-5 mt-2'>
                                                    <label className='required ' htmlFor="lastname">Last Name</label>
                                                </div>
                                                <div className='col-7 mt-2 text-center'>
                                                    <Field
                                                        onBlur={e => { setFieldValue("lastName", e.target.value.trim()) }}
                                                        className="form-control"
                                                        type='text'
                                                        id='lastName'
                                                        name='lastName'
                                                        pattern="([a-zA-Z',.-]+( [a-zA-Z',.-]+)*){2,30}"
                                                    />
                                                    {touched.lastName && errors.lastName && <div className='error-message'>{errors.lastName}</div>}
                                                </div>
                                            </Row>
                                            <Row className='mb-2'>
                                                <div className='col-5 mt-2'>
                                                    <label htmlFor="companyName">Company Name</label>
                                                </div>
                                                <div className='col-7 mt-2 text-center'>
                                                    <Field
                                                        onBlur={e => { setFieldValue("companyName", e.target.value.trim()) }}
                                                        className="form-control"
                                                        type='text'
                                                        id='companyName'
                                                        name='companyName'
                                                    />
                                                </div>
                                            </Row>
                                            <Row className="mb-2">
                                                <div className='col-5 mt-2'>
                                                    <label htmlFor="e-mail">Email</label>
                                                </div>
                                                <div className='col-7 mt-2 text-center'>
                                                    <Field
                                                        onBlur={e => { setFieldValue("email", e.target.value.trim()) }}
                                                        className="form-control"
                                                        type='email'
                                                        id='e-mail'
                                                        name='email'
                                                        placeholder='jhon@example.com'
                                                        pattern="[a-zA-Z0-9_]+([.][a-zA-Z0-9_]+)*@[a-zA-Z0-9_]+([.][a-zA-Z0-9_]+)*[.][a-zA-Z]{2,5}"
                                                    />
                                                    {touched.phoneNumber && !values.phoneNumber && <div className='error-message'>{errors.email}</div>}
                                                </div>
                                            </Row>
                                            <Row className="mb-2">
                                                <div className='col-5 mt-2'>
                                                    <label htmlFor="phoneNumber">Phone Number</label>
                                                </div>
                                                <div className='col-7 mt-2 text-center'>
                                                    <Field
                                                        onBlur={e => { setFieldValue("phoneNumber", e.target.value.trim()) }}
                                                        className="form-control"
                                                        type='text'
                                                        id='phoneNumber'
                                                        name='phoneNumber'
                                                        pattern="[0-9()-]{13}"
                                                        placeholder="(111)111-xxxx"
                                                        value={mask(unMask(values.phoneNumber), "(999)999-9999")}
                                                    />
                                                    {touched.phoneNumber && !values.email && <div className='error-message'>{errors.phoneNumber}</div>}
                                                </div>
                                            </Row>
                                            <div>
                                                <label htmlFor="message">Message</label>
                                                <Field
                                                    onBlur={e => { setFieldValue("message", e.target.value.trim()) }}
                                                    className="form-control"
                                                    as='textarea'
                                                    id='message'
                                                    name='message'
                                                    maxlength='50'
                                                />
                                            </div>
                                            <Row className="d-flex d-block justify-content-center text-center mt-2" >
                                                {success === true && <div className='d-block alert alert-success' >Your information was submitted successfully!</div>}
                                                {(success !== true && success !== null) && <div className='d-block alert alert-danger' >An error occurred while submitting your information!</div>}
                                            </Row>
                                            <Row className="d-flex d-block justify-content-center text-center" >
                                                <Button
                                                    className="d-block btn btn-primary f-14"
                                                    type="submit"
                                                >
                                                    Submit
                                                </Button>
                                            </Row>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </Card.Body>
                    </Card>
                </div>
            </div>
        </>
    )
}
