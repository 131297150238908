import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { HashRouter } from "react-router-dom";
import Routes from "./Routes";
import "./App.css";
import { useSelector } from "react-redux";
import { updateClientConfig } from "./features/clientContext";
import { selectAccessToken, getAccessToken } from "./features/accessTokens";
import {appCues} from "./features/AppCues";


function App() {
  const clientConfigsWrapper = document.getElementById(
    "el-lead-capture-widget"
  );
  const brokerageId = clientConfigsWrapper.getAttribute("brokerageId");
  appCues(brokerageId);
  const apiKey = clientConfigsWrapper.getAttribute("apiKey"); // check if we need this
  const googleAnalyticsId = clientConfigsWrapper.getAttribute("gTagId");
  const parameters = clientConfigsWrapper.getAttribute("parameters");
  const authKey = clientConfigsWrapper.getAttribute("authKey");
  const isFullScreen = clientConfigsWrapper.getAttribute('isFullScreen') === 'true';
  const accessToken = useSelector(selectAccessToken);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      updateClientConfig({ brokerageId, apiKey, googleAnalyticsId, authKey, isFullScreen,parameters })
    );
  }, [dispatch, apiKey, brokerageId, googleAnalyticsId, authKey, isFullScreen,parameters]);

  useEffect(() => {
    if (authKey && brokerageId) dispatch(getAccessToken());
  }, [authKey, brokerageId, dispatch]);

  return (
    accessToken && (
      <HashRouter>
        <Routes />
      </HashRouter>
    )
  );
}

export default App;
