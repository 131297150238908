import { createSlice } from "@reduxjs/toolkit";
import { resetState } from "../resetState";

const initialState = {
  suppliers: [],
  products: [],
  filters: {},
  preferedPriceDisplayFormat:0
};

const slice = createSlice({
  name: "showPrices",
  initialState,
  reducers: {
    resetShowPrices(state) {
      state.suppliers = [];
      state.supplierCards = [];
    },
    setFilters(state, action) {
      state.filters = action.payload;
    },
    setPreferedPriceDisplayFormat(state,action){
      state.preferedPriceDisplayFormat = action.payload||state.preferedPriceDisplayFormat
    }
  },
  extraReducers: {
    [resetState.type]: (state) => initialState,
  },
});

export default slice;

export const { name, actions, reducer } = slice;
