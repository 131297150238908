import React,{useEffect} from "react";
import { Form, Row, Col, OverlayTrigger, Tooltip } from "react-bootstrap";
import { usStates } from "../../features/PriceAndCustomerDetails/priceAndCustomerDetails.constant";
import classNames from "classnames";

const StatesComponent = ({ field, onFieldChange, data, fieldsResult }) => {

  useEffect(() => {
    const value = fieldsResult[field.name]||field.defaultValue
    onFieldChange(field.name,value);
    // eslint-disable-next-line
  }, []); 

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {field.friendlyDescription}
        </Tooltip>
  );

  return (
    <Form.Group>
      <Row className="mx-n1 mb-3">
        <Col lg={6} className="px-1">
        <Form.Label className={classNames({required:field.required})}>
            {field.friendlyName}
            {field.friendlyDescription&&<OverlayTrigger
              placement="top"
              overlay={renderTooltip}
            >
              <i className="ml-2 fa fa-info-circle" aria-hidden="true"></i>
            </OverlayTrigger>}
          </Form.Label>
        </Col>
        <Col lg={6} className="px-1">
          <Form.Control
            as="select"
            onChange={e => onFieldChange(field.name, e.target.value)}
            required={field.required}
            value={data[field.name]}
          >
            <option value="">Select {field.name}</option>
            {usStates.map((state, index) => (
              <option value={state} key={index}>
                {state}
              </option>
            ))}
          </Form.Control>
        </Col>
      </Row>
    </Form.Group>
  );
};

export default StatesComponent;
