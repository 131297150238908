export const NOT_FOUND = 404;
export const BAD_REQUEST = 400;

// To have an prefix added to your API calls uncomment the line below
// and set the prefix according you your needs. The setting below
// will result in /api/your-endpoint-name being used to call the api
// not we should move this to a config file and doucment it in the readme
// export const API_URL_PREFIX = 'api'
export const REACT_APP_API_URL =
  process.env.REACT_APP_API_URL;
  const REACT_APP_TEST_DATA = process.env.REACT_APP_TEST_DATA;
  console.log(REACT_APP_TEST_DATA);