import { createAsyncThunk } from "@reduxjs/toolkit";
import doAsync from "../../infrastructure/doAsync";
import { REGIONS } from "../../widgets/busyIndicator";

// const mockUnitForCommodity = [{ unit: "KWh" }, { unit: "wh" }];

export const searchForAccount = createAsyncThunk(
  "getAccount/getAll",
  async (accountToBeSearched, thunkArgs, { useCaching, noBusySpinner } = {}) =>
    await doAsync({
      url: `/Leads/getaddress`,
      useCaching,
      noBusySpinner,
      busyIndicatorName: REGIONS.SEARCH_ACCOUNT,
      httpMethod: "post",
      httpConfig: {
        body: JSON.stringify(accountToBeSearched),
      },
      errorMessage: "Unable to load Account. Please try again later.",
      ...thunkArgs,
    })
);

export const saveAllInformation = createAsyncThunk(
  "saveInformation",
  async ({ useCaching, noBusySpinner, customerData } = {}, thunkArgs) =>
    await doAsync({
      url: `submitLead`,
      useCaching,
      noBusySpinner,
      busyIndicatorName: REGIONS.SAVE_ALL_INFORMATION,
      httpMethod: "post",
      httpConfig: {
        body: JSON.stringify(customerData),
      },
      errorMessage: "Error while Saving data. Please Try again later",
      ...thunkArgs,
    })
);

export const getUnitsForCommodity = createAsyncThunk(
  "getUnitsForCommodity",
  async ({ useCaching, noBusySpinner } = {}, thunkArgs) =>
    await doAsync({
      url: `list/uoms`,
      useCaching,
      noBusySpinner,
      errorMessage: "Failed to load units. Please try again later",
      ...thunkArgs,
    })
);

export const getEnrollmentFields = createAsyncThunk(
  "getEnrollmentFields",
  async (supplier, thunkArgs, { useCaching, noBusySpinner } = {}) =>
    await doAsync({
      url: `getEnrollmentFields`,
      useCaching,
      noBusySpinner,
      httpMethod: "post",
      httpConfig: {
        body: JSON.stringify(supplier),
      },
      errorMessage: "Failed to load Field. Please try again later",
      ...thunkArgs,
    })
);

export const getUSStates = createAsyncThunk(
  "getUSStates",
  async ({ useCaching, noBusySpinner } = {}, thunkArgs) =>
    await doAsync({
      url: `states`,
      useCaching,
      noBusySpinner,
      errorMessage: "Failed to load Field. Please try again later",
      ...thunkArgs,
    })
);

export const getSupplierFields = createAsyncThunk(
  "getSupplierFields",
  async (supplier, thunkArgs, { useCaching, noBusySpinner } = {}) =>
    await doAsync({
      url: `getContractTemplate`,
      useCaching,
      noBusySpinner,
      httpMethod: "post",
      httpConfig: {
        body: JSON.stringify(supplier),
      },
      errorMessage: "Failed to Supplier Fields. Please try again later",
      ...thunkArgs,
    })
);



export const searchForAccountByAddress = createAsyncThunk(
  "getAccount/getAll",
  async (accountToBeSearched, thunkArgs, { useCaching, noBusySpinner } = {}) =>
    await doAsync({
      url: `/Leads/getaddress`,
      useCaching,
      noBusySpinner,
      busyIndicatorName: REGIONS.SEARCH_ACCOUNT,
      httpMethod: "post",
      httpConfig: {
        body: JSON.stringify(accountToBeSearched),
      },
      errorMessage: "Unable to load Account. Please try again later.",
      ...thunkArgs,
    })
);