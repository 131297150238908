import React, { useEffect, useState } from "react";
import queryString from "query-string";
import { Formik } from "formik";
import classNames from "classnames";
import * as yup from "yup";
import { Row, Col, Form, Button, Card } from "react-bootstrap";
import NumberFormat from "react-number-format";
import { useSelector, useDispatch } from "react-redux";
import {
  selectPrice,
  CLASS,
  selectShouldShowRFQDetails,
  selectTier,
  TIERS,
  selectUserSettings,
  selectRateCodes,
  selectPortalSetting,
  selectUtilities,
} from "../GetPrice";
import {
  selectSteps,
  selectSelectedSupplier,
  selectUSStates,
  selectEnrollmentFields,
  selectSupplierFields,
  selectCustomerInfo,
  selectSelectedDynamicFieldType,
  selectTaxExemptValue
} from "./priceAndCustomerDetails.selectors";
import Stepper from "./Stepper";
import SupplierCard from "./SupplierCard";
import { useHistory } from "react-router-dom";
import { actions } from "./priceAndCustomerDetails.slice";
import { actions as getPriceActions } from "../GetPrice/getPrice.slice";
import BusyIndicator, { REGIONS } from "../../widgets/busyIndicator";
import {
  getEnrollmentFields,
  getSupplierFields,
  getUSStates,
} from "./priceAndCustomerDetails.asyncActions";
import isEmpty from "lodash/isEmpty";
import handleCloseWidget from "../../helper/handleCloseWidget";
import LargeTierCard from "./LargeTierCard";
import { selectClientConfig } from "../clientContext";
import UploadDocuments from "./UploadDocuments"
import DocumentTable from "./DocumentTable"
import analytics from "../../helper/analytics"

const {
  saveCustomerInfo,
  setSteps,
  setCustomerLegalEntityName,
  setEnrollmentFields,
  setSupplierFields,
  setSelectedDynamicFieldType,
  setHasEmptyEnrollementAndSupplierFields,
  setHasEmptyAdditionalInfoFields,
  setTaxExemptValue
} = actions;

const { savePrice } = getPriceActions
const [
  emptyString,
  BILLING,
  PHYSICAL,
  ADDRESS,
  STATE_NAME,
  CITY,
  ZIP_CODE,
  ENROLLMENT,
  NEW_CONTRACT,
  ADDITIONAL_INFORMATION,
] = [
    "",
    "Billing",
    "Physical",
    "addressLine1",
    "stateName",
    "city",
    "zip",
    "Enrollment",
    "New Contract",
    "Additional Information",
  ];

const PriceDetails = () => {
  const dispatch = useDispatch();
  const steps = useSelector(selectSteps);
  const priceDetails = useSelector(selectPrice);
  const utilities = useSelector(selectUtilities)
  const USStates = useSelector(selectUSStates);
  const enrollmentFields = useSelector(selectEnrollmentFields);
  const supplierFields = useSelector(selectSupplierFields);
  const customerInfo = useSelector(selectCustomerInfo);
  const tier = useSelector(selectTier);
  const shouldShowRFQDetails = useSelector(selectShouldShowRFQDetails);
  const history = useHistory();
  const userSettings = useSelector(selectUserSettings);
  const selectedSupplier = useSelector(selectSelectedSupplier);
  const clientConfig = useSelector(selectClientConfig);
  const isCommercial = priceDetails.class === CLASS.COMMERCIAL
  const isCustomerLegalEntityNameAvailable = isCommercial || shouldShowRFQDetails;
  const rateCodes = useSelector(selectRateCodes);
  const [hasSamePhysicalAndBillingAddresses, setHasSamePhysicalAndBillingAddresses] = useState(false)
  const selectedDynamicFieldType = useSelector(selectSelectedDynamicFieldType)
  const taxExemptValue = useSelector(selectTaxExemptValue)
  const [hasErrorOnUpload, setHasErrorOnUpload] = useState(false)

  const { oid: opportunityId } = queryString.parse(window.location.search);
  const { brokerageId, isFullScreen } = clientConfig;
  const { pipelineContacts, pipelineAddresses, customerName, supplierIdentifier } = userSettings;
  const { state, commodity, utility, zipCode } = priceDetails;
  const portalSettings = useSelector(selectPortalSetting);
  const checkFieldsAreValid = (conditionalFields) => {
    const isConditionalVisibilityOnField = conditionalFields.every((each) => each.conditionalVisibilityOnField)
    if (isConditionalVisibilityOnField) {
      return conditionalFields.some((each) =>
        each?.conditionalVisibilityOnField?.some(
          (each) =>
            each.name === 'Class' && each.valueWhenShown === priceDetails.class
        )
      );
    } else return true;
  };

  const shouldSkipAdditionalInfo =
    supplierFields?.fieldsAsJson?.length === 0 ||
    !checkFieldsAreValid(supplierFields?.fieldsAsJson);

  const shouldSkipEnrollment =
    enrollmentFields.length === 0 || !checkFieldsAreValid(enrollmentFields);

  useEffect(() => {
    if (!isEmpty(userSettings) && !utility) {
      const selectedUtility = utilities.find((each) => each.LDCIdentifier === selectedSupplier.LDCId)
      dispatch(savePrice({ ...priceDetails, utility: selectedUtility }))
    }
  }, [dispatch, priceDetails, utility, utilities, selectedSupplier, userSettings])

  useEffect(() => {
    isEmpty(USStates) && dispatch(getUSStates());
    const isAlreadyRFQ = steps.some((each) => each.label === NEW_CONTRACT);
    const isAlreadyHaveEnrollment = steps.some(
      (each) => each.label === ENROLLMENT
    );
    if (shouldShowRFQDetails && !isAlreadyRFQ) {
      const updatedSteps = steps.map((each) => {
        if (each.label === ENROLLMENT)
          return { label: NEW_CONTRACT, path: "contract" };
        else if (each.label === ADDITIONAL_INFORMATION) return { label: undefined };
        return each;
      });
      const newUpdatedSteps = updatedSteps.filter(
        (each) => each.label !== undefined
      );
      dispatch(setSteps(newUpdatedSteps));
    } else {
      if (selectedSupplier.PlanId && !isAlreadyHaveEnrollment) {
        const updatedSteps = steps.map((each) => {
          if (each.label === ADDITIONAL_INFORMATION)
            return { label: ENROLLMENT, path: "enrollment" };
          return each;
        });
        dispatch(setSteps(updatedSteps));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, shouldShowRFQDetails, selectedSupplier, USStates]);

  useEffect(() => {
    if (selectedDynamicFieldType) {
      const updatedSteps = steps.filter((each) => {
        if (
          selectedSupplier.PlanId &&
          each.label === ENROLLMENT &&
          shouldSkipEnrollment
        ) {
          dispatch(setHasEmptyEnrollementAndSupplierFields(true));
          return false;
        }
        if (
          !selectedSupplier.PlanId &&
          each.label === ADDITIONAL_INFORMATION &&
          shouldSkipAdditionalInfo
        ) {
          dispatch(setHasEmptyAdditionalInfoFields(true));
          return false;
        }
        return true;
      });
      dispatch(setSteps(updatedSteps));
      dispatch(setSelectedDynamicFieldType(emptyString));
    }
  }, [
    steps,
    selectedDynamicFieldType,
    selectedSupplier.PlanId,
    shouldSkipAdditionalInfo,
    shouldSkipEnrollment,
    supplierFields,
    priceDetails,
    dispatch,
  ]);

  useEffect(() => {
    if (!shouldShowRFQDetails && selectedSupplier) {
      if (selectedSupplier.PlanId && shouldSkipEnrollment) {

        dispatch(
          getEnrollmentFields({
            SupplierId: selectedSupplier.SupplierId,
            StateId: state.id,
            CommodityTypeId: commodity,
            BrokerId: brokerageId,
          })
        ).then(() => {
          dispatch(setSelectedDynamicFieldType(ENROLLMENT))
        });
      } else if (!selectedSupplier.PlanId) {
        shouldSkipAdditionalInfo &&
          dispatch(
            getSupplierFields({
              SupplierId: opportunityId
                ? supplierIdentifier
                : selectedSupplier.SupplierId,
              StateId: state?.id,
              CommodityTypeId: commodity,
              rateClass: selectedSupplier.RateClass,
              utilityId: selectedSupplier.LDCId
            })
          ).then(() => dispatch(setSelectedDynamicFieldType("AdditionalInfo")));
      }
    }
  }, [
    opportunityId,
    supplierIdentifier,
    selectedSupplier,
    state,
    dispatch,
    brokerageId,
    shouldShowRFQDetails,
    commodity,
    shouldSkipEnrollment,
    supplierFields?.fieldsAsJson?.length,
    shouldSkipAdditionalInfo
  ]);

  const phoneRegExp = /^(\+0?1\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/;
  const isCommercialMatrix = isCommercial && !shouldShowRFQDetails
  const validation = yup.object().shape(
    {
      city: yup.string().test(
        "city",
        "City is required",
        (value) => shouldShowRFQDetails || hasSamePhysicalAndBillingAddresses || !!value).matches(/^[a-zA-Z ]*$/, "Please enter only alphabetical letters."),
      customerLegalEntityName: yup
        .string()
        .when("isCustomerLegalEntityNameAvailable", {
          is: true,
          then: yup.string().required("Customer Legal Entity Name is required"),
        }),
      email: yup
        .string()
        .email("Email must be a valid Email")
        .required("Email is required"),
      firstName: yup.string().required("First Name is required").matches(/^[a-zA-Z ]*$/, "Please enter only alphabetical letters."),
      lastName: yup.string().required("Last Name is required").matches(/^[a-zA-Z ]*$/, "Please enter only alphabetical letters."),
      personalPhone: yup
        .string()
        .matches(phoneRegExp, "Invalid Phone Number")
        .when("workPhone", {
          is: (workPhone) => !workPhone || workPhone.length === 0,
          then: yup.string().required("Personal or Work Phone is required"),
          otherwise: yup.string(),
        }),
      address: yup.string().test(
        "address",
        "Address is required",
        (value) => shouldShowRFQDetails || hasSamePhysicalAndBillingAddresses || !!value),
      state: yup.string().test(
        "state",
        "State is required",
        (value) => shouldShowRFQDetails || hasSamePhysicalAndBillingAddresses || !!value),
      title: yup.string().matches(/^[a-zA-Z ]*$/, "Please enter only alphabetical letters."),
      workPhone: yup
        .string()
        .matches(phoneRegExp, "Invalid Phone Number")
        .when("personalPhone", {
          is: (personalPhone) => !personalPhone || personalPhone.length === 0,
          then: yup.string().required(),
          otherwise: yup.string(),
        }),
      zipCode: yup
        .string()
        .test(
          "zipCode",
          "Zip Code is required",
          (value) => shouldShowRFQDetails || hasSamePhysicalAndBillingAddresses || !!value)
        .test('len', 'Zip Code must contain at least 5 digits', val => (val && val.length >= 5) || shouldShowRFQDetails || hasSamePhysicalAndBillingAddresses),
      files: yup.array().test(
        "files",
        "files is required",
        (value) => !isCommercialMatrix || (value && value.length > 0)),
      physicalAddress: yup.string().test(
        "physicalAddress",
        "Address is required",
        (value) => !isCommercial || !!value),
      physicalState: yup.string().test(
        "physicalState",
        "State is required",
        (value) => !isCommercial || !!value),
      physicalZipCode: yup
        .string()
        .min(5, "Zip Code must contain at least 5 digits")
        .test(
          "physicalZipCode",
          "Zip Code is required",
          (value) => !isCommercial || !!value),
      physicalCity: yup.string().test(
        "physicalCity",
        "City is required",
        (value) => !isCommercial || !!value).matches(/^[a-zA-Z ]*$/, "Please enter only alphabetical letters."),
      taxExemptValue: yup.string().required("Tax Exempt is required")
    },
    [["personalPhone", "workPhone"]]
  );

  const initialStateName = (intialStateId) => {
    const initialState = USStates.find(
      (states) => states.id === intialStateId
    );
    return initialState?.displayName;
  };

  const getAddress = (addressTypeName, fieldKey) => {
    const address = pipelineAddresses?.find((each) => each.addressTypeName === addressTypeName)
    return address && address[fieldKey]
  }

  const initialValues = {
    city: customerInfo?.billingAddress?.city || state?.city || getAddress(BILLING, CITY) || emptyString,
    customerLegalEntityName:
      customerInfo?.customerLegalEntityName || customerName || emptyString,
    email: customerInfo?.email || (pipelineContacts && pipelineContacts.length > 0 && pipelineContacts[0].emailWork) || emptyString,
    firstName:
      customerInfo?.firstName ||
      (pipelineContacts && pipelineContacts.length > 0 && pipelineContacts[0].firstName) ||
      emptyString,
    lastName:
      customerInfo?.lastName ||
      (pipelineContacts && pipelineContacts.length > 0 && pipelineContacts[0].lastName) ||
      emptyString,
    personalPhone: customerInfo?.personalPhone || (pipelineContacts && pipelineContacts.length > 0 && pipelineContacts[0].phonePersonal) || emptyString,
    address: customerInfo?.billingAddress?.address || getAddress(BILLING, ADDRESS) || emptyString,
    state:
      initialStateName(customerInfo?.billingAddress?.stateId) ||
      priceDetails?.state?.displayName ||
      getAddress(BILLING, STATE_NAME) ||
      emptyString,
    title: customerInfo?.title || (pipelineContacts && pipelineContacts.length > 0 && pipelineContacts[0].designation) || emptyString,
    workPhone: customerInfo?.workPhone || (pipelineContacts && pipelineContacts.length > 0 && pipelineContacts[0].phoneWork) || emptyString,
    zipCode:
      customerInfo?.billingAddress?.zipCode ||
      priceDetails.zipCode ||
      getAddress(BILLING, ZIP_CODE) ||
      emptyString,
    isCustomerLegalEntityNameAvailable: isCustomerLegalEntityNameAvailable,
    files: customerInfo?.files || [],
    physicalAddress: customerInfo?.address?.address || getAddress(PHYSICAL, ADDRESS) || emptyString,
    physicalCity: customerInfo?.address?.city || state?.city || getAddress(PHYSICAL, CITY) || emptyString,
    physicalState:
      initialStateName(customerInfo?.address?.stateId) ||
      state?.displayName ||
      getAddress(PHYSICAL, STATE_NAME) ||
      emptyString,
    physicalZipCode:
      customerInfo?.address?.zipCode ||
      zipCode ||
      getAddress(PHYSICAL, ZIP_CODE) ||
      emptyString,
    taxExemptValue: taxExemptValue || emptyString
  };

  const onDropHandle = (uploadedFiles, acceptedFiles, setFieldValue) => {
    setHasErrorOnUpload(false)
    const uploads = [];
    acceptedFiles &&
      acceptedFiles.forEach((file) => {
        const reader = new FileReader();
        reader.onload = (r) => {
          uploads.push({
            base64Data: r.target.result,
            fileName: file.name,
            fileType: file.type,
            size: file.size,
          });
          if (uploads.length === acceptedFiles.length) {
            const files = [...uploadedFiles, ...uploads]
            setFieldValue("files", files)
          }
        };
        reader.readAsDataURL(file);
      });
  };

  const removeFromUploadedFiles = (uploadedFiles, index, setFieldValue) => {
    const files = [...uploadedFiles]
    files.splice(index, 1);
    setFieldValue("files", files)
  };

  const onFieldChange = (setFieldValue, values) => {
    const updatedValue = !hasSamePhysicalAndBillingAddresses
    setHasSamePhysicalAndBillingAddresses(updatedValue)
    if (updatedValue) {
      const { physicalAddress, physicalCity, physicalState, physicalZipCode } = values
      setFieldValue("address", physicalAddress)
      setFieldValue("city", physicalCity)
      setFieldValue("state", physicalState)
      setFieldValue("zipCode", physicalZipCode)
    }
    else {
      setFieldValue("address", emptyString)
      setFieldValue("city", emptyString)
      setFieldValue("state", emptyString)
      setFieldValue("zipCode", emptyString)
    }
  }

  return (
    <div className={classNames("inner-widget", { "px-20": !isFullScreen })}>
      <Card className={classNames("p-4", { "min-h-100": isFullScreen })}>
        <div className="mb-4 py-2">
          <div className="d-flex align-items-center justify-content-between">
            {tier !== TIERS.LARGE && rateCodes.length > 0 ? (
              <Button
                variant="outline-primary"
                onClick={() => {
                  dispatch(setEnrollmentFields());
                  dispatch(setSupplierFields());
                  history.push(
                    shouldShowRFQDetails ? "select-pricing" : "show-prices"
                  );
                }}
              >
                {shouldShowRFQDetails ? "Back" : "Back To Prices"}
              </Button>
            ) : (
              <div />
            )}
            {shouldShowRFQDetails && <b>Custom Pricing Details</b>}
            <div className='d-flex align-items-center'>
              {portalSettings.phoneWork && <h6 className="sub-heading-text f-14 text-muted ">Need Assistance?: Call {portalSettings.phoneWork}</h6>}
              <Button
                variant="outline-light"
                onClick={() => handleCloseWidget(dispatch, history)}
              >
                <i className="fa fa-times fa-2x f-20"></i>
              </Button>
            </div>
          </div>
        </div>
        <Row className="justify-content-center mb-3">
          <SupplierCard />
          {tier === TIERS.LARGE && <LargeTierCard />}
          <Col lg={8}>
            <BusyIndicator region={REGIONS.GET_CUSTOMER_INFO}>
              <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={validation}
                onSubmit={(values) => {
                  const {
                    firstName,
                    lastName,
                    title,
                    email,
                    personalPhone,
                    workPhone,
                    customerLegalEntityName,
                    address,
                    city,
                    state,
                    zipCode,
                    files,
                    physicalAddress,
                    physicalCity,
                    physicalState,
                    physicalZipCode,
                    taxExemptValue
                  } = values;
                  const getState = (state) =>
                    USStates.find(
                      (each) =>
                        each.name.replaceAll("_", " ") === state.toLowerCase()
                    ) || {};
                  dispatch(setTaxExemptValue(taxExemptValue))
                  const customerInfoData = {
                    firstName,
                    lastName,
                    title,
                    email,
                    personalPhone,
                    workPhone,
                    billingAddress: {
                      address,
                      city,
                      stateId: getState(state).id,
                      zipCode,
                    },
                    customerLegalEntityName,
                    files
                  };
                  if (isCommercial)
                    customerInfoData.address = {
                      address: physicalAddress,
                      city: physicalCity,
                      stateId: getState(physicalState).id,
                      zipCode: physicalZipCode
                    }
                  dispatch(setCustomerLegalEntityName(customerLegalEntityName));
                  dispatch(saveCustomerInfo(customerInfoData));
                  history.push("accounts");
                  const dataForAnalytics = values
                  delete dataForAnalytics.files
                  analytics.sendEventWithData("Your Information", dataForAnalytics)
                }}
              >
                {({ values, errors, touched, setFieldValue, handleBlur, handleSubmit }) => (
                  <>
                    <div className="wizard">
                      <Stepper pages={steps} currentPath="info" />
                      <div className="wizard-content" data-step="step1">
                        {isCustomerLegalEntityNameAvailable && (
                          <Form.Group as={Row} className="align-items-center mb-3">
                            <Col md={4}>
                              <Form.Label className="required">
                                Customer Legal Entity Name
                              </Form.Label>
                            </Col>
                            <Col md={8}>
                              <Form.Control
                                type="text"
                                className={classNames("f-14", {
                                  error:
                                    errors.customerLegalEntityName &&
                                    touched.customerLegalEntityName,
                                })}
                                placeholder="Customer Legal Entity Name"
                                value={values.customerLegalEntityName}
                                onChange={(e) =>
                                  setFieldValue(
                                    "customerLegalEntityName",
                                    e.target.value
                                  )
                                }
                                onBlur={(e) =>
                                  setFieldValue(
                                    "customerLegalEntityName",
                                    e.target.value.trim()
                                  )
                                }
                                maxLength={100}
                              />
                              {errors.customerLegalEntityName &&
                                touched.customerLegalEntityName && (
                                  <div className="error-message mt-1">
                                    {errors.customerLegalEntityName}
                                  </div>
                                )}
                            </Col>
                          </Form.Group>
                        )}
                        <Row className='mb-3'>
                          <Col md={6}>
                            <Form.Group as={Row} className="align-items-center">
                              <Col lg={4}>
                                <Form.Label className="required">First Name</Form.Label>
                              </Col>
                              <Col lg={8}>
                                <Form.Control
                                  type="text"
                                  className={classNames("f-14", {
                                    error:
                                      errors.firstName && touched.firstName,
                                  })}
                                  placeholder="John"
                                  value={values.firstName}
                                  onChange={(e) =>
                                    setFieldValue("firstName", e.target.value)
                                  }
                                  onBlur={(e) =>
                                    setFieldValue("firstName", e.target.value.trim())
                                  }
                                  maxLength={100}
                                />
                                {errors.firstName && touched.firstName && (
                                  <div className="error-message mt-1">
                                    {errors.firstName}
                                  </div>
                                )}
                              </Col>
                            </Form.Group>
                          </Col>
                          <Col md={6}>
                            <Form.Group as={Row} className="align-items-center">
                              <Col lg={4}>
                                <Form.Label className="required">Last Name</Form.Label>
                              </Col>
                              <Col lg={8}>
                                <Form.Control
                                  type="text"
                                  className={classNames("f-14", {
                                    error: errors.lastName && touched.lastName,
                                  })}
                                  placeholder="Smith"
                                  value={values.lastName}
                                  onChange={(e) =>
                                    setFieldValue("lastName", e.target.value)
                                  }
                                  onBlur={(e) =>
                                    setFieldValue("lastName", e.target.value.trim())
                                  }
                                  maxLength={100}
                                />
                                {errors.lastName && touched.lastName && (
                                  <div className="error-message mt-1">
                                    {errors.lastName}
                                  </div>
                                )}
                              </Col>
                            </Form.Group>
                          </Col>
                        </Row>
                        {isCommercial && <Row className='mb-3'>
                          <Col md={6}>
                            <Form.Group as={Row} className="align-items-center">
                              <Col lg={4}>
                                <Form.Label>Title</Form.Label>
                              </Col>
                              <Col lg={8}>
                                <Form.Control
                                  type="text"
                                  className={classNames("f-14", {
                                    error: errors.title && touched.title,
                                  })}
                                  placeholder="Title"
                                  value={values.title}
                                  onChange={(e) =>
                                    setFieldValue("title", e.target.value)
                                  }
                                  onBlur={(e) =>
                                    setFieldValue("title", e.target.value.trim())
                                  }
                                  maxLength={100}
                                />
                                {errors.title && touched.title && (
                                  <div className="error-message mt-1">
                                    {errors.title}
                                  </div>
                                )}
                              </Col>
                            </Form.Group>
                          </Col>
                          <Col md={6} className="col-md-6">
                            <Form.Group as={Row} className="align-items-center">
                              <Col lg={4}>
                                <Form.Label>Work Phone</Form.Label>
                              </Col>
                              <Col lg={8}>
                                <NumberFormat
                                  format="(###) ###-####"
                                  mask="_"
                                  type="text"
                                  className={classNames("f-14 form-control", {
                                    error:
                                      errors.workPhone && touched.workPhone,
                                  })}
                                  placeholder="(111) 111-XXXX"
                                  value={values.workPhone}
                                  onChange={(e) =>
                                    setFieldValue("workPhone", e.target.value)
                                  }
                                />
                              </Col>
                            </Form.Group>
                          </Col>
                        </Row>
                        }
                        <Row className='mb-3'>
                          <Col md={6}>
                            <Form.Group as={Row} className="align-items-center">
                              <Col lg={4}>
                                <Form.Label className="required">Personal Phone</Form.Label>
                              </Col>
                              <Col lg={8}>
                                <NumberFormat
                                  format="(###) ###-####"
                                  mask="_"
                                  type="text"
                                  className={classNames("f-14 form-control", {
                                    error:
                                      errors.personalPhone &&
                                      touched.personalPhone,
                                  })}
                                  placeholder="(111) 111-XXXX"
                                  value={values.personalPhone}
                                  onChange={(e) => {
                                    setFieldValue(
                                      "personalPhone",
                                      e.target.value
                                    );
                                  }}
                                />
                                {errors.personalPhone &&
                                  touched.personalPhone && (
                                    <div className="error-message mt-1">
                                      {errors.personalPhone}
                                    </div>
                                  )}
                              </Col>
                            </Form.Group>
                          </Col>
                          <Col md={6}>
                            <Form.Group as={Row} className="align-items-center">
                              <Col lg={4}>
                                <Form.Label className="required">Email</Form.Label>
                              </Col>
                              <Col lg={8}>
                                <Form.Control
                                  type="text"
                                  className={classNames("f-14", {
                                    error: errors.email && touched.email,
                                  })}
                                  placeholder="John@smith.com"
                                  value={values.email}
                                  onChange={(e) =>
                                    setFieldValue("email", e.target.value)
                                  }
                                  onBlur={(e) =>
                                    setFieldValue("email", e.target.value.trim())
                                  }
                                  maxLength={100}
                                />
                                {errors.email && touched.email && (
                                  <div className="error-message mt-1">
                                    {errors.email}
                                  </div>
                                )}
                              </Col>
                            </Form.Group>
                          </Col>
                        </Row>
                        {isCommercial && <Form.Group>
                          <Form.Label className="required">Address</Form.Label>
                          <Row className="mx-n1 mb-3">
                            <Col lg={6} className="px-1 mt-3">
                              <Form.Group>
                                <Form.Control
                                  type="text"
                                  className={classNames("f-14", {
                                    error: errors.physicalAddress && touched.physicalAddress,
                                  })}
                                  placeholder="Address"
                                  value={values.physicalAddress}
                                  onChange={(e) => {
                                    setFieldValue("physicalAddress", e.target.value)
                                    if (hasSamePhysicalAndBillingAddresses)
                                      setFieldValue("address", e.target.value)
                                  }
                                  }
                                  onBlur={(e) => {
                                    setFieldValue("physicalAddress", e.target.value.trim())
                                    if (hasSamePhysicalAndBillingAddresses)
                                      setFieldValue("address", e.target.value.trim())
                                  }
                                  }
                                />
                                {errors.physicalAddress && touched.physicalAddress && (
                                  <div className="error-message mt-1">
                                    {errors.physicalAddress}
                                  </div>
                                )}
                              </Form.Group>
                            </Col>
                            <Col lg={2} md={4} className="px-1 mt-3">
                              <Form.Group>
                                <Form.Control
                                  type="text"
                                  className={classNames("f-14", {
                                    error: errors.physicalCity && touched.physicalCity,
                                  })}
                                  placeholder="City"
                                  value={values.physicalCity}
                                  onChange={(e) => {
                                    setFieldValue("physicalCity", e.target.value)
                                    if (hasSamePhysicalAndBillingAddresses)
                                      setFieldValue("city", e.target.value)
                                  }
                                  }
                                  onBlur={(e) => {
                                    setFieldValue("physicalCity", e.target.value.trim())
                                    if (hasSamePhysicalAndBillingAddresses)
                                      setFieldValue("city", e.target.value.trim())
                                  }
                                  }
                                />
                                {errors.physicalCity && touched.physicalCity && (
                                  <div className="error-message mt-1">
                                    {errors.physicalCity}
                                  </div>
                                )}
                              </Form.Group>
                            </Col>
                            <Col lg={2} md={4} className="px-1 mt-3">
                              <Form.Group>
                                <Form.Control
                                  as="select"
                                  className={classNames("f-14", {
                                    error: errors.physicalState && touched.physicalState,
                                  })}
                                  placeholder="State"
                                  value={values.physicalState}
                                  onChange={(e) => {
                                    setFieldValue("physicalState", e.target.value)
                                    if (hasSamePhysicalAndBillingAddresses)
                                      setFieldValue("state", e.target.value)
                                  }
                                  }
                                >
                                  <option value={emptyString}>Select state</option>

                                  {USStates.filter((states) =>
                                    states.country.includes("US")
                                  ).map((state) => {
                                    return (
                                      <option value={state.displayName} key={state.id}>
                                        {state.displayName}
                                      </option>
                                    );
                                  })}
                                </Form.Control>
                                {errors.physicalState && touched.physicalState && (
                                  <div className="error-message mt-1">
                                    {errors.physicalState}
                                  </div>
                                )}
                              </Form.Group>
                            </Col>
                            <Col lg={2} md={4} className="px-1 mt-3">
                              <Form.Group>
                                <NumberFormat
                                  type="text"
                                  allowLeadingZeros={true}
                                  placeholder="Zip Code"
                                  className={classNames("f-14 form-control", {
                                    error: errors.physicalZipCode && touched.physicalZipCode,
                                  })}
                                  value={values.physicalZipCode}
                                  onChange={(e) => {
                                    setFieldValue("physicalZipCode", e.target.value)
                                    if (hasSamePhysicalAndBillingAddresses)
                                      setFieldValue("zipCode", e.target.value)
                                  }
                                  }
                                  allowNegative={false}
                                />
                                {errors.physicalZipCode && touched.physicalZipCode && (
                                  <div className="error-message mt-1">
                                    {errors.physicalZipCode}
                                  </div>
                                )}
                              </Form.Group>
                            </Col>
                          </Row>

                        </Form.Group>}
                        <Form.Group>
                          <Form.Label className={classNames({ required: !shouldShowRFQDetails })}>Billing Address </Form.Label>
                          {isCommercial && <Form.Check
                          style={{width:'auto',height:'auto',clip:'inherit'}}
                            className='custom-form ml-4 d-inline'
                            id="same-as-above-checkbox"
                            label="Same as above"
                            onChange={() => onFieldChange(setFieldValue, values)}
                            checked={hasSamePhysicalAndBillingAddresses}
                          />}
                          <Row className="mx-n1 mb-3">
                            <Col lg={6} className="px-1 mt-3">
                              <Form.Group>
                                <Form.Control
                                  type="text"
                                  className={classNames("f-14", {
                                    error: errors.address && touched.address,
                                  })}
                                  placeholder="Address"
                                  value={values.address}
                                  onChange={(e) =>
                                    setFieldValue("address", e.target.value)
                                  }
                                  onBlur={(e) =>
                                    setFieldValue("address", e.target.value.trim())
                                  }
                                  maxLength={100}
                                  disabled={hasSamePhysicalAndBillingAddresses}
                                />
                                {errors.address && touched.address && (
                                  <div className="error-message mt-1">
                                    {errors.address}
                                  </div>
                                )}
                              </Form.Group>
                            </Col>
                            <Col lg={2} md={4} className="px-1 mt-3">
                              <Form.Group>
                                <Form.Control
                                  type="text"
                                  className={classNames("f-14", {
                                    error: errors.city && touched.city,
                                  })}
                                  placeholder="City"
                                  value={values.city}
                                  onChange={(e) =>
                                    setFieldValue("city", e.target.value)
                                  }
                                  onBlur={(e) =>
                                    setFieldValue("city", e.target.value.trim())
                                  }
                                  maxLength={100}
                                  disabled={hasSamePhysicalAndBillingAddresses}
                                />
                                {errors.city && touched.city && (
                                  <div className="error-message mt-1">
                                    {errors.city}
                                  </div>
                                )}
                              </Form.Group>
                            </Col>
                            <Col lg={2} md={4} className="px-1 mt-3">
                              <Form.Group>
                                <Form.Control
                                  as="select"
                                  className={classNames("f-14", {
                                    error: errors.state && touched.state,
                                  })}
                                  placeholder="State"
                                  value={values.state}
                                  onChange={(e) =>
                                    setFieldValue("state", e.target.value)
                                  }
                                  disabled={hasSamePhysicalAndBillingAddresses}
                                >
                                  <option value={emptyString}>Select state</option>

                                  {USStates.filter((states) =>
                                    states.country.includes("US")
                                  ).map((state) => {
                                    return (
                                      <option value={state.displayName} key={state.id}>
                                        {state.displayName}
                                      </option>
                                    );
                                  })}
                                </Form.Control>
                                {errors.state && touched.state && (
                                  <div className="error-message mt-1">
                                    {errors.state}
                                  </div>
                                )}
                              </Form.Group>
                            </Col>
                            <Col lg={2} md={4} className="px-1 mt-3">
                              <Form.Group>
                                <NumberFormat                                  
                                  type="text"
                                  allowLeadingZeros={true}
                                  placeholder="Zip Code"
                                  className={classNames("f-14 form-control", {
                                    error: errors.zipCode && touched.zipCode,
                                  })}
                                  value={values.zipCode}
                                  onChange={(e) =>
                                    setFieldValue("zipCode", e.target.value)
                                  }
                                  allowNegative={false}
                                  maxLength={100}
                                  disabled={hasSamePhysicalAndBillingAddresses}
                                />
                                {errors.zipCode && touched.zipCode && (
                                  <div className="error-message mt-1">
                                    {errors.zipCode}
                                  </div>
                                )}
                              </Form.Group>
                            </Col>
                          </Row>
                          {isCommercial &&
                            <Row className="mx-n1 align-items-center mb-3">
                              <Col lg={2} md={2} className="px-1">
                                <Form.Label className="required tax-extemp-mb">Tax Exempt </Form.Label>
                              </Col>
                              <Col lg={3} md={3} className="px-1">
                                <Form.Group>
                                  <Form.Control
                                    as="select"
                                    name="taxExemptValue"
                                    className={classNames("f-14", {
                                      error: errors.taxExemptValue && touched.taxExemptValue,
                                    })}
                                    placeholder="Select Tax Exempt"
                                    value={values.taxExemptValue}
                                    onChange={(e) =>
                                      setFieldValue("taxExemptValue", e.target.value)
                                    }
                                    onBlur={handleBlur}
                                  >
                                    <option value={"Yes"}>Yes</option>
                                    <option value={"No"}>No</option>
                                    <option value={"Partial"}>Partial</option>
                                  </Form.Control>
                                  {errors.taxExemptValue && touched.taxExemptValue && (
                                    <div className="error-message mt-1">
                                      {errors.taxExemptValue}
                                    </div>
                                  )}
                                </Form.Group>
                              </Col>
                            </Row>
                          }
                          {isCommercialMatrix && (
                            <>
                              {hasErrorOnUpload && <span className="error-message">File size limit exceeds 100 MB.</span>}
                              <UploadDocuments onDropHandle={(files, rej) => {
                                if (rej && rej.length > 0) setHasErrorOnUpload(true)
                                else
                                  onDropHandle(values.files, files, setFieldValue)
                              }} isRequired={true} hasError={(errors.files && touched.files) || hasErrorOnUpload} />
                              <DocumentTable uploadedFiles={values.files} removeFromUploadedFiles={(index) => removeFromUploadedFiles(values.files, index, setFieldValue)} />
                            </>
                          )}
                        </Form.Group>
                      </div>
                    </div>
                    <div className="wizard-footer d-flex">
                      <Button
                        variant="primary"
                        className="ml-auto"
                        onClick={handleSubmit}
                      >
                        Next
                      </Button>
                    </div>
                  </>
                )}
              </Formik>
            </BusyIndicator>
          </Col>
        </Row>
      </Card>
    </div>
  );
};
export default PriceDetails;
