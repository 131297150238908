import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Form, Row, Col, OverlayTrigger, Tooltip } from "react-bootstrap";
import classNames from "classnames";
import moment from 'moment'


const DateTimeComponent = ({ field, onFieldChange, data, fieldsResult }) => {
  
  const [fieldRanges, setFIeldRanges] = useState({
    maxDate: "",
    minDate: "",
    allowWeekends: true
  })

  useEffect(() => {
    if (field.ranges) {
      setFIeldRanges({
        maxDate: field.ranges.maxDate,
        minDate: field.ranges.minDate,
        allowWeekends: field.ranges.allowWeekends,
      });
    }
    // eslint-disable-next-line
  }, [field.ranges]);

  const getFormattedDate = (date) => {
    return date && moment(date).format('YYYY-MM-DD')
  };
  
  useEffect(() => {
    const value = fieldsResult[field.name] || field.defaultValue
    onFieldChange(field.name, value);
    // eslint-disable-next-line
  }, []);

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {field.friendlyDescription}
    </Tooltip>
  );

  const isWeekendAllowed = (date) => {
    const day = date.getDay();
    return fieldRanges.allowWeekends ? true : day !== 0 && day !== 6;
  };
  
  
  return (
    <Form.Group>
      <Row className="mx-n1 mb-3">
        <Col lg={6} className="px-1">
          <Form.Label className={classNames({ required: field.required })}>
            {field.friendlyName}
            {field.friendlyDescription && <OverlayTrigger
              placement="top"
              overlay={renderTooltip}
            >
              <i className="ml-2 fa fa-info-circle" aria-hidden="true"></i>
            </OverlayTrigger>}
          </Form.Label>
        </Col>
        <Col lg={6} className="px-1">
          <DatePicker
            selected={data[field.name] ? moment(data[field.name], 'YYYY-MM-DD').toDate() : field.date} 
            className="form-control"
            onChange={date => {
              onFieldChange(
                field.name,
                getFormattedDate(date)
              );
            }}
            popperPlacement="bottom"
            autoComplete="date-picker"
            required={field.required}
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            filterDate={isWeekendAllowed}
            minDate={fieldRanges.minDate }
            maxDate={fieldRanges.maxDate }
            excludeDates = {field.holidays}
            includeDates = {field.availableDates}
          />
        </Col>
      </Row>
    </Form.Group>
  );
};

export default DateTimeComponent;
