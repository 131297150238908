import React from "react";
import classNames from "classnames";
import Button from "react-bootstrap/Button";

const Stepper = ({ pages, currentPath }) => {
  return (
    <ul className="step d-flex flex-nowrap mb-5 px-0 " >
      {pages.map((each, index) => (
        <li
          className={classNames("step-item", {
            active:
              each.path === pages[pages.length - 1].path
                ? false
                : currentPath === each.path,
          })}
          key={index}
        >
          <Button className="step-item" variant="link">{each.label}</Button>
        </li>
      ))}
    </ul>
  );
};

export default Stepper;
