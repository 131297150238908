import { createContext, useContext } from "react";

const userContext = createContext(null)

const useUserContext = () => {

    const ctx = useContext(userContext)
    if( !ctx ){
        throw new Error("User Context Undefined")
    }
    return ctx;
}

const UserProvider = ({children})=>{
    
    let selectMonth ="";

    const setSelectMonth = (month)=>{
         selectMonth = month;
     }
 
    const getSelectMonth =  ()=>{
         return selectMonth;
     }
    return <userContext.Provider value={{set:setSelectMonth, get:getSelectMonth}}>
        { children}
    </userContext.Provider>
}

export {UserProvider, useUserContext }

