import { CLASS } from "../features/GetPrice";

export const getUsage = (keyValue, price) => {
    return parseFloat(parseFloat(keyValue === "Monthly Bill"
      ? price.class === CLASS.RESIDENTIAL
        ? (price.usage * 12) / 0.12
        : (price.usage * 12 ) / 0.095
      : price.usage).toFixed(2))
}

export const getMonthlyUsage = (keyValue, usage) =>
  parseFloat(parseFloat(keyValue === 'Annual Usage' ? usage / 12 : usage));


export const getTermFilteredPlans = (searchedPrices, brokerageId, brokerageIdsForTermRange) => {
  return Object.keys(brokerageIdsForTermRange).filter((id) => brokerageId === id )[0] ? searchedPrices.filter(price => price.Term >= brokerageIdsForTermRange[brokerageId].min && price.Term <= brokerageIdsForTermRange[brokerageId].max) : searchedPrices;
}