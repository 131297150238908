import { getFormattedDate } from "../features/utils/getFormattedDate"

export const getFormattedValue = (type, value, states, key) => {
    if (type === 'link') {
        return <>
            {key}
            <a href={value} target="_blank" rel="noreferrer">
                <i className="ml-2 fa fa-external-link"></i>
            </a>
        </>
    }
    else if (type === 'date')
        return getFormattedDate(value)
    else if (type === 'state') {
        const stateName = states && states.find((states) =>
            states.abbreviation === value
        )
        return stateName && stateName.name
            .replaceAll("_", " ")
            .replace(/\b(\w)/g, (s) => s.toUpperCase());
    }
    else if (type === 'number') {
        return parseFloat(Number(value)).toFixed(2)
    }
}
