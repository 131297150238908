import { Form, Row } from "react-bootstrap";

// Field with Checkbox

const InformationComponent = ({ field }) => (
    <Form.Group>
      <Row className="mx-n1 mb-3">
        <Form.Group className="mb-3 d-flex">
          <Form.Label
            className='font-italic'
            required={field.required}
            label={field.friendlyName}
            id={field.name}>
             {field.friendlyName}
          </Form.Label>
        </Form.Group>
      </Row>
    </Form.Group>
)

export default InformationComponent