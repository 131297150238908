import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef,
} from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import queryString from "query-string";
import { Formik } from "formik";
import classNames from "classnames";
import * as yup from "yup";
import {
  Row,
  Col,
  Button,
  Card,
  Form,
  ButtonGroup,
  InputGroup,
} from "react-bootstrap";
import Alert from "react-bootstrap/Alert";
import cross from "../../assets/images/cross.png";
import "font-awesome/css/font-awesome.min.css";
import {
  getUtilitiesFromZip,
  getUtilitiesForStateByZip,
  getRateCodes,
  getStateFromZip,
  getDealIdSettings,
  getOpportunityIdSettings,
  getLeadCapturePortalSettings,
  getSearchPrice,
  validateReferralCode,
} from "./getPrice.asyncActions";
import {
  COMMODITY,
  CLASS,
  TIERS,
  COMMODITIES_LIST,
  UNITS,
  PORTAL_SETTINGS,
} from "./getPrice.constants";
import { actions } from "./getPrice.slice";
import {
  selectPrice,
  selectRateCodes,
  selectUtilities,
  selectTier,
  selectPortalSetting,
  selectIsStateUpdated,
  selectIsUtilityUpdated,
  selectIsInvalidZipcode,
  selectIsEmptyUtility,
  selectIsRateCodeUpdated,
  selectIsFetchingSettings,
} from "./getPrice.selectors";
import NumberFormat from "react-number-format";
import BusyIndicator, { REGIONS } from "../../widgets/busyIndicator";
import Overlay from "../../widgets/Tooltip";
import {
  selectUserSettings,
  selectHasNoRecords,
  selectIsReferralCodeValid,
} from "./getPrice.selectors";
import { isEmpty } from "lodash";
import { setFilters, monthNames, SORTING_ORDERS } from "../ShowPrices";
import {
  selectSelectedSupplier,
  selectSteps,
  setSteps,
  searchForAccount,
  ENEREX_STATES,
  ENEREX_STATESFORPRICE,
} from "../PriceAndCustomerDetails";
import { selectClientConfig } from "../clientContext";
import moment from "moment";
import { debounce } from "../../helper/debounce";
import { getMonthlyUsage, getUsage } from "../../helper/getUsage";
import analytics from "../../helper/analytics";
import { getFormattedUsage } from "../utils/getFormattedUsage";

const {
  savePrice,
  setShouldShowRFQDetails,
  setTier,
  setIsPriceExpired,
  setIsStateUpdated,
  setIsUtilityUpdated,
  setPriceForm,
  setIsCustomPricing,
  setIsRateCodeUpdated,
  setIsFetchingSettings,
} = actions;

const { TH, KWH } = UNITS;

const emptyString = "";
const dollarSign = "$";
const emptyArray = [];
const allowedZipCodeLength = 5;
const getFormattedQueryData = (data) =>
  data?.includes("/") ? data.slice(0, data.indexOf("/")) : data;

const GetPrice = () => {
  const selectedSupplier = useSelector(selectSelectedSupplier);
  const clientConfig = useSelector(selectClientConfig);
  const basicInfo = useSelector(selectPrice);
  const history = useHistory();
  const dispatch = useDispatch();
  const rateCodes = useSelector(selectRateCodes);
  const utilities = useSelector(selectUtilities);
  const isFetchingSettings = useSelector(selectIsFetchingSettings);
  const {
    annualUsage,
    monthlyBill,
    zc,
    resi,
    comm,
    search,
    rc,
    oid: opportunityId,
    did: dealId,
  } = queryString.parse(window.location.search);
  const [showError, setShowError] = useState(false);
  const [isSearchedClicked, setIsSearchedClicked] = useState(false);
  const [isTierAvailable, setIsTierAvailable] = useState(true);
  const [enteredZipCode, setEnteredZipCode] = useState();
  const userSettings = useSelector(selectUserSettings);
  const hasNoRecords = useSelector(selectHasNoRecords);
  const steps = useSelector(selectSteps);
  const portalSettings = useSelector(selectPortalSetting);
  const [selectedCommodity, setSelectedCommodity] = useState();
  const priceDetails = useSelector(selectPrice);
  const tier = useSelector(selectTier);
  const isStateUpdated = useSelector(selectIsStateUpdated);
  const isUtilityUpdated = useSelector(selectIsUtilityUpdated);
  const isInvalidZipcode = useSelector(selectIsInvalidZipcode);
  const isEmptyUtility = useSelector(selectIsEmptyUtility);
  const isRateCodeUpdated = useSelector(selectIsRateCodeUpdated);
  const isReferralCodeValid = useSelector(selectIsReferralCodeValid);
  const date = new Date();
  const currentYear = date.getFullYear();
  const currentMonth = date.getMonth() + 1;
  const dateValidUpto =
    userSettings.approvedQuotations &&
    userSettings.approvedQuotations.length > 0 &&
    userSettings.approvedQuotations[0].validUpto;
  const currentDate = moment(new Date());
  const duration = moment.duration(currentDate.diff(dateValidUpto));
  const isPriceExpired = dateValidUpto ? duration.asDays() > 0 : true;
  const isEnerexStatePresent =
    basicInfo.state && ENEREX_STATES.includes(basicInfo.state.name);
  const isEnerexStatePresentForPrice =
    basicInfo.state && ENEREX_STATESFORPRICE.includes(basicInfo.state.name);
  const { brokerageId, parameters } = clientConfig;
  let portalSettingDefaultClass = !!parameters
    ? parameters === "display:commercial"
      ? "Commercial"
      : "Residential"
    : portalSettings.defaultClass;
  const [selectedClass, setSelectedClass] = useState(portalSettingDefaultClass);
  const [noRateCodesPresent, setNoRateCodesPresent] = useState(false);
  const updatedRateCodes = rateCodes.map((each) => ({
    ...each,
    displayName: each.RateClass,
  }));
  const loadZoneRateCodes = rateCodes.map((each) => each.LoadProfile);
  const rateClassCodes = rateCodes.map((each) => each.RateClass);
  const [isSearchButtonClicked, setIsSearchButtonClicked] = useState(false);

  // isEnerexStatePresent
  //   ? rateCodes
  //       .filter(
  //         (each) => each.RateClassification === CLASS.COMMERCIAL.toUpperCase()
  //       )
  //       .map(({ Id, ...each }) => ({
  //         ...each,
  //         id: Id,
  //         displayName: each.RateDescription,
  //       }))
  //   : rateCodes.length > 0 && rateCodes[0].List
  //   ? rateCodes[0].List.map((each) => ({
  //       ...each,
  //       displayName: each.RateClass,
  //     }))
  //   : [];

  const [updatedStepsForFalseEnrollment, setUpdatedStepsForFalseEnrollment] =
    useState(emptyArray);
  const [stepsForEnrollmentandPlanId, setStepsForEnrollmentandPlanId] =
    useState(emptyArray);
  const shouldShowClassSelection = portalSettings.hasOwnProperty(
    "showClassSelection"
  )
    ? portalSettings.showClassSelection
    : true;
  const shouldShowCommoditySelection = portalSettings.hasOwnProperty(
    "showCommoditySelection"
  )
    ? portalSettings.showCommoditySelection
    : true;
  const usageValue = getFormattedQueryData(annualUsage || monthlyBill);

  const handleTier = useCallback(
    (usage, selectedClass) => {
      //usage = parseInt(usage)
      //const { mediumBoundaryMin, largeBoundaryMin, smallBoundaryMin, smallBoundaryMax, mediumBoundaryMax, largeBoundaryMax } = portalSettings;
      const {
        smallBoundaryMinElectric,
        smallBoundaryMaxElectric,
        mediumBoundaryMinElectric,
        mediumBoundaryMaxElectric,
        largeBoundaryMinElectric,
        largeBoundaryMaxElectric,
      } = portalSettings;
      const {
        smallBoundaryMinGas,
        smallBoundaryMaxGas,
        mediumBoundaryMinGas,
        mediumBoundaryMaxGas,
        largeBoundaryMinGas,
        largeBoundaryMaxGas,
      } = portalSettings;
      if (selectedClass === CLASS.RESIDENTIAL) {
        dispatch(setTier(TIERS.SMALL));
      } else if (selectedClass === CLASS.COMMERCIAL) {
        if (selectedCommodity === 1) {
          if (
            usage >= smallBoundaryMinElectric &&
            usage <= smallBoundaryMaxElectric
          ) {
            dispatch(setTier(TIERS.SMALL));
          } else if (
            usage >= mediumBoundaryMinElectric &&
            usage <= mediumBoundaryMaxElectric
          ) {
            dispatch(setTier(TIERS.MID));
          } else if (
            usage >= largeBoundaryMinElectric &&
            usage <= largeBoundaryMaxElectric
          ) {
            dispatch(setTier(TIERS.LARGE));
          }
        } else if (selectedCommodity === 2) {
          if (usage >= smallBoundaryMinGas && usage <= smallBoundaryMaxGas) {
            dispatch(setTier(TIERS.SMALL));
          } else if (
            usage >= mediumBoundaryMinGas &&
            usage <= mediumBoundaryMaxGas
          ) {
            dispatch(setTier(TIERS.MID));
          } else if (
            usage >= largeBoundaryMinGas &&
            usage <= largeBoundaryMaxGas
          ) {
            dispatch(setTier(TIERS.LARGE));
          }
        }
      } else {
        dispatch(setTier(TIERS.SMALL));
      }
    },
    [dispatch, portalSettings, selectedCommodity]
  );

  let owayrc = "";
  let owaydid = "";

  const [localhref, setLocalhref] = useState("");
  let partTotalRc = [];
  let partTotalDid = [];
  useEffect(() => {
    setLocalhref(window.location.href);
  }, []);
  if (localhref.includes("rc=")) {
    partTotalRc = localhref.split("rc=");
  }
  if (owayrc === "") {
    owayrc = partTotalRc[1]?.replace("#/", "");
  }
  if (localhref.includes("did=")) {
    partTotalDid = localhref.split("did=");
  }
  if (owaydid === "") {
    owaydid = partTotalDid[1]?.replace("#/", "");
  }

  useEffect(() => {
    if (owayrc && !isSearchedClicked) dispatch(validateReferralCode(owayrc));
  }, [dispatch, owayrc, isSearchedClicked]);

  useEffect(() => {
    if (rc && !isSearchedClicked) dispatch(validateReferralCode(rc));
  }, [dispatch, rc, isSearchedClicked]);

  useEffect(() => {
    dispatch(setIsPriceExpired(isPriceExpired));
  }, [dispatch, isPriceExpired]);

  const companyClass =
    resi || portalSettings.hasOwnProperty("defaultClass")
      ? portalSettingDefaultClass
      : CLASS.RESIDENTIAL;
  const companyCommodity =
    comm || portalSettings.hasOwnProperty("defaultCommodityId")
      ? portalSettings.defaultCommodityId
      : 0;
  const companyUsage =
    annualUsage ||
    userSettings.annualUsage ||
    (portalSettingDefaultClass === CLASS.RESIDENTIAL &&
    portalSettings.defaultCommodityId === 1
      ? portalSettings.defaultValueResiElectric
      : null) ||
    (portalSettingDefaultClass === CLASS.RESIDENTIAL &&
    portalSettings.defaultCommodityId === 2
      ? portalSettings.defaultValueResiGas
      : null) ||
    (portalSettingDefaultClass === CLASS.COMMERCIAL &&
    portalSettings.defaultCommodityId === 1
      ? portalSettings.defaultValueCommElectric
      : null) ||
    (portalSettingDefaultClass === CLASS.COMMERCIAL &&
    portalSettings.defaultCommodityId === 2
      ? portalSettings.defaultValueCommGas
      : null) ||
    emptyString;
  const companyZipcode = zc || portalSettings.defaultZipCode || emptyString;
  const companyUtility = portalSettings.defaultUtility || emptyString;
  const companyRateCode = portalSettings.defaultRateCode || "All";
  const validation = yup.object().shape({
    class: yup.string().required("Class is required"),
    commodity: yup
      .string()
      .test("commo", "Commodity is required", (value) => value !== "0"),
    usage: yup.string().when("showUsages", {
      is: (value) => value,
      then: yup
        .string()
        .required(
          portalSettings.keyValue
            ? `${portalSettings.keyValue} is required`
            : "Annual Usage is required"
        ),
      otherwise: yup.string(),
    }),
    zipCode: yup
      .string()
      .required("Zip Code is required")
      .test(
        "zipcode",
        "Invalid Zip Code.",
        (value) =>
          value && value.length >= allowedZipCodeLength && !isInvalidZipcode
      ),
    utility: yup.object().when("class", {
      is: CLASS.COMMERCIAL,
      then: yup
        .object()
        .test(
          "utility",
          `We're sorry, but there are no ${COMMODITIES_LIST[selectedCommodity]} options for your zipcode. Check back later!`,
          () => (tier === TIERS.SMALL ? !isEmptyUtility : true)
        ),
    }),
    rateCode: yup.string().when("class", {
      is: CLASS.COMMERCIAL,
      then: yup.string(),
    }),
    referralCode: yup
      .string()
      .test(
        "invalid-code",
        "Invalid Referral Code",
        (value) => !value || isReferralCodeValid
      ),
  });

  const getZipCodeForOpportunity =
    (userSettings.pipelineAccount &&
      userSettings.pipelineAccount.length > 0 &&
      userSettings.pipelineAccount[0].serviceAddressZip) ||
    (userSettings.pipelineAddresses &&
      userSettings.pipelineAddresses.length > 0 &&
      userSettings.pipelineAddresses[0].zipCode); // key name may change here dont have response currently

  const getZipCodeForDeal =
    userSettings.dealAccount &&
    userSettings.dealAccount.length > 0 &&
    userSettings.dealAccount[0].serviceAddressZip;

  const initialValues = useMemo(
    () => ({
      class:
        (opportunityId || dealId || owaydid || portalSettings.companyId) &&
        !isSearchedClicked
          ? (!isEmpty(userSettings) &&
              (userSettings.dealLevelName === CLASS.RESIDENTIAL
                ? CLASS.RESIDENTIAL
                : CLASS.COMMERCIAL)) ||
            companyClass
          : basicInfo.class || resi || selectedClass
          ? portalSettingDefaultClass
          : emptyString,
      commodity:
        (opportunityId || dealId || owaydid || portalSettings.companyId) &&
        !isSearchedClicked
          ? ((dealId || owaydid) && userSettings
              ? userSettings.typeId
              : userSettings.dealTypeId) || companyCommodity
          : basicInfo.commodity || comm || selectedCommodity
          ? portalSettings.defaultCommodityId
          : emptyString,
      rateCode:
        (opportunityId || dealId || owaydid || portalSettings.companyId) &&
        !isSearchedClicked
          ? companyRateCode
          : basicInfo.rateCode || "All",
      usage:
        (opportunityId || dealId || owaydid || portalSettings.companyId) &&
        !isSearchedClicked
          ? (!isEmpty(userSettings) &&
              (dealId || owaydid
                ? getUsage(portalSettings.keyValue || "Annual Usage", {
                    usage: userSettings.annualUsage,
                    class:
                      userSettings.dealLevelName === CLASS.RESIDENTIAL
                        ? CLASS.RESIDENTIAL
                        : CLASS.COMMERCIAL,
                  })
                : userSettings.pipelineAccount &&
                  userSettings.pipelineAccount.length > 0 &&
                  userSettings.pipelineAccount[0].annualUsage)) ||
            companyUsage
          : basicInfo.usage || usageValue || emptyString,
      utility:
        (opportunityId || dealId || owaydid || portalSettings.companyId) &&
        !isSearchedClicked
          ? companyUtility
          : basicInfo.utility || {},
      zipCode:
        (opportunityId || dealId || owaydid || portalSettings.companyId) &&
        !isSearchedClicked
          ? (!isEmpty(userSettings) &&
              (dealId || owaydid
                ? getZipCodeForDeal
                : getZipCodeForOpportunity)) ||
            companyZipcode
          : basicInfo.zipCode || zc || emptyString,
      referralCode:
        rc && !isSearchedClicked
          ? rc
          : owayrc || basicInfo.referralCode || emptyString,
      showUsages: portalSettings.showUsages,
    }),
    [
      // basicInfo,
      //comm,
      dealId,
      owaydid,
      companyClass,
      companyCommodity,
      // companyRateCode,
      // companyUsage,
      // companyUtility,
      // companyZipcode,
      //isSearchedClicked,
      opportunityId,
      //rc,
      //resi,
      //usageValue,
      //zc,
      portalSettings,
      // userSettings,
      // getZipCodeForDeal,
      // getZipCodeForOpportunity,
    ]
  );

  const { commodity, referralCode, utility } = initialValues;

  useEffect(() => {
    setSelectedCommodity(commodity);
  }, [commodity]);

  useEffect(() => {
    setSelectedClass(initialValues.class);
  }, [initialValues.class]);

  useEffect(() => {
    if (isSearchedClicked && tier && !noRateCodesPresent) {
      dispatch(setShouldShowRFQDetails(tier === TIERS.LARGE));
      if (tier === TIERS.NONE) setShowError(true);
      else if (tier === TIERS.MID)
        if (rateCodes.length === 0 || isEmptyUtility) {
          dispatch(setShouldShowRFQDetails(true));
          dispatch(setIsCustomPricing(true));
          history.push("info");
        } else history.push("select-pricing");
      else history.push(tier === TIERS.LARGE ? "info" : "show-prices");
    }
  }, [
    tier,
    isEmptyUtility,
    rateCodes,
    dispatch,
    history,
    isSearchedClicked,
    commodity,
    referralCode,
    utility,
    selectedSupplier,
    priceDetails,
    noRateCodesPresent,
    brokerageId,
    hasNoRecords,
  ]);

  useEffect(() => {
    if (isStateUpdated && selectedClass === CLASS.RESIDENTIAL) {
      dispatch(
        getRateCodes({
          filters: {
            LDCIdentifiers: [],
            Commodity: COMMODITIES_LIST[selectedCommodity],
            StateId: priceDetails.state.id,
            StateName: priceDetails.state.name,
            ZipCode: enteredZipCode,
            BrokerId: brokerageId,
            RateClassification: CLASS.RESIDENTIAL,
          },
        })
      );
      setNoRateCodesPresent(false);
      dispatch(setIsStateUpdated());
    }
  }, [
    priceDetails,
    brokerageId,
    dispatch,
    enteredZipCode,
    isStateUpdated,
    selectedClass,
    selectedCommodity,
  ]);

  useEffect(() => {
    if (
      (isStateUpdated || isUtilityUpdated) &&
      utilities &&
      utilities.length > 0
    ) {
      selectedCommodity &&
        dispatch(
          getRateCodes({
            filters: {
              LDCIdentifiers: [utilities[0].LDCIdentifier],
              Commodity: COMMODITIES_LIST[selectedCommodity],
              StateId: priceDetails.state.id,
              StateName: priceDetails.state.name,
              ZipCode: enteredZipCode,
              BrokerId: brokerageId,
              RateClassification: selectedClass,
            },
          })
        );
      setNoRateCodesPresent(false);
      dispatch(setIsUtilityUpdated());
      dispatch(setIsStateUpdated());
    }
  }, [
    dispatch,
    enteredZipCode,
    selectedClass,
    isStateUpdated,
    selectedCommodity,
    isUtilityUpdated,
    priceDetails.state,
    utilities,
    brokerageId,
  ]);

  useEffect(() => {
    if ((dealId || owaydid) && isEmpty(userSettings)) {
      const id = getFormattedQueryData(dealId || owaydid);
      dispatch(getDealIdSettings({ id }));
    }
    if (opportunityId) {
      const id = getFormattedQueryData(opportunityId);
      dispatch(getOpportunityIdSettings({ id }));
    }
  }, [dispatch, opportunityId, dealId, owaydid, userSettings]);

  useEffect(() => {
    if (
      resi === CLASS.COMMERCIAL ||
      ((opportunityId || dealId || owaydid) &&
        initialValues.zipCode.length >= allowedZipCodeLength &&
        initialValues.class === CLASS.COMMERCIAL)
    )
      dispatch(
        getUtilitiesFromZip({
          zipcode: initialValues.zipCode,
          commodityId: selectedCommodity || initialValues.commodity,
        })
      ).then(({ payload }) => {
        if (isEmpty(payload) && tier === TIERS.LARGE) {
          dispatch(
            getUtilitiesForStateByZip({
              zipcode: initialValues.zipCode,
              commodityId: selectedCommodity || initialValues.commodity,
            })
          );
        }
      });
    // eslint-disable-next-line
  }, [dispatch, resi, opportunityId, selectedCommodity, dealId, tier, owaydid]);

  useEffect(() => {
    if (steps.length > 4) {
      setUpdatedStepsForFalseEnrollment(
        steps.filter((step) => step.label !== "Enrollment")
      );
      setStepsForEnrollmentandPlanId(
        steps.filter((step) => step.label !== "Additional Information")
      );
    }
  }, [steps]);

  useEffect(() => {
    if (!isEmpty(userSettings)) {
      if (opportunityId && getZipCodeForOpportunity) {
        isPriceExpired || !(userSettings.LDCName && userSettings.LDCId)
          ? history.push("show-prices")
          : history.push("info");
      }
      if ((dealId || owaydid) && getZipCodeForDeal) {
        history.push("show-prices");
      }
    }
  }, [
    opportunityId,
    dealId,
    owaydid,
    userSettings,
    getZipCodeForDeal,
    getZipCodeForOpportunity,
    history,
    isPriceExpired,
  ]);

  const withoutDealId = useCallback(() => {
    if (search && !isEmpty(portalSettings)) {
      const price =
        portalSettingDefaultClass === CLASS.RESIDENTIAL
          ? {
              class: resi || portalSettingDefaultClass,
              commodity: comm || portalSettings.defaultCommodityId,
              zipCode: zc || portalSettings.defaultZipCode,
              usage: usageValue || portalSettings.defaultValue,
              referralCode: rc || owayrc || emptyString,
            }
          : initialValues;
      if (isEmpty(basicInfo)) {
        dispatch(savePrice(price));
        dispatch(getStateFromZip({ zipcode: price.zipCode }));
        handleTier(
          usageValue || portalSettings.defaultValue,
          resi || portalSettingDefaultClass
        );
      }
      tier && !isSearchedClicked && setIsSearchedClicked(true);
    }
  }, [
    dispatch,
    search,
    basicInfo,
    usageValue,
    comm,
    initialValues,
    isSearchedClicked,
    rc,
    owayrc,
    resi,
    tier,
    portalSettings,
    handleTier,
    zc,
  ]);

  const updatedMonth = (currentMonth) => {
    if (currentMonth < 10) return "0" + currentMonth;
    return currentMonth;
  };

  const getStartDate = useCallback(() => {
    return currentYear + "-" + updatedMonth(currentMonth) + "-01";
  }, [currentMonth, currentYear]);

  const withDealIdOrOpportunityId = useCallback(() => {
    if (
      (dealId || owaydid || opportunityId) &&
      !isEmpty(userSettings) &&
      !hasNoRecords
    ) {
      const price =
        userSettings.defaultUserClass === CLASS.RESIDENTIAL
          ? {
              class: resi || userSettings.defaultUserClass,
              commodity: comm || userSettings.defaultUserCommodity,
              zipCode: zc || userSettings.defaultUserZipCode,
              usage: usageValue || userSettings.defaultUserAnnualUsage,
              referralCode: rc || owayrc || emptyString,
            }
          : initialValues;
      if (isEmpty(basicInfo)) {
        const endDate = new Date(userSettings.endDate);
        const month = endDate ? endDate.getMonth() : "";
        const year = endDate ? endDate.getFullYear() : "";
        const index = month === 11 ? 0 : monthNames.indexOf(month) + 1;
        const newYear = month === 11 ? year + 1 : year;

        dispatch(savePrice(price));
        price.zipCode && dispatch(getStateFromZip({ zipcode: price.zipCode }));
        handleTier(
          usageValue || userSettings.annualUsage,
          resi || CLASS.RESIDENTIAL
        );
        price.zipCode &&
          dispatch(
            getUtilitiesFromZip({
              zipcode: price.zipCode,
              commodityId: price.commodity,
            })
          ).then(({ payload }) => {
            if (isEmpty(payload) && tier === TIERS.LARGE) {
              dispatch(
                getUtilitiesForStateByZip({
                  zipcode: price.zipCode,
                  commodityId: price.commodity,
                })
              );
            }
          });
        !isEmpty(userSettings.defaultFilters) &&
          dispatch(
            setFilters(
              opportunityId
                ? userSettings.defaultFilters
                : {
                    ...userSettings.defaultFilters,
                    month: monthNames[index],
                    year: newYear,
                  }
            )
          );
        price.zipCode &&
          dispatch(
            getSearchPrice({
              BrokerId: brokerageId,
              LDCS: [],
              Commodity: COMMODITIES_LIST[price.commodity],
              RateClassification: price.class,
              Suppliers: [],
              ZipCode: price.zipCode,
              SortBy:
                price.class === CLASS.RESIDENTIAL && isEnerexStatePresent
                  ? price.commodity === COMMODITY.ELECTRIC
                    ? `Price${
                        isEnerexStatePresentForPrice
                          ? sortPricesAsPerUsage(
                              getMonthlyUsage(
                                portalSettings.keyValue || "Annual Usage",
                                price.usage
                              )
                            ) + "kwh"
                          : ""
                      }`
                    : `Price${
                        isEnerexStatePresentForPrice
                          ? sortPricesAsPerUsage(
                              getMonthlyUsage(
                                portalSettings.keyValue || "Annual Usage",
                                price.usage
                              )
                            ) + "therm"
                          : ""
                      }`
                  : SORTING_ORDERS[0].value,
              SortDirection: SORTING_ORDERS[0].order,
              StartDate: getStartDate(),
              UsageMin: getUsage(
                portalSettings.keyValue || "Annual Usage",
                price
              ),
            })
          );
        if (isPriceExpired) {
          getZipCodeForOpportunity && history.push("show-prices");
        } else {
          userSettings.accountNumber &&
            dispatch(searchForAccount({ account: userSettings.accountNumber }));
          userSettings.selectedSupplier?.PlanId
            ? dispatch(setSteps(stepsForEnrollmentandPlanId))
            : dispatch(setSteps(updatedStepsForFalseEnrollment));
        }
      }
    }
  }, [
    dispatch,
    handleTier,
    getStartDate,
    tier,
    dealId,
    opportunityId,
    userSettings,
    basicInfo,
    comm,
    history,
    rc,
    owayrc,
    owaydid,
    resi,
    stepsForEnrollmentandPlanId,
    updatedStepsForFalseEnrollment,
    usageValue,
    zc,
    initialValues,
    hasNoRecords,
    isPriceExpired,
    getZipCodeForOpportunity,
    brokerageId,
    portalSettings.keyValue,
    isEnerexStatePresent,
  ]);

  useEffect(() => {
    withoutDealId();
  }, [withoutDealId]);

  useEffect(() => {
    if (portalSettings.defaultValue && portalSettingDefaultClass)
      handleTier(portalSettings.defaultValue, portalSettingDefaultClass);
  }, [handleTier, portalSettings.defaultValue, portalSettingDefaultClass]);

  useEffect(() => {
    if (brokerageId && isEmpty(portalSettings) && !isFetchingSettings) {
      dispatch(setIsFetchingSettings(true));
      dispatch(getLeadCapturePortalSettings({ brokerageId })).then(() =>
        dispatch(setIsFetchingSettings(false))
      );
    }
  }, [dispatch, brokerageId, portalSettings, isFetchingSettings]);

  useEffect(() => {
    withDealIdOrOpportunityId();
  }, [withDealIdOrOpportunityId, owaydid, opportunityId, dealId]);

  const formRef = useRef();
  useEffect(() => {
    if (
      isSearchButtonClicked &&
      isRateCodeUpdated &&
      selectedClass === CLASS.RESIDENTIAL
    ) {
      if (formRef.current) {
        formRef.current.handleSubmit();
        setIsSearchButtonClicked(false);
      }
    }
  }, [isSearchButtonClicked, isRateCodeUpdated, selectedClass]);

  useEffect(() => {
    if (isEnerexStatePresent && selectedClass === CLASS.COMMERCIAL) {
      const current = formRef.current;
      current.setFieldValue("");
    }
  }, [loadZoneRateCodes, isEnerexStatePresent, selectedClass]);

  const checkTierAvailablity = () => {
    if (selectedClass === CLASS.RESIDENTIAL) return true;
    else if (tier === TIERS.SMALL)
      return portalSettings.isSmallBoundarySelected;
    else if (tier === TIERS.MID) return portalSettings.isMediumBoundarySelected;
    else if (tier === TIERS.LARGE)
      return portalSettings.isLargeBoundarySelected;
  };

  const sortPricesAsPerUsage = (usage) => {
    const prices = [500, 1000, 2000];
    const sortedPrices = prices.sort((a, b) => {
      return Math.abs(usage - a) - Math.abs(usage - b);
    });
    return sortedPrices[0];
  }; // Move to utils

  useEffect(() => {
    if (portalSettings) {
      if (
        (portalSettingDefaultClass === " " || selectedClass === " ") &&
        (portalSettings.defaultCommodityId === 0 || selectedCommodity === 0)
      ) {
        if (formRef.current) {
          formRef.current.values.usage = " ";
        }
      } else {
        if (formRef.current) {
          if (portalSettings.showUsagesResi === true) {
            if (
              selectedClass === CLASS.RESIDENTIAL &&
              selectedCommodity === COMMODITY.ELECTRIC
            ) {
              formRef.current.values.usage =
                portalSettings.defaultValueResiElectric;
              handleTier(
                portalSettings.defaultValueResiElectric,
                selectedClass
              );
            } else if (
              selectedClass === CLASS.RESIDENTIAL &&
              selectedCommodity === COMMODITY.NAT_GAS
            ) {
              formRef.current.values.usage = portalSettings.defaultValueResiGas;
              handleTier(portalSettings.defaultValueResiGas, selectedClass);
            }
          } else {
            if (
              selectedClass === CLASS.RESIDENTIAL &&
              selectedCommodity === COMMODITY.ELECTRIC
            ) {
              formRef.current.values.usage = " ";
            } else if (
              selectedClass === CLASS.RESIDENTIAL &&
              selectedCommodity === COMMODITY.NAT_GAS
            ) {
              formRef.current.values.usage = " ";
            }
          }
          if (portalSettings.showUsagesResi === true) {
            if (
              selectedClass === CLASS.RESIDENTIAL &&
              selectedCommodity === COMMODITY.ELECTRIC
            ) {
              formRef.current.values.usage =
                portalSettings.defaultValueResiElectric;
              handleTier(
                portalSettings.defaultValueResiElectric,
                selectedClass
              );
            } else if (
              selectedClass === CLASS.RESIDENTIAL &&
              selectedCommodity === COMMODITY.NAT_GAS
            ) {
              formRef.current.values.usage = portalSettings.defaultValueResiGas;
              handleTier(portalSettings.defaultValueResiGas, selectedClass);
            }
          } else {
            if (
              selectedClass === CLASS.RESIDENTIAL &&
              selectedCommodity === COMMODITY.ELECTRIC
            ) {
              formRef.current.values.usage = " ";
            } else if (
              selectedClass === CLASS.RESIDENTIAL &&
              selectedCommodity === COMMODITY.NAT_GAS
            ) {
              formRef.current.values.usage = " ";
            }
          }
          if (portalSettings.showUsagesComm === true) {
            if (
              selectedClass === CLASS.COMMERCIAL &&
              selectedCommodity === COMMODITY.ELECTRIC
            ) {
              formRef.current.values.usage =
                portalSettings.defaultValueCommElectric;
              handleTier(
                portalSettings.defaultValueCommElectric,
                selectedClass
              );
            } else if (
              selectedClass === CLASS.COMMERCIAL &&
              selectedCommodity === COMMODITY.NAT_GAS
            ) {
              formRef.current.values.usage = portalSettings.defaultValueCommGas;
              handleTier(portalSettings.defaultValueCommGas, selectedClass);
            }
          } else {
            if (
              selectedClass === CLASS.COMMERCIAL &&
              selectedCommodity === COMMODITY.ELECTRIC
            ) {
              formRef.current.values.usage = " ";
            } else if (
              selectedClass === CLASS.COMMERCIAL &&
              selectedCommodity === COMMODITY.NAT_GAS
            ) {
              formRef.current.values.usage = " ";
            }
          }
          if (portalSettings.showUsagesComm === true) {
            if (
              selectedClass === CLASS.COMMERCIAL &&
              selectedCommodity === COMMODITY.ELECTRIC
            ) {
              formRef.current.values.usage =
                portalSettings.defaultValueCommElectric;
              handleTier(
                portalSettings.defaultValueCommElectric,
                selectedClass
              );
            } else if (
              selectedClass === CLASS.COMMERCIAL &&
              selectedCommodity === COMMODITY.NAT_GAS
            ) {
              formRef.current.values.usage = portalSettings.defaultValueCommGas;
              handleTier(portalSettings.defaultValueCommGas, selectedClass);
            }
          } else {
            if (
              selectedClass === CLASS.COMMERCIAL &&
              selectedCommodity === COMMODITY.ELECTRIC
            ) {
              formRef.current.values.usage = " ";
            } else if (
              selectedClass === CLASS.COMMERCIAL &&
              selectedCommodity === COMMODITY.NAT_GAS
            ) {
              formRef.current.values.usage = " ";
            }
          }
        }
      }
    }
  }, [portalSettings, selectedClass, selectedCommodity, handleTier]);

  if (
    ((dealId || owaydid || opportunityId ? hasNoRecords : true) &&
      !isEmpty(portalSettings)) ||
    (!isEmpty(portalSettings) && !search) ||
    (!isEmpty(portalSettings) &&
      (zc || rc || owayrc || annualUsage || resi || comm) &&
      !search &&
      !isEmpty(portalSettings))
  )
    return (
      <Formik
        innerRef={formRef}
        initialValues={initialValues}
        validationSchema={validation}
        enableReinitialize={true}
        onSubmit={(values) => {
          let { commodity, usage, zipCode, referralCode } = values;

          if (usage === " " || usage === 0) {
            usage = portalSettings.defaultValue;
          }

          // const RateClass = values.class.toUpperCase();
          if (values.class === CLASS.RESIDENTIAL && !isRateCodeUpdated)
            setIsSearchButtonClicked(true);
          if (isRateCodeUpdated || isEmptyUtility) {
            setIsSearchButtonClicked(false);
            if (rateCodes.length === 0 && tier === TIERS.SMALL) {
              setNoRateCodesPresent(true);
              return;
            }
            let formValues = {
              BrokerId: brokerageId,
              LDCS:
                values.class === CLASS.COMMERCIAL
                  ? [values.utility?.LDCIdentifier]
                  : [],
              Commodity: COMMODITIES_LIST[commodity],
              RateClassification: values.class,
              Suppliers: [],
              ZipCode: zipCode,
              SortBy:
                values.class === CLASS.RESIDENTIAL && isEnerexStatePresent
                  ? commodity === COMMODITY.ELECTRIC
                    ? `Price${
                        isEnerexStatePresentForPrice
                          ? sortPricesAsPerUsage(
                              getMonthlyUsage(
                                portalSettings.keyValue || "Annual Usage",
                                usage
                              )
                            ) + "kwh"
                          : ""
                      }`
                    : `Price${
                        isEnerexStatePresentForPrice
                          ? sortPricesAsPerUsage(
                              getMonthlyUsage(
                                portalSettings.keyValue || "Annual Usage",
                                usage
                              )
                            ) + "therm"
                          : ""
                      }`
                  : SORTING_ORDERS[0].value,
              SortDirection: SORTING_ORDERS[0].order,
              StartDate: getStartDate(),
              UsageMin: getUsage(portalSettings.keyValue || "Annual Usage", {
                class: values.class,
                usage,
              }),
            };
            if (values.class === CLASS.COMMERCIAL)
              formValues = isEnerexStatePresent
                ? {
                    ...formValues,
                    LoadZone: values.rateCode !== "All"
                      ? [values.rateCode]
                      : loadZoneRateCodes,
                  }
                : {
                    ...formValues,
                    RateClass: values.rateCode !== "All"
                      ? [values.rateCode]
                      : rateClassCodes,
                  };
            else
              formValues = isEnerexStatePresent
                ? { ...formValues, LoadZone: loadZoneRateCodes }
                : { ...formValues, RateClass: rateClassCodes };

            if (zipCode.length >= allowedZipCodeLength) {
              if (!checkTierAvailablity()) {
                setIsTierAvailable(false);
                return;
              }
              dispatch(setPriceForm(formValues));
              dispatch(getSearchPrice(formValues));
              const price =
                values.class === CLASS.RESIDENTIAL
                  ? {
                      class: values.class,
                      commodity,
                      usage,
                      zipCode,
                      referralCode,
                    }
                  : values;
              dispatch(savePrice(price));
              dispatch(setIsCustomPricing(tier === TIERS.LARGE));
              dispatch(getStateFromZip({ zipcode: zipCode }));
            }
            setIsSearchedClicked(true);
            dispatch(setIsRateCodeUpdated(false));
            analytics.sendEventWithData("Search", values);
          } else return;
        }}
      >
        {({ values, errors, touched, setFieldValue, handleSubmit }) => {
          const updateUtilities = (selectedClass, commodity, zipCode) => {
            if (
              commodity &&
              selectedClass === CLASS.COMMERCIAL &&
              zipCode.length >= allowedZipCodeLength
            )
              dispatch(
                getUtilitiesFromZip({
                  zipcode: zipCode,
                  commodityId: commodity,
                })
              ).then((res) => {
                if (isEmpty(res.payload) && tier === TIERS.LARGE) {
                  dispatch(
                    getUtilitiesForStateByZip({
                      zipcode: zipCode,
                      commodityId: commodity,
                    })
                  ).then(
                    (res) =>
                      res.payload && setFieldValue("utility", res.payload[0])
                  );
                }
                res.payload && setFieldValue("utility", res.payload[0]);
              });
          };

          const onChangeZipCode = (zipCode, isCommercial) => {
            setFieldValue("zipCode", zipCode);
            setEnteredZipCode(zipCode);
            if (zipCode.length >= allowedZipCodeLength) {
              debounce(() => {
                dispatch(getStateFromZip({ zipcode: zipCode })).then(
                  (res) =>
                    res.payload &&
                    isCommercial &&
                    updateUtilities(
                      isCommercial ? CLASS.COMMERCIAL : values.class,
                      values.commodity,
                      zipCode
                    )
                );
              });
            }
          };

          if (!isEmpty(errors) && isSearchButtonClicked) {
            setIsSearchButtonClicked(false);
          }

          return (
            <main className="py-5 container">
              <div className="widget-card">
                <Card>
                  <Card.Header>
                    <h5 className="mb-0">Get Prices</h5>
                  </Card.Header>
                  <Card.Body>
                    {hasNoRecords && (dealId || owaydid || opportunityId) && (
                      <Alert variant="danger" show={hasNoRecords}>
                        {`Unable to find record for ${
                          dealId || owaydid ? "Deal Id" : "Opportunity Id"
                        }`}
                      </Alert>
                    )}
                    {!isTierAvailable && (
                      <Alert
                        variant="danger"
                        show={!isTierAvailable}
                        dismissible
                        onClose={() => setIsTierAvailable(true)}
                      >
                        {portalSettings.messageForCustomPricing ||
                          `We don’t have any prices to show you at the moment, please try again later. Thanks`}
                      </Alert>
                    )}
                    {noRateCodesPresent && (
                      <Alert
                        variant="danger"
                        show={noRateCodesPresent}
                        onClose={() => setNoRateCodesPresent(false)}
                      >
                        <Row>
                          <Col md={10}>
                            No Prices Available
                          </Col>
                          <Col md={2}>
                            <Button variant="outline" onClick={() => setNoRateCodesPresent(false)} className="py-0"><img className="p-0 mb-1" src={cross}/></Button>
                          </Col>
                        </Row>
                      </Alert>
                    )}
                    <Row className="mb-3">
                      {shouldShowClassSelection && (
                        <Col
                          xs={5}
                          sm={5}
                          lg={5}
                          className="d-flex justify-content-center"
                        >
                          <div className="d-inline-flex flex-column text-center mr-3">
                            <ButtonGroup
                              className={classNames("d-inline-flex mb-1", {
                                error: errors.class && touched.class,
                              })}
                            >
                              {parameters === "display:residential" && (
                                <Form.Label className="mb-0 radio-group-item">
                                  <Form.Control
                                    type="radio"
                                    name="className"
                                    className="d-none"
                                    value={CLASS.RESIDENTIAL}
                                  />
                                  <Overlay content={CLASS.RESIDENTIAL}>
                                    <Button
                                      variant={
                                        values.class === CLASS.RESIDENTIAL
                                          ? "primary"
                                          : "outline-primary"
                                      }
                                      className="tab-btn btn-custom-left"
                                      onClick={() => {
                                        dispatch(setIsRateCodeUpdated(false));
                                        priceDetails.state &&
                                          dispatch(
                                            getRateCodes({
                                              filters: {
                                                LDCIdentifiers: [],
                                                Commodity:
                                                  COMMODITIES_LIST[
                                                    values.commodity
                                                  ],
                                                StateId: priceDetails.state.id,
                                                StateName:
                                                  priceDetails.state.name,
                                                ZipCode: values.zipCode,
                                                BrokerId: brokerageId,
                                                RateClassification:
                                                  CLASS.RESIDENTIAL,
                                              },
                                            })
                                          );
                                        setNoRateCodesPresent(false);
                                        setSelectedClass(CLASS.RESIDENTIAL);
                                        setFieldValue(
                                          "class",
                                          CLASS.RESIDENTIAL
                                        );
                                        dispatch(setTier(TIERS.SMALL));
                                      }}
                                    >
                                      <i className="fa fa-home fa-lg"></i>
                                    </Button>
                                  </Overlay>
                                </Form.Label>
                              )}
                              {parameters === "display:commercial" && (
                                <Form.Label className="mb-0 radio-group-item">
                                  <Form.Control
                                    type="radio"
                                    name="className"
                                    className="d-none"
                                    value={CLASS.COMMERCIAL}
                                  />
                                  <Overlay content={CLASS.COMMERCIAL}>
                                    <Button
                                      variant={
                                        values.class === CLASS.COMMERCIAL
                                          ? "primary"
                                          : "outline-primary"
                                      }
                                      className="tab-btn btn-custom-right"
                                      onClick={() => {
                                        dispatch(setIsRateCodeUpdated(false));
                                        //handleTier(values.usage, CLASS.COMMERCIAL)
                                        // updateUtilities(CLASS.COMMERCIAL,values.commodity,values.zipCode)
                                        setNoRateCodesPresent(false);
                                        setSelectedClass(CLASS.COMMERCIAL);
                                        setFieldValue(
                                          "class",
                                          CLASS.COMMERCIAL
                                        );
                                        onChangeZipCode(values.zipCode, true);
                                      }}
                                    >
                                      <i className="fa fa-building fa-lg"></i>
                                    </Button>
                                  </Overlay>
                                </Form.Label>
                              )}
                            </ButtonGroup>
                            <ButtonGroup
                              className={classNames("d-inline-flex mb-1", {
                                error: errors.class && touched.class,
                              })}
                            >
                              {!parameters && (
                                <Form.Label className="mb-0 radio-group-item">
                                  <Form.Control
                                    type="radio"
                                    name="className"
                                    className="d-none"
                                    value={CLASS.RESIDENTIAL}
                                  />
                                  <Overlay content={CLASS.RESIDENTIAL}>
                                    <Button
                                      variant={
                                        values.class === CLASS.RESIDENTIAL
                                          ? "primary"
                                          : "outline-primary"
                                      }
                                      className="tab-btn btn-custom-left"
                                      onClick={() => {
                                        dispatch(setIsRateCodeUpdated(false));
                                        priceDetails.state &&
                                          dispatch(
                                            getRateCodes({
                                              filters: {
                                                LDCIdentifiers: [],
                                                Commodity:
                                                  COMMODITIES_LIST[
                                                    values.commodity
                                                  ],
                                                StateId: priceDetails.state.id,
                                                StateName:
                                                  priceDetails.state.name,
                                                ZipCode: values.zipCode,
                                                BrokerId: brokerageId,
                                                RateClassification:
                                                  CLASS.RESIDENTIAL,
                                              },
                                            })
                                          );
                                        setNoRateCodesPresent(false);
                                        setSelectedClass(CLASS.RESIDENTIAL);
                                        setFieldValue(
                                          "class",
                                          CLASS.RESIDENTIAL
                                        );
                                        dispatch(setTier(TIERS.SMALL));
                                      }}
                                    >
                                      <i className="fa fa-home fa-lg"></i>
                                    </Button>
                                  </Overlay>
                                </Form.Label>
                              )}
                              {!parameters && (
                                <Form.Label className="mb-0 radio-group-item">
                                  <Form.Control
                                    type="radio"
                                    name="className"
                                    className="d-none"
                                    value={CLASS.COMMERCIAL}
                                  />
                                  <Overlay content={CLASS.COMMERCIAL}>
                                    <Button
                                      variant={
                                        values.class === CLASS.COMMERCIAL
                                          ? "primary"
                                          : "outline-primary"
                                      }
                                      className="tab-btn btn-custom-right"
                                      onClick={() => {
                                        dispatch(setIsRateCodeUpdated(false));
                                        //handleTier(values.usage, CLASS.COMMERCIAL)
                                        // updateUtilities(CLASS.COMMERCIAL,values.commodity,values.zipCode)
                                        setNoRateCodesPresent(false);
                                        setSelectedClass(CLASS.COMMERCIAL);
                                        setFieldValue(
                                          "class",
                                          CLASS.COMMERCIAL
                                        );
                                        onChangeZipCode(values.zipCode, true);
                                      }}
                                    >
                                      <i className="fa fa-building fa-lg"></i>
                                    </Button>
                                  </Overlay>
                                </Form.Label>
                              )}
                            </ButtonGroup>
                            <Form.Label className="f-14 text-muted mb-0">
                              Class
                            </Form.Label>
                            {errors.class && touched.class && (
                              <div className="error-message">
                                {errors.class}
                              </div>
                            )}
                          </div>
                        </Col>
                      )}
                      {shouldShowCommoditySelection && (
                        <Col
                          xs={5}
                          sm={5}
                          lg={5}
                          className="d-flex justify-content-center ml-auto"
                        >
                          <div className="d-inline-flex flex-column text-center">
                            <ButtonGroup
                              className={classNames("d-inline-flex mb-1", {
                                error: errors.commodity && touched.commodity,
                              })}
                            >
                              <Form.Label className="mb-0 radio-group-item">
                                <Form.Control
                                  type="radio"
                                  name="commodityName"
                                  className="d-none"
                                  value={COMMODITY.ELECTRIC}
                                />
                                <Overlay content="Electricity">
                                  <Button
                                    variant={
                                      selectedCommodity === COMMODITY.ELECTRIC
                                        ? "primary"
                                        : "outline-primary"
                                    }
                                    className="tab-btn btn-custom-left"
                                    onClick={() => {
                                      setFieldValue(
                                        "commodity",
                                        COMMODITY.ELECTRIC
                                      );
                                      setSelectedCommodity(COMMODITY.ELECTRIC);
                                      setNoRateCodesPresent(false);
                                      !isInvalidZipcode &&
                                        updateUtilities(
                                          values.class,
                                          COMMODITY.ELECTRIC,
                                          values.zipCode
                                        );
                                    }}
                                  >
                                    <i className="fa fa-bolt fa-lg"></i>
                                  </Button>
                                </Overlay>
                              </Form.Label>
                              <Form.Label className="mb-0 radio-group-item">
                                <Form.Control
                                  type="radio"
                                  name="commodityName"
                                  className="d-none"
                                  value={COMMODITY.NAT_GAS}
                                />
                                <Overlay content="Gas">
                                  <Button
                                    variant={
                                      selectedCommodity === COMMODITY.NAT_GAS
                                        ? "primary"
                                        : "outline-primary"
                                    }
                                    className="tab-btn btn-custom-right"
                                    onClick={() => {
                                      setFieldValue(
                                        "commodity",
                                        COMMODITY.NAT_GAS
                                      );
                                      setSelectedCommodity(COMMODITY.NAT_GAS);
                                      setNoRateCodesPresent(false);
                                      !isInvalidZipcode &&
                                        updateUtilities(
                                          values.class,
                                          COMMODITY.NAT_GAS,
                                          values.zipCode
                                        );
                                    }}
                                  >
                                    <i className="fa fa-fire fa-lg"></i>
                                  </Button>
                                </Overlay>
                              </Form.Label>
                            </ButtonGroup>
                            <Form.Label className="f-14 text-muted">
                              Commodity
                            </Form.Label>
                            {errors.commodity && touched.commodity && (
                              <div className="error-message">
                                {errors.commodity}
                              </div>
                            )}
                          </div>
                        </Col>
                      )}
                    </Row>
                    {showError && (
                      <Alert
                        variant="danger"
                        onClose={() => setShowError(false)}
                        dismissible
                      >
                        We cannot get you prices at the moment please contact us
                        at xxxxxxx.
                      </Alert>
                    )}
                    <Form.Group>
                      {portalSettings.showUsagesResi &&
                        portalSettings.showUsagesComm === false &&
                        selectedClass === CLASS.RESIDENTIAL && (
                          <Row className="mb-3">
                            <Col xs={12} sm={6}>
                              <Form.Label className="required">
                                {portalSettings.keyValueResi}
                              </Form.Label>
                            </Col>
                            <Col>
                              {annualUsage || monthlyBill ? (
                                <div className="input-group">
                                  {portalSettings.keyValueResi ===
                                    PORTAL_SETTINGS.MONTHLY_BILL && (
                                    <InputGroup.Text className="input-group-text-left">
                                      {dollarSign}
                                    </InputGroup.Text>
                                  )}
                                  <NumberFormat
                                    type="text"
                                    className={classNames(
                                      "f-14 form-control text-right",
                                      {
                                        error: errors.usage && touched.usage,
                                      }
                                    )}
                                    value={values.usage}
                                    onChange={(e) => {
                                      setFieldValue(
                                        "usage",
                                        getFormattedUsage(e.target.value)
                                      );
                                    }}
                                    onBlur={() =>
                                      handleTier(values.usage, values.class)
                                    }
                                    maxLength={16}
                                    thousandSeparator={true}
                                    allowNegative={false}
                                  />
                                  {portalSettings.keyValueResi !==
                                    PORTAL_SETTINGS.MONTHLY_BILL && (
                                    <InputGroup.Text className="input-group-text-right">
                                      {values.commodity === COMMODITY.NAT_GAS
                                        ? TH
                                        : KWH}
                                    </InputGroup.Text>
                                  )}
                                </div>
                              ) : (
                                <div className="input-group">
                                  {portalSettings.keyValueResi ===
                                    PORTAL_SETTINGS.MONTHLY_BILL && (
                                    <InputGroup.Text className="input-group-text-left">
                                      {dollarSign}
                                    </InputGroup.Text>
                                  )}
                                  <NumberFormat
                                    type="text"
                                    className={classNames(
                                      "f-14 form-control text-right",
                                      {
                                        error: errors.usage && touched.usage,
                                      }
                                    )}
                                    value={values.usage}
                                    onChange={(e) => {
                                      setFieldValue(
                                        "usage",
                                        getFormattedUsage(e.target.value)
                                      );
                                    }}
                                    onBlur={() =>
                                      handleTier(values.usage, values.class)
                                    }
                                    maxLength={16}
                                    thousandSeparator={true}
                                    allowNegative={false}
                                  />
                                  {portalSettings.keyValueResi !==
                                    PORTAL_SETTINGS.MONTHLY_BILL && (
                                    <InputGroup.Text className="input-group-text-right">
                                      {values.commodity === COMMODITY.NAT_GAS
                                        ? TH
                                        : KWH}
                                    </InputGroup.Text>
                                  )}
                                </div>
                              )}
                              {errors.usage && touched.usage && (
                                <div className="error-message mt-1">
                                  {errors.usage}
                                </div>
                              )}
                            </Col>
                          </Row>
                        )}
                      {portalSettings.showUsagesComm &&
                        portalSettings.showUsagesResi === false &&
                        selectedClass === CLASS.COMMERCIAL && (
                          <Row className="mb-3" n>
                            <Col xs={12} sm={6}>
                              <Form.Label className="required">
                                {portalSettings.keyValueComm}
                              </Form.Label>
                            </Col>
                            <Col>
                              {annualUsage || monthlyBill ? (
                                <div className="input-group">
                                  {portalSettings.keyValueComm ===
                                    PORTAL_SETTINGS.MONTHLY_BILL && (
                                    <InputGroup.Text className="input-group-text-left">
                                      {dollarSign}
                                    </InputGroup.Text>
                                  )}
                                  <NumberFormat
                                    type="text"
                                    className={classNames(
                                      "f-14 form-control text-right",
                                      {
                                        error: errors.usage && touched.usage,
                                      }
                                    )}
                                    value={values.usage}
                                    onChange={(e) => {
                                      setFieldValue(
                                        "usage",
                                        getFormattedUsage(e.target.value)
                                      );
                                    }}
                                    onBlur={() =>
                                      handleTier(values.usage, values.class)
                                    }
                                    maxLength={16}
                                    thousandSeparator={true}
                                    allowNegative={false}
                                  />
                                  {portalSettings.keyValueComm !==
                                    PORTAL_SETTINGS.MONTHLY_BILL && (
                                    <InputGroup.Text className="input-group-text-right">
                                      {values.commodity === COMMODITY.NAT_GAS
                                        ? TH
                                        : KWH}
                                    </InputGroup.Text>
                                  )}
                                </div>
                              ) : (
                                <div className="input-group">
                                  {portalSettings.keyValueComm ===
                                    PORTAL_SETTINGS.MONTHLY_BILL && (
                                    <InputGroup.Text className="input-group-text-left">
                                      {dollarSign}
                                    </InputGroup.Text>
                                  )}
                                  <NumberFormat
                                    type="text"
                                    className={classNames(
                                      "f-14 form-control text-right",
                                      {
                                        error: errors.usage && touched.usage,
                                      }
                                    )}
                                    value={values.usage}
                                    onChange={(e) => {
                                      setFieldValue(
                                        "usage",
                                        getFormattedUsage(e.target.value)
                                      );
                                    }}
                                    onBlur={() =>
                                      handleTier(values.usage, values.class)
                                    }
                                    maxLength={16}
                                    thousandSeparator={true}
                                    allowNegative={false}
                                  />
                                  {portalSettings.keyValueComm !==
                                    PORTAL_SETTINGS.MONTHLY_BILL && (
                                    <InputGroup.Text className="input-group-text-right">
                                      {values.commodity === COMMODITY.NAT_GAS
                                        ? TH
                                        : KWH}
                                    </InputGroup.Text>
                                  )}
                                </div>
                              )}
                              {errors.usage && touched.usage && (
                                <div className="error-message mt-1">
                                  {errors.usage}
                                </div>
                              )}
                            </Col>
                          </Row>
                        )}
                      {portalSettings.showUsagesResi === true &&
                        portalSettings.showUsagesComm === true &&
                        portalSettings.keyValueComm ===
                          portalSettings.keyValueResi && (
                          <Row className="mb-3">
                            <Col xs={12} sm={6}>
                              <Form.Label className="required">
                                {portalSettings.keyValueResi}
                              </Form.Label>
                            </Col>
                            <Col>
                              {annualUsage || monthlyBill ? (
                                <div className="input-group">
                                  {portalSettings.keyValueResi ===
                                    PORTAL_SETTINGS.MONTHLY_BILL && (
                                    <InputGroup.Text className="input-group-text-left">
                                      {dollarSign}
                                    </InputGroup.Text>
                                  )}
                                  <NumberFormat
                                    type="text"
                                    className={classNames(
                                      "f-14 form-control text-right",
                                      {
                                        error: errors.usage && touched.usage,
                                      }
                                    )}
                                    value={values.usage}
                                    onChange={(e) => {
                                      setFieldValue(
                                        "usage",
                                        getFormattedUsage(e.target.value)
                                      );
                                    }}
                                    onBlur={() =>
                                      handleTier(values.usage, values.class)
                                    }
                                    maxLength={16}
                                    thousandSeparator={true}
                                    allowNegative={false}
                                  />
                                  {portalSettings.keyValueResi !==
                                    PORTAL_SETTINGS.MONTHLY_BILL && (
                                    <InputGroup.Text className="input-group-text-right">
                                      {values.commodity === COMMODITY.NAT_GAS
                                        ? TH
                                        : KWH}
                                    </InputGroup.Text>
                                  )}
                                </div>
                              ) : (
                                <div className="input-group">
                                  {portalSettings.keyValueResi ===
                                    PORTAL_SETTINGS.MONTHLY_BILL && (
                                    <InputGroup.Text className="input-group-text-left">
                                      {dollarSign}
                                    </InputGroup.Text>
                                  )}
                                  <NumberFormat
                                    type="text"
                                    className={classNames(
                                      "f-14 form-control text-right",
                                      {
                                        error: errors.usage && touched.usage,
                                      }
                                    )}
                                    value={values.usage}
                                    onChange={(e) => {
                                      setFieldValue(
                                        "usage",
                                        getFormattedUsage(e.target.value)
                                      );
                                    }}
                                    onBlur={() =>
                                      handleTier(values.usage, values.class)
                                    }
                                    maxLength={16}
                                    thousandSeparator={true}
                                    allowNegative={false}
                                  />
                                  {portalSettings.keyValueResi !==
                                    PORTAL_SETTINGS.MONTHLY_BILL && (
                                    <InputGroup.Text className="input-group-text-right">
                                      {values.commodity === COMMODITY.NAT_GAS
                                        ? TH
                                        : KWH}
                                    </InputGroup.Text>
                                  )}
                                </div>
                              )}
                              {errors.usage && touched.usage && (
                                <div className="error-message mt-1">
                                  {errors.usage}
                                </div>
                              )}
                            </Col>
                          </Row>
                        )}
                      {portalSettings.showUsagesResi === true &&
                        portalSettings.showUsagesComm === true &&
                        portalSettings.keyValueComm !==
                          portalSettings.keyValueResi && (
                          <Row className="mb-3">
                            <Col xs={12} sm={6}>
                              {selectedClass !== CLASS.RESIDENTIAL &&
                                selectedClass !== CLASS.COMMERCIAL && (
                                  <Form.Label className="required">
                                    {portalSettings.keyValue}
                                  </Form.Label>
                                )}
                              {selectedClass === CLASS.RESIDENTIAL && (
                                <Form.Label className="required">
                                  {portalSettings.keyValueResi}
                                </Form.Label>
                              )}
                              {selectedClass === CLASS.COMMERCIAL && (
                                <Form.Label className="required">
                                  {portalSettings.keyValueComm}
                                </Form.Label>
                              )}
                            </Col>
                            {selectedClass === CLASS.RESIDENTIAL ? (
                              <Col>
                                {annualUsage || monthlyBill ? (
                                  <div className="input-group">
                                    {portalSettings.keyValueResi ===
                                      PORTAL_SETTINGS.MONTHLY_BILL && (
                                      <InputGroup.Text className="input-group-text-left">
                                        {dollarSign}
                                      </InputGroup.Text>
                                    )}
                                    <NumberFormat
                                      type="text"
                                      className={classNames(
                                        "f-14 form-control text-right",
                                        {
                                          error: errors.usage && touched.usage,
                                        }
                                      )}
                                      value={values.usage}
                                      onChange={(e) => {
                                        setFieldValue(
                                          "usage",
                                          getFormattedUsage(e.target.value)
                                        );
                                      }}
                                      onBlur={() =>
                                        handleTier(values.usage, values.class)
                                      }
                                      maxLength={16}
                                      thousandSeparator={true}
                                      allowNegative={false}
                                    />
                                    {portalSettings.keyValueResi !==
                                      PORTAL_SETTINGS.MONTHLY_BILL && (
                                      <InputGroup.Text className="input-group-text-right">
                                        {values.commodity === COMMODITY.NAT_GAS
                                          ? TH
                                          : KWH}
                                      </InputGroup.Text>
                                    )}
                                  </div>
                                ) : (
                                  <div className="input-group">
                                    {portalSettings.keyValueResi ===
                                      PORTAL_SETTINGS.MONTHLY_BILL && (
                                      <InputGroup.Text className="input-group-text-left">
                                        {dollarSign}
                                      </InputGroup.Text>
                                    )}
                                    <NumberFormat
                                      type="text"
                                      className={classNames(
                                        "f-14 form-control text-right",
                                        {
                                          error: errors.usage && touched.usage,
                                        }
                                      )}
                                      value={values.usage}
                                      onChange={(e) => {
                                        setFieldValue(
                                          "usage",
                                          getFormattedUsage(e.target.value)
                                        );
                                      }}
                                      onBlur={() =>
                                        handleTier(values.usage, values.class)
                                      }
                                      maxLength={16}
                                      thousandSeparator={true}
                                      allowNegative={false}
                                    />
                                    {portalSettings.keyValueResi !==
                                      PORTAL_SETTINGS.MONTHLY_BILL && (
                                      <InputGroup.Text className="input-group-text-right">
                                        {values.commodity === COMMODITY.NAT_GAS
                                          ? TH
                                          : KWH}
                                      </InputGroup.Text>
                                    )}
                                  </div>
                                )}
                                {errors.usage && touched.usage && (
                                  <div className="error-message mt-1">
                                    {errors.usage}
                                  </div>
                                )}
                              </Col>
                            ) : selectedClass === CLASS.COMMERCIAL ? (
                              <Col>
                                {annualUsage || monthlyBill ? (
                                  <div className="input-group">
                                    {portalSettings.keyValueComm ===
                                      PORTAL_SETTINGS.MONTHLY_BILL && (
                                      <InputGroup.Text className="input-group-text-left">
                                        {dollarSign}
                                      </InputGroup.Text>
                                    )}
                                    <NumberFormat
                                      type="text"
                                      className={classNames(
                                        "f-14 form-control text-right",
                                        {
                                          error: errors.usage && touched.usage,
                                        }
                                      )}
                                      value={values.usage}
                                      onChange={(e) => {
                                        setFieldValue(
                                          "usage",
                                          getFormattedUsage(e.target.value)
                                        );
                                      }}
                                      onBlur={() =>
                                        handleTier(values.usage, values.class)
                                      }
                                      maxLength={16}
                                      thousandSeparator={true}
                                      allowNegative={false}
                                    />
                                    {portalSettings.keyValueComm !==
                                      PORTAL_SETTINGS.MONTHLY_BILL && (
                                      <InputGroup.Text className="input-group-text-right">
                                        {values.commodity === COMMODITY.NAT_GAS
                                          ? TH
                                          : KWH}
                                      </InputGroup.Text>
                                    )}
                                  </div>
                                ) : (
                                  <div className="input-group">
                                    {portalSettings.keyValueComm ===
                                      PORTAL_SETTINGS.MONTHLY_BILL && (
                                      <InputGroup.Text className="input-group-text-left">
                                        {dollarSign}
                                      </InputGroup.Text>
                                    )}
                                    <NumberFormat
                                      type="text"
                                      className={classNames(
                                        "f-14 form-control text-right",
                                        {
                                          error: errors.usage && touched.usage,
                                        }
                                      )}
                                      value={values.usage}
                                      onChange={(e) => {
                                        setFieldValue(
                                          "usage",
                                          getFormattedUsage(e.target.value)
                                        );
                                      }}
                                      onBlur={() =>
                                        handleTier(values.usage, values.class)
                                      }
                                      maxLength={16}
                                      thousandSeparator={true}
                                      allowNegative={false}
                                    />
                                    {portalSettings.keyValueComm !==
                                      PORTAL_SETTINGS.MONTHLY_BILL && (
                                      <InputGroup.Text className="input-group-text-right">
                                        {values.commodity === COMMODITY.NAT_GAS
                                          ? TH
                                          : KWH}
                                      </InputGroup.Text>
                                    )}
                                  </div>
                                )}
                                {errors.usage && touched.usage && (
                                  <div className="error-message mt-1">
                                    {errors.usage}
                                  </div>
                                )}
                              </Col>
                            ) : (
                              <Col>
                                {annualUsage || monthlyBill ? (
                                  <div className="input-group">
                                    {portalSettings.keyValue ===
                                      PORTAL_SETTINGS.MONTHLY_BILL && (
                                      <InputGroup.Text className="input-group-text-left">
                                        {dollarSign}
                                      </InputGroup.Text>
                                    )}
                                    <NumberFormat
                                      type="text"
                                      className={classNames(
                                        "f-14 form-control text-right",
                                        {
                                          error: errors.usage && touched.usage,
                                        }
                                      )}
                                      value={values.usage}
                                      onChange={(e) => {
                                        setFieldValue(
                                          "usage",
                                          getFormattedUsage(e.target.value)
                                        );
                                      }}
                                      onBlur={() =>
                                        handleTier(values.usage, values.class)
                                      }
                                      maxLength={16}
                                      thousandSeparator={true}
                                      allowNegative={false}
                                    />
                                    {portalSettings.keyValue !==
                                      PORTAL_SETTINGS.MONTHLY_BILL && (
                                      <InputGroup.Text className="input-group-text-right">
                                        {values.commodity === COMMODITY.NAT_GAS
                                          ? TH
                                          : KWH}
                                      </InputGroup.Text>
                                    )}
                                  </div>
                                ) : (
                                  <div className="input-group">
                                    {portalSettings.keyValue ===
                                      PORTAL_SETTINGS.MONTHLY_BILL && (
                                      <InputGroup.Text className="input-group-text-left">
                                        {dollarSign}
                                      </InputGroup.Text>
                                    )}
                                    <NumberFormat
                                      type="text"
                                      className={classNames(
                                        "f-14 form-control text-right",
                                        {
                                          error: errors.usage && touched.usage,
                                        }
                                      )}
                                      value={values.usage}
                                      onChange={(e) => {
                                        setFieldValue(
                                          "usage",
                                          getFormattedUsage(e.target.value)
                                        );
                                      }}
                                      onBlur={() =>
                                        handleTier(values.usage, values.class)
                                      }
                                      maxLength={16}
                                      thousandSeparator={true}
                                      allowNegative={false}
                                    />
                                    {portalSettings.keyValue !==
                                      PORTAL_SETTINGS.MONTHLY_BILL && (
                                      <InputGroup.Text className="input-group-text-right">
                                        {values.commodity === COMMODITY.NAT_GAS
                                          ? TH
                                          : KWH}
                                      </InputGroup.Text>
                                    )}
                                  </div>
                                )}
                                {errors.usage && touched.usage && (
                                  <div className="error-message mt-1">
                                    {errors.usage}
                                  </div>
                                )}
                              </Col>
                            )}
                          </Row>
                        )}
                    </Form.Group>
                    <Form.Group>
                      <Row className="mb-3">
                        <Col xs={12} sm={6}>
                          <Form.Label className="required">Zip Code</Form.Label>
                        </Col>
                        <Col>
                          {zc ? (
                            <NumberFormat
                              type="text"
                              allowLeadingZeros={true}
                              className={classNames(
                                "f-14 form-control text-right",
                                {
                                  error: errors.zipCode && touched.zipCode,
                                }
                              )}
                              value={values.zipCode.toString()}
                              onChange={(e) => {
                                setFieldValue("zipCode", e.target.value);
                                onChangeZipCode(
                                  e.target.value,
                                  values.class === CLASS.COMMERCIAL
                                );
                              }}
                              onKeyDown={(e) =>
                                e.key === "Enter" ? handleSubmit() : null
                              }
                              onBlur={() =>
                                handleTier(values.usage, values.class)
                              }
                              maxLength={100}
                              allowNegative={false}
                            />
                          ) : (
                            <NumberFormat
                              type="text"
                              allowLeadingZeros={true}
                              className={classNames(
                                "f-14 form-control text-right",
                                {
                                  error:
                                    (errors.zipCode && touched.zipCode) ||
                                    isInvalidZipcode,
                                }
                              )}
                              value={values.zipCode}
                              onChange={(e) => {
                                setIsSearchedClicked(false);
                                setFieldValue("zipCode", e.target.value);
                                onChangeZipCode(
                                  e.target.value,
                                  values.class === CLASS.COMMERCIAL
                                );
                              }}
                              onKeyDown={(e) =>
                                e.key === "Enter" ? handleSubmit() : null
                              }
                              onBlur={() =>
                                handleTier(values.usage, values.class)
                              }
                              maxLength={100}
                              allowNegative={false}
                            />
                          )}
                          {((errors.zipCode && touched.zipCode) ||
                            isInvalidZipcode) && (
                            <div className="error-message mt-1">
                              {errors.zipCode || "Invalid Zip Code."}
                            </div>
                          )}
                        </Col>
                      </Row>
                    </Form.Group>
                    {portalSettings.showReferralCode && (
                      <Form.Group>
                        <Row className="mb-3">
                          <Col xs={12} sm={6}>
                            <Form.Label>Referral Code</Form.Label>
                          </Col>
                          <Col>
                            <Form.Control
                              id="rcID"
                              type="text"
                              className={classNames(
                                "f-14 form-control text-right",
                                {
                                  error:
                                    errors.referralCode && touched.referralCode,
                                }
                              )}
                              value={values.referralCode}
                              onChange={(e) => {
                                setFieldValue("referralCode", e.target.value);
                                dispatch(validateReferralCode(e.target.value));
                              }}
                              // onBlur={(e) =>
                              //   dispatch(validateReferralCode(values.referralCode))
                              // }
                              //disabled={!!rc}
                              readOnly={isReferralCodeValid}
                              maxLength={100}
                            />

                            {isReferralCodeValid === false
                              ? errors.referralCode &&
                                touched.referralCode && (
                                  <div className="error-message mt-1">
                                    {errors.referralCode}
                                  </div>
                                )
                              : document.getElementById("rcID")
                              ? (document
                                  .getElementById("rcID")
                                  .setAttribute(
                                    "class",
                                    "f-14 form-control text-right"
                                  ),
                                document.getElementById("rcID") && owayrc
                                  ? document
                                      .getElementById("rcID")
                                      .setAttribute("readonly", "readonly")
                                  : false)
                              : false}
                          </Col>
                        </Row>
                      </Form.Group>
                    )}
                    <BusyIndicator
                      region={
                        values.class === CLASS.RESIDENTIAL
                          ? REGIONS.GET_RATE_CODES
                          : REGIONS.GET_UTILITIES
                      }
                    >
                      {(values.class === CLASS.COMMERCIAL ||
                        selectedClass === CLASS.COMMERCIAL) &&
                      tier !== TIERS.LARGE &&
                      (!isEmpty(utilities) ||
                        (isEmptyUtility && tier !== TIERS.MID)) &&
                      !isInvalidZipcode ? (
                        <div className="mx-n1">
                          <Col className="px-1 mb-1">
                            <Form.Group>
                              <Form.Label>Utility</Form.Label>
                              <Form.Control
                                as="select"
                                className={classNames("f-14", {
                                  error:
                                    errors.utility &&
                                    (isEmptyUtility || touched.utility),
                                })}
                                values={values.utility}
                                onChange={(e) => {
                                  const utility = JSON.parse(e.target.value);
                                  dispatch(
                                    getRateCodes({
                                      filters: {
                                        LDCIdentifiers: [utility.LDCIdentifier],
                                        Commodity:
                                          COMMODITIES_LIST[values.commodity],
                                        StateId: priceDetails.state.id,
                                        StateName: priceDetails.state.name,
                                        ZipCode: values.zipCode,
                                        BrokerId: brokerageId,
                                        RateClassification: values.class,
                                      },
                                    })
                                  );
                                  setNoRateCodesPresent(false);
                                  setFieldValue("utility", utility);
                                }}
                              >
                                {utilities &&
                                  utilities.length > 0 &&
                                  utilities.map((utility, index) => (
                                    <option
                                      key={index}
                                      value={JSON.stringify(utility)}
                                    >
                                      {utility.LDCName}
                                    </option>
                                  ))}
                              </Form.Control>
                            </Form.Group>
                            {errors.utility &&
                              (isEmptyUtility || touched.utility) && (
                                <div className="error-message mt-1">
                                  {errors.utility}
                                </div>
                              )}
                          </Col>
                          <Col className="px-1">
                            {utilities.length > 0 && (
                              <Form.Group>
                                <Form.Label>
                                  {isEnerexStatePresent
                                    ? "Load Profile"
                                    : "Rate Code"}
                                </Form.Label>
                                <Form.Control
                                  as="select"
                                  className={classNames("f-14", {
                                    error: errors.rateCode && touched.rateCode,
                                  })}
                                  onChange={(e) =>
                                    setFieldValue("rateCode", e.target.value)
                                  }
                                  value={values.rateCode}
                                >
                                  {/* // need to update  key  for  placeholder */}
                                  {(updatedRateCodes.length > 1 ||
                                    updatedRateCodes.length === 0) && (
                                    <option value="All">All</option>
                                  )}
                                  {updatedRateCodes.map((rateCode) => (
                                    <option
                                      key={rateCode.id}
                                      value={
                                        isEnerexStatePresent
                                          ? rateCode.LoadProfile
                                          : rateCode.RateClass
                                      }
                                    >
                                      {rateCode.displayName}
                                    </option>
                                  ))}
                                </Form.Control>
                              </Form.Group>
                            )}
                            {errors.rateCode && touched.rateCode && (
                              <div className="error-message mt-1">
                                {errors.rateCode}
                              </div>
                            )}
                          </Col>
                        </div>
                      ) : (
                        emptyString
                      )}
                    </BusyIndicator>
                  </Card.Body>

                  <Card.Footer className="text-center">
                    <Button
                      className="btn btn-primary f-14"
                      type="submit"
                      onClick={handleSubmit}
                    >
                      Search
                    </Button>
                  </Card.Footer>
                </Card>
              </div>
            </main>
          );
        }}
      </Formik>
    );

  return null;
};

export default React.memo(GetPrice);
