import React, { useEffect, useState } from "react";
import { Row, Col, Button, Card, Alert, Form } from "react-bootstrap";
import Stepper from "./Stepper";
import DocumentTable from "./DocumentTable";
import UploadDocuments from "./UploadDocuments";
import SupplierCard from "./SupplierCard";
import {
  selectSaveStatus,
  selectSteps,
} from "./priceAndCustomerDetails.selectors";
import { useSelector } from "react-redux";
import BusyIndicator, { REGIONS } from "../../widgets/busyIndicator";
import {
  selectShouldShowRFQDetails,
  selectTier,
  TIERS,
  selectPrice,
  selectPortalSetting,
} from "../GetPrice";
import handleCloseWidget from "../../helper/handleCloseWidget";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import LargeTierCard from "./LargeTierCard";
import parse from "html-react-parser";
import analytics from "../../helper/analytics";
import { selectClientConfig } from "../clientContext";
import classNames from "classnames";
import CustomComponent from "../../widgets/CustomComponent";
import {
  selectEnrollmentFields,
  selectFieldsResult,
  selectSelectedSupplier,
} from "./priceAndCustomerDetails.selectors";

const Review = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const steps = useSelector(selectSteps);
  const saveStatus = useSelector(selectSaveStatus);
  const shouldShowRFQDetails = useSelector(selectShouldShowRFQDetails);
  const tier = useSelector(selectTier);
  const clientConfig = useSelector(selectClientConfig);
  const { isFullScreen } = clientConfig;
  const portalSettings = useSelector(selectPortalSetting);
  const [hasErrorOnUpload, setHasErrorOnUpload] = useState(false);

  const [data, setData] = useState({});
  const [moveType, setMoveType] = useState("");
  const price = useSelector(selectPrice);
  const [constelDayValidate, setConstelDayValidate] = useState(false);
  const fieldsResult = useSelector(selectFieldsResult);
  const selectedSupplier = useSelector(selectSelectedSupplier);
  const { Zone } = selectedSupplier;

  const fields = JSON.parse(
    JSON.stringify(useSelector(selectEnrollmentFields))
  );

  const getFormattedFields = () => {
    let fieldsToShow = [];
    if (fields && fields.length > 0) {
      const fieldsWithAffirmation = fields.filter((each) =>
        each.friendlyName?.includes("Deposit")
      );
      fieldsToShow = [...fieldsWithAffirmation];
    } else fieldsToShow = [...fields];
    if (selectedSupplier.Disclaimer) {
      const disclaimerField = {
        name: "Disclaimer",
        dataType: "Info",
        friendlyName: selectedSupplier.Disclaimer,
        type: "field",
        required: true,
      };
      return [...fieldsToShow, disclaimerField];
    } else return fieldsToShow;
  };

  useEffect(() => {
    analytics.sendEventWithData("Review", {
      message: JSON.stringify(saveStatus),
    });
  }, [saveStatus]);

  const showMessage = (cssClass) => {
    const div = document.createElement("div");
    div.className = `alert alert-${cssClass} mt-2`;
    div.appendChild(
      document.createTextNode(
        "Please verify your date of birth , you must be over 18 years old"
      )
    );
    const container = document.querySelector("#container");
    const app = document.querySelector("#app");
    container.insertBefore(div, app);

    const time = setTimeout(function () {
      document.querySelector(".alert").remove();
    }, 5000);

    return time;
  };

  const onDropHandle = (uploadedFiles, acceptedFiles, setFieldValue) => {
    setHasErrorOnUpload(false);
    const uploads = [];
    acceptedFiles &&
      acceptedFiles.forEach((file) => {
        const reader = new FileReader();
        reader.onload = (r) => {
          uploads.push({
            base64Data: r.target.result,
            fileName: file.name,
            fileType: file.type,
            size: file.size,
          });
          if (uploads.length === acceptedFiles.length) {
            const files = [...uploadedFiles, ...uploads];
            setFieldValue("files", files);
          }
        };
        reader.readAsDataURL(file);
      });
  };

  const removeFromUploadedFiles = (uploadedFiles, index, setFieldValue) => {
    const files = [...uploadedFiles];
    files.splice(index, 1);
    setFieldValue("files", files);
  };

  return (
    <div className={classNames("inner-widget", { "px-20": !isFullScreen })}>
      <Card className={classNames("p-4", { "min-h-100": isFullScreen })}>
        <div className="mb-4 py-2">
          <div className="d-flex align-items-center justify-content-between">
            {shouldShowRFQDetails && <b>Custom Pricing Details</b>}
            <div className="d-flex align-items-center justify-content-between"></div>
            <div className="d-flex align-items-center justify-content-between">
              {portalSettings.phoneWork && (
                <h6 className="sub-heading-text f-14 text-muted">
                  Need Assistance?: Call {portalSettings.phoneWork}
                </h6>
              )}
              <Button
                variant="outline-light"
                onClick={() => handleCloseWidget(dispatch, history)}
              >
                <i className="fa fa-times fa-2x f-20"></i>
              </Button>
            </div>
          </div>
        </div>
        <Row className="justify-content-center">
          <SupplierCard />
          {tier === TIERS.LARGE && <LargeTierCard />}
          <Col lg={8}>
            <div className="wizard ">
              <Stepper pages={steps} currentPath="review" />
              <BusyIndicator region={REGIONS.SAVE_ALL_INFORMATION}>
                <div className="wizard-content" data-step="step1">
                  <div className="col-md-8 mx-auto">
                    {saveStatus.errorMessages &&
                      saveStatus.errorMessages.map((each) => (
                        <Alert variant="danger" className="mt-1">
                          <p>{parse(each || "")}</p>
                        </Alert>
                      ))}
                  </div>
                </div>

                <div>
                  {" "}
                  {saveStatus.successMessages &&
                    saveStatus.successMessages.map((each) => (
                      <Alert variant="success" className="mt-1">
                        <p>{parse(each || "")}</p>
                      </Alert>
                    ))}
                  {saveStatus.successMessages.length > 0 &&
                    saveStatus.successMessages.length > 2 && (
                      <Form id="app">
                        <CustomComponent
                          fields={getFormattedFields()}
                          data={data}
                          fieldsResult={fieldsResult}
                          checked={data.field}
                          onFieldChange={(field, value) => {
                            setData((data) => ({ ...data, [field]: value }));
                            if (field === "SwitchMoveType") {
                              setMoveType(value);
                            }
                            if (
                              (field === "SwitchType" &&
                                value === "Self-Selected Switch") ||
                              (field === "SwitchSubType" &&
                                value === "Self-Selected Switch") ||
                              (field === "SwitchSubType" &&
                                value === "switchDate") ||
                              (field === "SwitchSubType" &&
                                value === "forward_switch") ||
                              (field === "SwitchSubType" &&
                                value === "SwitchMoveDate")
                            ) {
                              setConstelDayValidate(true);
                            } else if (
                              (field === "SwitchMoveType" &&
                                value === "Moving") ||
                              (field === "SwitchMoveType" &&
                                value === "Move-In") ||
                              (field === "SwitchMoveType" &&
                                value === "move") ||
                              (field === "SwitchMoveType" && value === "MoveIn")
                            ) {
                              setConstelDayValidate(false);
                            }
                          }}
                          removeKey={(keyToRemove) => {
                            delete data[keyToRemove];
                            setData(data);
                          }}
                          selectedClass={price.class}
                        />
                      </Form>
                    )}
                  {saveStatus.validationMessages &&
                    saveStatus.validationMessages.map((each) => (
                      <Alert variant="success" className="mt-1">
                        <p>{parse(each || "")}</p>
                      </Alert>
                    ))}
                </div>
              </BusyIndicator>
            </div>

            <div className="wizard-footer d-flex">
              <Button
                variant="primary"
                className="ml-auto"
                onClick={() => handleCloseWidget(dispatch, history)}
              >
                Close
              </Button>
            </div>
          </Col>
        </Row>
      </Card>
    </div>
  );
};

export default Review;
