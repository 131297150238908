import React from "react";
import { Switch, Route } from "react-router-dom";
import Page from "./widgets/Page/Page";
import GetPrice from "./features/GetPrice";
import ShowPrices from "./features/ShowPrices";
import PriceSelection from "./features/PriceSelection";
import AccountInformation from "./features/PriceAndCustomerDetails/AccountInformation";
import Info from "./features/PriceAndCustomerDetails/PriceDetails";
import Review from "./features/PriceAndCustomerDetails/Review";
import Enrollment from "./features/PriceAndCustomerDetails/Enrollment";
import Contract from "./features/PriceAndCustomerDetails/Contract";
import SupplierInfo from "./features/PriceAndCustomerDetails/SupplierInfo";
import SelectPriceType from "./features/ShowPrices/SelectPricingType"
import { useGoogleAnalytics } from "./hooks";
import ContactMe from "./features/ContactMeView/ContactMe";

export default function Routes() {
  useGoogleAnalytics();
  return (
    <Switch>
      <PageRoute path="/enrollment">
        <Enrollment />
      </PageRoute>
      <PageRoute path="/supplier-info">
        <SupplierInfo />
      </PageRoute>
      <PageRoute path="/contract">
        <Contract />
      </PageRoute>
      <PageRoute path="/review">
        <Review />
      </PageRoute>
      <PageRoute path="/info">
        <Info />
      </PageRoute>
      <PageRoute path="/accounts">
        <AccountInformation />
      </PageRoute>
      <PageRoute path="/price-selection">
        <PriceSelection />
      </PageRoute>
      <PageRoute path="/contact-me">
        <ContactMe/>
      </PageRoute>
      <PageRoute path="/show-prices">
        <ShowPrices />
      </PageRoute>
      <PageRoute path="/select-pricing">
        <SelectPriceType />
      </PageRoute>
      <PageRoute path="/">
        <GetPrice />
      </PageRoute>
    </Switch>
  );
}

function PageRoute({ children, ...rest }) {
  const currentPath = rest.path
  if (currentPath !== "/") {
    document.body.classList.add('overflow-hidden');
  } else if (currentPath === "/") {
    document.body.classList.remove('overflow-hidden');
  }
  return (
    <Route {...rest}>
      <Page path={currentPath}>{children}</Page>
    </Route>
  );
}
