import ReactGA from "react-ga4"

const init = trackingId => {
  ReactGA.initialize(trackingId);
};
const sendEvent = payload => {
  const { category, action, label } = payload;

  ReactGA.event({
    category,
    action,
    label, // optional
    value: 99, // optional, must be a number
    nonInteraction: true, // optional, true/false
    transport: "xhr", // optional, beacon/xhr/image
  });
};
const sendPageView = (path, title) => {
  ReactGA.send({ hitType: "pageview", page: path, title });
};

const sendEventWithData = (event, data) => {
  ReactGA.gtag("event", event, data)
}

const analytics = {
  init,
  sendEvent,
  sendPageView,
  sendEventWithData
};
export default analytics;
