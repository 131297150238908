import React from "react";
import { FieldComponent } from "./Field";
import FieldGroup from "./FieldGroup";

export const CustomComponent = ({ fields, onFieldChange, data, fieldsResult, removeKey,selectedClass}) => (
  <>
    {fields &&
      fields.map((field, index) => {
        switch (field.type) {
          case "field":
            return (
              <React.Fragment key={index}>
                <FieldComponent
                  field={field}
                  onFieldChange={onFieldChange}
                  data={data}
                  fieldsResult={fieldsResult}
                  removeKey={removeKey}
                  selectedClass={selectedClass}
                />
              </React.Fragment>
            );
          case "fieldGroup":
            return (
              <React.Fragment key={index}>
                <FieldGroup
                  field={field}
                  onFieldChange={onFieldChange}
                  data={data}
                  fieldsResult={fieldsResult}
                />
              </React.Fragment>
            );
          default:
            return null;
        }
      })}
  </>
);
