import queryString from "query-string"
import { resetState } from "../features/resetState"

const FIRST_INDEX=0
const SECOND_INDEX=1

const removeParamFromQueryString = (paramToBeRemoved) => {
    const URL = window.location.href
    let updatedURL = URL.split("?")[FIRST_INDEX]
    const queryString = URL.split("?")[SECOND_INDEX].split("/")[FIRST_INDEX]
    const params = queryString.split("&");
    const  paramIndex = params.findIndex((each) => each.includes(paramToBeRemoved))
    params.splice(paramIndex, 1)
    if (params.length) updatedURL = updatedURL + "?" + params.join("&");
    return updatedURL;
}

const  handleCloseWidget = (dispatch, history) => {
    const { did: dealId, oid: opportunityId } = queryString.parse(
        window.location.search
      )
    dispatch(resetState)
    const newUrl = (dealId || opportunityId) && removeParamFromQueryString(dealId ? 'dealId' : 'opportunityId')
    newUrl && window.history.pushState({}, "", newUrl)
    history.push('/')
  }

  export default handleCloseWidget; 