import React from "react"
import Dropzone from "react-dropzone";
import { Button, Form } from "react-bootstrap";
import classNames from "classnames";

const UploadDocuments = ({ onDropHandle, isRequired = false, hasError = false }) => {
    return (<Dropzone
        onDrop={onDropHandle}
        accept={[
            "image/*",
            ".pdf",
            ".zip",
            "xls",
            "docs",
        ]}
        maxSize={100000000} // 100MB
    >
        {({ getRootProps, getInputProps }) => (
            <section className="mt-2 mb-3">
                <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    <div className="d-flex align-items-center">
                        <Form.Label className={classNames("flex-fill mr-3", { required: isRequired, "error-message": hasError })}>
                            Please upload your bills or other
                            documents here that will help us expedite
                            your quote
                        </Form.Label>
                        <Button
                            variant="secondary"
                            className="text-nowrap"
                        >
                            Select File
                        </Button>
                    </div>
                </div>
            </section>
        )}
    </Dropzone>
    )
}

export default UploadDocuments

