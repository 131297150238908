import React, { useState } from "react";
import { Col, Row, Button, Collapse } from "react-bootstrap";
import { getFormattedValue } from "../../helper/getFormattedValue";
import { selectUSStates } from "../PriceAndCustomerDetails";
import { useSelector } from 'react-redux'

const PriceSelectionAccordion = ({ supplierCard }) => {
  const [active, setActive] = useState(false);
  const USStates = useSelector(selectUSStates)
  return (
    <>
      <Collapse in={active}>
        <div>
          <Row className="mt-2">
            {supplierCard.supplierDetails.filter(supplier => supplier.type !== "link").map((each) => (
             (each.value !== "N/A" && <Col key={each.key} xs={6} className="adjust-spacing mt-2">
                <span className="heading-text"> {each.key} : </span>
                <span className="sub-heading-text f-14 text-muted">
                  {each.unitPlace === 'front' && each.unit}
                  {each.type !== "default"
                    ? getFormattedValue(each.type, each.value, USStates)
                    : each.value}
                  {each.unitPlace === 'back' && each.unit}
                </span>
              </Col>)
            ))}
          </Row>
          {supplierCard.Links.map((each) => (
            <Row key={each.key}>
              {each}
              <a href={each} rel="noreferrer" target="_blank">
                <i className=" ml-2 fa fa-download"></i>
              </a>
            </Row>
          ))}
        </div>
      </Collapse>
      <div className="text-right">
        <Button
          variant="link"
          className={`f-14 px-0 text-center view-more-button accordion_button ${active}`}
          onClick={() => setActive((prevValue) => !prevValue)}
        >
          {active ? "Less Details" : `More Details`}
          {active ? (
            <i className="fa fa-chevron-up ml-2"></i>
          ) : (
            <i className="fa fa-chevron-down ml-2"></i>
          )}
        </Button>
      </div>
    </>
  );
};

export default PriceSelectionAccordion;
