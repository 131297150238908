import { createSlice } from "@reduxjs/toolkit";
import {
  getUtilitiesFromZip,
  getRateCodes,
  getStateFromZip,
  getDealIdSettings,
  getOpportunityIdSettings,
  getLeadCapturePortalSettings,
  getApplicableMargin,
  getSearchPrice,
  getSupplierLogo,
  getUtilitiesForStateByZip,
  validateReferralCode,
  getSuppierAddressFields
} from "./getPrice.asyncActions";
import { resetState } from "../resetState";

const initialState = {
  price: {},
  rateCodes: [],
  utilities: [],
  tier: "",
  companySettings: {},
  userSettings: {},
  hasNoRecords: false,
  portalSetting: {},
  applicableMargin: {},
  shouldShowRFQDetails: false,
  leadCapturePortSettings: {},
  searchedPriceData: {},
  searchedPrices: [],
  searchedPricesUtility: [],
  searchedPricesSuppliers: [],
  searchedPricesProducts: [],
  supplierAddressFields: {},
  supplierLogo: {},
  isPriceExpired: true,
  isStateUpdated: false,
  isUtilityUpdated: false,
  priceForm: {},
  isInvalidZipcode: false,
  isEmptyUtility: false,
  isCustomPricing: false,
  isRateCodeUpdated: false,
  isSupplierUpdated: false,
  isFetchingSettings: false,
  isReferralCodeValid: false,
};

const slice = createSlice({
  name: "prices",
  initialState,
  reducers: {
    setIsRateCodeUpdated(state, action) {
      state.isRateCodeUpdated = action.payload
    },
    savePrice(state, action) {
      state.price = action.payload;
    },
    setSearchedPrices(state, action) {
      state.searchedPrices = state.searchedPrices.map((supplier, index) => {
        if (supplier.isSelectedForPriceComparision)
          return {
            ...supplier,
            isSelectedForPriceComparision: !supplier.isSelectedForPriceComparision
          };
        else return supplier;
      });
    },
    setPriceForComparision(state, action) {
      const { selectedPriceIndex, supplierDetails } = action.payload;
      state.searchedPrices = state.searchedPrices.map((supplier, index) => {
        if (index === selectedPriceIndex)
          return {
            ...supplier,
            supplierDetails,
            isSelectedForPriceComparision: !supplier.isSelectedForPriceComparision
          };
        else return supplier;
      });
    },
    setShouldShowRFQDetails(state, action) {
      state.shouldShowRFQDetails = action.payload;
    },
    setHasNoRecords(state, action) {
      state.hasNoRecords = action.payload;
    },
    setIsPriceExpired(state, action) {
      state.isPriceExpired = action.payload
    },
    resetPrices(state) {
      state.price = {
        class: "Residential",
        commodity: "Electric"
      };
      state.rateCodes = [];
      state.utilities = [];
      state.tier = "";
      state.shouldShowRFQDetails = false;
    },
    setTier(state, action) {
      state.tier = action.payload || initialState.tier;
    },
    setIsStateUpdated(state, action) {
      state.isStateUpdated = action.payload || initialState.isStateUpdated
    },
    setIsUtilityUpdated(state, action) {
      state.isUtilityUpdated = action.payload || initialState.isUtilityUpdated
    },
    setPriceForm(state, action) {
      state.priceForm = action.payload
    },
    setIsInvalidZipCode(state, action) {
      state.isInvalidZipcode = action.payload || initialState.isInvalidZipcode
    },
    setIsCustomPricing(state, action) {
      state.isCustomPricing = action.payload || initialState.isCustomPricing
    },
    setIsSupplierUpdated(state, action) {
      state.isSupplierUpdated = action.payload || initialState.isSupplierUpdated
    },
    setIsFetchingSettings(state, action) {
      state.isFetchingSettings = action.payload
    }
  },
  extraReducers: {
    [resetState.type]: state => initialState,
    [getUtilitiesFromZip.fulfilled]: (state, action) => {
      state.isUtilityUpdated = action.payload
      state.utilities = action.payload || initialState.utilities;
      state.isEmptyUtility = !action.payload || action.payload.length === 0
    },
    [resetState.type]: state => initialState,
    [getUtilitiesForStateByZip.fulfilled]: (state, action) => {
      state.isUtilityUpdated = action.payload
      state.utilities = action.payload || initialState.utilities;
      state.isEmptyUtility = !action.payload || action.payload.length === 0
    },
    [getRateCodes.fulfilled]: (state, action) => {
      state.isRateCodeUpdated = !!action.payload
      state.rateCodes = action.payload || initialState.rateCodes;
    },
    [getStateFromZip.fulfilled]: (state, action) => {
      state.isStateUpdated = !!action.payload
      state.isInvalidZipcode = !action.payload
      state.price = {
        ...state.price,
        state: action.payload || {}
      };
    },
    [getDealIdSettings.fulfilled]: (state, action) => {
      state.hasNoRecords = !action.payload;
      if(action.payload)
      state.userSettings = action.payload 

    },
    [getOpportunityIdSettings.fulfilled]: (state, action) => {
      state.hasNoRecords = !action.payload;
      state.userSettings = action.payload || {};
    },
    [getLeadCapturePortalSettings.fulfilled]: (state, action) => {
      state.portalSetting = action.payload || {};
    },
    [getApplicableMargin.fulfilled]: (state, action) => {
      state.applicableMargin = action.payload || {};
    },
    [getSearchPrice.fulfilled]: (state, action) => {
      state.searchedPrices = action.payload?.Data?.Prices || [];
      if (!state.isSupplierUpdated) {
        state.searchedPricesSuppliers =
          action.payload.Data?.Metadata?.Suppliers || [];
        state.searchedPricesUtility =
          action.payload.Data?.Metadata?.Utilities.map((each) => ({
            LDCName: each,
            LDCId: state.searchedPrices.find(
              (searchedPricesData) =>
                searchedPricesData.LDCName === each
            )?.LDCId
          })) || [];
        state.searchedPricesProducts =
          action.payload.Data?.Metadata?.ProductTypes || [];
      }
      state.isSupplierUpdated = false
    },
    [getSuppierAddressFields.fulfilled]: (state, action) => {
      state.supplierAddressFields = action.payload?.addressViewModel || {};
      state.supplierContactFields = action.payload?.contactViewModel || {};
    },
    [getSupplierLogo.fulfilled]: (state, action) => {
      state.supplierLogo = !!action.payload
        ? { ...state.supplierLogo, [action.meta.arg]: action.payload }
        : state.supplierLogo;
    },
    [validateReferralCode.fulfilled]: (state, action) => {
      state.isReferralCodeValid =
        action.payload || initialState.isReferralCodeValid;
    },
  },
});

export default slice;

export const { name, actions, reducer } = slice;
