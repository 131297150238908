import React from "react";
import { Row, Col, Button, Collapse } from "react-bootstrap";
import { selectUSStates } from "../PriceAndCustomerDetails";
import { useSelector } from 'react-redux'
import { getFormattedValue } from "../../helper/getFormattedValue"
import { SUPPLIERID, USAGE_SURCHARGES } from "../ShowPrices/showPrice.constants";

const ShowPriceDetails = ({ supplierDetails, supplier, cardDetails }) => {
  const USStates = useSelector(selectUSStates)

  var currentColumnWidth = 2
  var currentColumnIndex = 0

  const updateColumnWidth = () => {
    currentColumnWidth = currentColumnIndex === 3 || currentColumnIndex === 4 ? 3 : 2
    currentColumnIndex = currentColumnIndex === 4 ? 0 : currentColumnIndex + 1
    return currentColumnWidth
  }
  const termination = () => {

    if (supplier.SupplierId === SUPPLIERID.CHARIOT_ENERGY) {
      for (let keyIndex in supplierDetails) {
        if (supplierDetails[keyIndex].key === "Termination Fee Type")
          if (isNaN(supplierDetails[keyIndex].value)) {
            if (supplierDetails[keyIndex].value === 1) {
              return " one time fee"
            } else if (supplierDetails[keyIndex].value === 2) {
              return " per remaining month"
            }
          } else {
            if (supplierDetails[keyIndex].value === 1 + '') {
              return " one time fee"
            } else if (supplierDetails[keyIndex].value === 2 + '') {
              return " per remaining month"
            }
          }
      }
    }
  }

  return (
    <>
      <Collapse in={cardDetails}>
        <Row className='mb-2'>
          <Col>
            <div>
              <Row className="mt-2">
                {supplierDetails.filter(price => price.type !== "link").map((each) => (
                  (supplier.SupplierId === SUPPLIERID.CHARIOT_ENERGY && each.key !== "Termination Fee Type") ?
                    (each.value !=="N/A" && 
                    <Col key={each.key} lg={updateColumnWidth()}  className="adjust-spacing mt-2">
                      <span className="heading-text"> {supplier.SupplierId === SUPPLIERID.ENERGY_TEXAS && each.key === USAGE_SURCHARGES.COMMON
                        ? USAGE_SURCHARGES.ENERGY_TEXAS : each.key}:{" "}
                      </span>
                      <span className="sub-heading-text f-14 text-muted">
                        {each.unitPlace === 'front' && !isNaN(each.value) && each.unit}
                        {each.type !== 'default' ? (supplier.SupplierId === SUPPLIERID.CHARIOT_ENERGY && each.key === "Early Termination Fee") ?
                          each.value === "N/A" ? "N/A" : getFormattedValue(each.type, each.value, USStates) + termination() :
                          each.value === "N/A" ? "N/A" : getFormattedValue(each.type, each.value, USStates) : `${each.value}`}
                        {each.unitPlace === 'back' && each.unit}
                      </span>
                    </Col>) :
                    (supplier.SupplierId !== SUPPLIERID.CHARIOT_ENERGY && each.key !== "Termination Fee Type") &&
                    (<Col key={each.key} lg={updateColumnWidth()}  className="adjust-spacing mt-2">
                      <span className="heading-text"> {supplier.SupplierId === SUPPLIERID.ENERGY_TEXAS && each.key === USAGE_SURCHARGES.COMMON
                        ? USAGE_SURCHARGES.ENERGY_TEXAS : each.key}:{" "}
                      </span>
                      <span className="sub-heading-text f-14 text-muted">
                        {each.unitPlace === 'front' && !isNaN(each.value) && each.unit}
                        {each.type !== 'default' ? each.value === "N/A" ? "N/A" : getFormattedValue(each.type, each.value, USStates) : `${each.value}`}
                        {each.unitPlace === 'back' && each.unit}
                      </span>
                    </Col>)
                ))}
              </Row>
              {supplier.Links.map((each) => (
                <Row key={each.key}>
                  {each}
                  <a href={each} rel="noreferrer" target="_blank">
                    <i className=" ml-2 fa fa-download"></i>
                  </a>
                </Row>
              ))}
            </div>
          </Col>
        </Row>
      </Collapse>
    </>
  );
};

export default ShowPriceDetails;
