import React from "react";
import { useSelector } from "react-redux";
import { getNamedBusyIndicator } from "./busyIndicator.selectors";
import { REGIONS } from "./busyIndicator.constants";
import "font-awesome/css/font-awesome.min.css";

export default function BusyIndicator({ children, region = REGIONS.GLOBAL }) {
  const show = useSelector(getNamedBusyIndicator(region));

  const hasContentToDisplay =
    !show && children && (children.length === undefined || children.length > 0);

  return (
    <React.Fragment>
      {show ? (
        <div className="d-flex justify-content-center">
          <i className="fa fa-spinner fa-lg fa-spin text-success"></i>
        </div>
      ) : (
        <React.Fragment>
          {hasContentToDisplay ? children : <ContentNotFound />}
        </React.Fragment>
      )}
    </React.Fragment>
  );
}

function ContentNotFound() {
  return <p></p>;
}
