import { createAsyncThunk } from "@reduxjs/toolkit";
import doAsync from "../../infrastructure/doAsync";
import { REGIONS } from "../../widgets/busyIndicator";

export const getStateFromZip = createAsyncThunk(
  "getUsState/get",
  async ({ zipcode, useCaching, noBusySpinner } = {}, thunkArgs) =>
    await doAsync({
      url: `statesByZipCode/${zipcode}`,
      useCaching,
      noBusySpinner,
      errorMessage: "Unable to get State. Please try again later.",
      ...thunkArgs,
      busyIndicatorName: REGIONS.GET_UTILITIES,
    })
);

export const getUtilitiesFromZip = createAsyncThunk(
  "getUtilities/getAll",
  async ({ zipcode, commodityId, useCaching, noBusySpinner } = {}, thunkArgs) =>
    await doAsync({
      url: `utilitiesByZipCode/${zipcode}/${commodityId}`,
      useCaching,
      noBusySpinner,
      errorMessage:
        "Unable to load Utilities and Rate Code. Please try again later.",
      busyIndicatorName: REGIONS.GET_UTILITIES,
      ...thunkArgs
    })
);

export const getUtilitiesForStateByZip = createAsyncThunk(
  "getUtilities/getAll",
  async ({ zipcode, commodityId, useCaching, noBusySpinner } = {}, thunkArgs) =>
    await doAsync({
      url: `utilitiesForStateByZipCode/${zipcode}/${commodityId}`,
      useCaching,
      noBusySpinner,
      errorMessage:
        "Unable to load Utilities. Please try again later.",
      busyIndicatorName: REGIONS.GET_UTILITIES,
      ...thunkArgs
    })
);

export const getRateCodes = createAsyncThunk(
  "refreshRateCodes/getAll",
  async ({ filters }, thunkArgs, { useCaching, noBusySpinner } = {}) =>
    await doAsync({
      url: `getRateClasses`,
      httpMethod: "post",
      useCaching,
      noBusySpinner,
      httpConfig: {
        body: JSON.stringify(filters)
      },
      errorMessage: "Unable to load Rate Codes. Please try again later.",
      busyIndicatorName: REGIONS.GET_RATE_CODES,
      ...thunkArgs
    })
);

export const getDealIdSettings = createAsyncThunk(
  "getDealIdSettings",
  async ({ id, useCaching, noBusySpinner } = {}, thunkArgs) => {
    return await doAsync({
      url: `deal/${id}`,
      useCaching,
      noBusySpinner,
      errorMessage: "Unable to load user settings,please try again later.",
      ...thunkArgs
    });
  }
);

export const getOpportunityIdSettings = createAsyncThunk(
  "getOpportunityIdSettings",
  async ({ id, useCaching, noBusySpinner } = {}, thunkArgs) =>
    await doAsync({
      url: `opportunity/${id}`,
      useCaching,
      noBusySpinner,
      errorMessage: "Unable to load user settings,please try again later.",
      ...thunkArgs
    })
);

export const getLeadCapturePortalSettings = createAsyncThunk(
  "getLeadCapturePortalSettings/get",
  async ({ brokerageId, useCaching, noBusySpinner } = {}, thunkArgs) =>
    await doAsync({
      url: `settings/${brokerageId}`,
      useCaching,
      noBusySpinner,
      errorMessage: "Unable to get State. Please try again later.",
      ...thunkArgs
    })
);

export const getApplicableMargin = createAsyncThunk(
  "getApplicableMargin/get",
  async (
    {
      brokerageId,
      stateId,
      utilityId,
      supplierId,
      commodity,
      rateClass,
      useCaching,
      noBusySpinner
    } = {},
    thunkArgs
  ) =>
    await doAsync({
      url: `applicableMargin?brokerageId=${brokerageId}&stateId=${stateId}&utilityId=${utilityId}&supplierId=${supplierId}&commodity=${commodity}&rateClass=${rateClass}`,
      useCaching,
      noBusySpinner,
      errorMessage: "Unable to get State. Please try again later.",
      ...thunkArgs
    })
);

export const getSearchPrice = createAsyncThunk(
  "getSearchPrice/getAll",
  async (priceToBeSearched, thunkArgs, { useCaching, noBusySpinner } = {}) =>
    await doAsync({
      url: `supplierprices`,
      useCaching,
      noBusySpinner,
      httpMethod: "post",
      httpConfig: {
        body: JSON.stringify(priceToBeSearched)
      },
      busyIndicatorName: REGIONS.GET_SUPPLIERS,
      errorMessage: "Unable to get Prices. Please try again later.",
      ...thunkArgs
    })
);

//Need to write new function for call new Address API


export const getSuppierAddressFields = createAsyncThunk(
  "getSuppierAddressFields",
  async ({
    brokerageId,
    supplierId,
    subscriptionKey

  }, thunkArgs, { useCaching, noBusySpinner } = {}) =>
    await doAsync({
      url: `GetSupplierInfo?brokerageid=${brokerageId}&supplierid=${supplierId}&subscription-key=${subscriptionKey}`,
      useCaching,
      noBusySpinner,
      httpMethod: "get",
      errorMessage: "Failed to Supplier Fields. Please try again later",
      ...thunkArgs,
    })
);
//End Need to write new function for call new Address API

export const getSupplierLogo = createAsyncThunk(
  "getSupplierLogo/getAll",
  async (supplierId, thunkArgs, { useCaching, noBusySpinner } = {}) =>
    await doAsync({
      url: `supplierLogo/${supplierId}`,
      useCaching,
      noBusySpinner,
      httpMethod: "get",
      errorMessage: "Unable to get Supplier Logo. Please try again later.",
      ...thunkArgs
    })
);

export const validateReferralCode = createAsyncThunk(
  "validateReferralCode/get",
  async (referralCode, thunkArgs, { useCaching, noBusySpinner } = {}) =>
    await doAsync({
      url: `IsReferralCodeValid?referralCode=${referralCode}`,
      useCaching,
      noBusySpinner,
      httpMethod: "get",
      errorMessage: "Unable to validate Referral Code. Please try again later.",
      ...thunkArgs,
    })
);
