import React, { useEffect } from "react";
import { Form, Row, Col, OverlayTrigger, Tooltip } from "react-bootstrap";
import classNames from "classnames";
import { Link } from "react-router-dom";

const DropdownComponent = ({ field, onFieldChange, data, fieldsResult }) => {
  useEffect(() => {
    const value =
      fieldsResult[field.name] ||
      field.defaultValue ||
      (field.values.length > 0 && field.values[0].value);
    onFieldChange(field.name, value);
    // eslint-disable-next-line
  }, []);

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {field.friendlyDescription}
    </Tooltip>
  );

  const handleEmailButtonClick = () => {
    window.location.href = "mailto:CustomerCare@mychariotenergy.com";
  };

  return (
    <Form.Group>
      <Row className="mx-n1 mb-3">
        <Col lg={6} className="px-1">
          <Form.Label className={classNames({ required: field.required })}>
            {field.friendlyName}
            {field.friendlyDescription && (
              <OverlayTrigger placement="top" overlay={renderTooltip}>
                <i className="ml-2 fa fa-info-circle" aria-hidden="true"></i>
              </OverlayTrigger>
            )}
          </Form.Label>
        </Col>
        <Col lg={6} className="px-1">
          <Form.Control
            as="select"
            onChange={(e) => onFieldChange(field.name, e.target.value)}
            required={field.required}
            value={data[field.name]}
          >
            {field.values.map((val, index) => (
              <option value={val.value} key={index}>
                {val.name}
              </option>
            ))}
          </Form.Control>
        </Col>
        {field.name == "WavierReason" ? (
          <div>
            <span
              style={{
                fontWeight: 500,
                lineHeight: 1.5,
                fontSize: "0.9rem",
                justifyContent: "center",
              }}
            >
              {data[field.name]}
            </span>
            <br />
            <input type="checkbox" checked />
            {' '}
            <label
              style={{ fontWeight: 600, fontSize: "0.9rem", marginTop: "30px" }}
              onClick={handleEmailButtonClick}
            >
            I will send the waiver proof document via email to{' '}
              <Link to= "CustomerCare@mychariotenergy.com" target='_blank'>
                CustomerCare@mychariotenergy.com
              </Link>
            </label>
          </div>
        ) : (
          " "
        )}
      </Row>
    </Form.Group>
  );
};

export default DropdownComponent;
