import * as selectors from "./priceAndCustomerDetails.selectors";
import slice from "./priceAndCustomerDetails.slice";

export * from "./priceAndCustomerDetails.asyncActions";
export * from "./priceAndCustomerDetails.constant";
export const {
  name,
  actions: {
    setSteps,
    addToCustomerAccountsInfo,
    addToCustomerAccountsInfoDetail,
    setSelectedSupplier,
    saveCustomerInfo,
    removeFromCustomerAccountsInfo,
    searchAccount,
    resetSearchedAccount,
    setCustomerLegalEntityName,
    setFieldsResult,
    setTaxExemptValue,
  },
  reducer,
} = slice;

// we prefix all selectors with the the "select" prefix
export const {
  selectSteps,
  selectCustomerAccountsInfo,
  selectSelectedSupplier,
  selectCustomerInfo,
  selectSearchedAccount,
  selectUnitsForCommodity,
  selectEnrollmentFields,
  selectFieldsResult,
  selectUSStates,
  selectTaxExemptValue
} = selectors;

// we export the component most likely to be desired by default
