import React from "react";
import { Button, Card, Row, Col } from "react-bootstrap";
import handleCloseWidget from "../../helper/handleCloseWidget";
import { useHistory } from "react-router-dom";
import { useSelector,useDispatch } from "react-redux";
import { 
  TIERS, 
  setShouldShowRFQDetails,
  selectPriceForm, 
  getSearchPrice, 
  setIsCustomPricing, 
  selectTier, 
  selectUtilities,
  selectPrice,
  getUtilitiesForStateByZip
} from '../GetPrice'
import { isEmpty } from "lodash";
import classNames from "classnames";
import { selectClientConfig } from "../clientContext";

const SelectPricingType = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const priceForm = useSelector(selectPriceForm)
  const utilities = useSelector(selectUtilities)
  const tier = useSelector(selectTier)
  const price = useSelector(selectPrice)
  const clientConfig = useSelector(selectClientConfig);
  const { isFullScreen } = clientConfig;

  return (
    <div className={classNames("inner-widget", {"px-20": !isFullScreen})}>
      <Card className={classNames("shadow-lg", {"min-h-100": isFullScreen})}>
      <Card.Header className="p-header">
          <div className="d-flex align-items-center justify-content-between">
            <h5 className="f-15 mb-0">Select Pricing Type</h5>
            <Button
              variant="outline-light"
              onClick={() => handleCloseWidget(dispatch, history)}
            >
              <i className="fa fa-times fa-2x f-20"></i>
            </Button>
          </div>
        </Card.Header>
        <Card.Body>
          <Row className="mb-2">
            <Col md={6}>
              <div className="card border bg-light mb-3">
                <Card className='select-card'>
                  <Card.Body className='d-flex flex-column'>
                    <Card.Title>Custom Pricing</Card.Title>
                    <Card.Text>
                      This process will take into account how and when you use
                      power. We will tailor a specific product to meet your
                      unique needs. We will require a few more pieces of
                      information as next steps.
                    </Card.Text>
                    <i className='mt-auto fa fa-handshake-o text-center selection-icon'></i>
                    <div className="text-center mt-auto">
                    <Button onClick={()=>{
                      if(isEmpty(utilities) && tier === TIERS.MID)
                      {
                        dispatch(getUtilitiesForStateByZip({ zipcode: price.zipCode, commodityId: price.commodity }))
                      }
                      dispatch(setShouldShowRFQDetails(true))
                      history.push('info')
                      dispatch(getSearchPrice(priceForm))
                      dispatch(setIsCustomPricing(true))
                      }} 
                      className="text-center" variant="primary">
                      Select
                    </Button>
                    </div>
                  </Card.Body>
                </Card>
              </div>
            </Col>
            <Col md={6}>
              <div className="card border bg-light mb-3">
                <Card className='select-card'>
                  <Card.Body className='d-flex flex-column'>
                    <Card.Title>Matrix Pricing</Card.Title>
                    <Card.Text>
                      Know your annual usage? (Hint: If not, its on your bill!)
                      In seconds, we will pull prices from Suppliers and you can
                      secure that price within 4 easy steps.
                    </Card.Text>
                    <i className='mt-auto py-20 fa fa-table text-center selection-icon'></i>
                    <div className="text-center mt-auto">
                    <Button 
                    onClick={()=>{
                      history.push('show-prices')
                      dispatch(getSearchPrice(priceForm))
                      dispatch(setIsCustomPricing(false))
                  }} className="text-center" variant="primary">
                      Select
                    </Button>
                    </div>
                  </Card.Body>
                </Card>
              </div>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </div>
  );
};

export default SelectPricingType;
