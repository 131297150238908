export const showAddressLookup = (id) => {
    //State ids for Texas, Philadelphia and Ohio
    const stateIds = [35, 38, 43];
    if(stateIds.includes(id)){
        return true;
    }
    else{
        return false;
    }
}

export const showESIIDorECLID = (id) => {
    return showAddressLookup(id) ? id === 43 ? true : false : false;  
}
