import ShowPrices from "./ShowPrices";

import * as selectors from "./showPrices.selectors";
import slice from "./showPrices.slice";

export * from "./showPrice.constants"
export * from "./showPrices.utils"
export const {
  name,
  actions: {
    resetShowPrices,
    setFilters,
    setIsFavorite,
    setCustomPricingMessageStatus,
  },
  reducer,
} = slice;

export const { selectSuppliers, selectFilters, selectProducts, selectCustomPricingMessageStatus } = selectors;

export default ShowPrices;
