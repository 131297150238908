import slice from "./getPrice.slice";

export const selectSlice = state => state[slice.name];
export const selectPrice = state => selectSlice(state).price;
export const selectUtilities = state => selectSlice(state).utilities;
export const selectRateCodes = state => selectSlice(state).rateCodes;
export const selectTier = state => selectSlice(state).tier;
export const selectUserSettings = state => selectSlice(state).userSettings;
export const selectHasNoRecords = state => selectSlice(state).hasNoRecords;
export const selectShouldShowRFQDetails = state =>
  selectSlice(state).shouldShowRFQDetails;
export const selectPortalSetting = state => selectSlice(state).portalSetting;
export const selectApplicableMargin = state =>
  selectSlice(state).applicableMargin;
export const selectSearchedPrices = state => selectSlice(state).searchedPrices;
export const selectSearchedPricesUtility = state =>
  selectSlice(state).searchedPricesUtility;
export const selectSearchedPricesSuppliers = state =>
  selectSlice(state).searchedPricesSuppliers;
export const selectAllProducts = state =>
  selectSlice(state).searchedPricesProducts;
export const selectSuppliersLogo = state => 
  selectSlice(state).supplierLogo;
export const selectIsPriceExpired = state =>
  selectSlice(state).isPriceExpired
export const selectIsStateUpdated = state =>
  selectSlice(state).isStateUpdated  
export const selectIsUtilityUpdated =state =>
  selectSlice(state).isUtilityUpdated  
export const selectPriceForm = state =>
  selectSlice(state).priceForm  
export const selectIsInvalidZipcode = state =>
  selectSlice(state).isInvalidZipcode  
export const selectIsEmptyUtility = state =>
  selectSlice(state).isEmptyUtility  
export const selectIsCustomPricing = state =>
  selectSlice(state).isCustomPricing  
export const selectIsRateCodeUpdated = state =>
  selectSlice(state).isRateCodeUpdated
export const selectIsFetchingSettings = state =>
  selectSlice(state).isFetchingSettings 
export const selectIsReferralCodeValid = (state) =>
  selectSlice(state).isReferralCodeValid;
export const selectSuppierAddressFields = (state) =>
  selectSlice(state).supplierAddressFields;
  export const selectSuppierContactFields = (state) =>
  selectSlice(state).supplierContactFields;