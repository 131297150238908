import React, { useEffect } from "react";
import { Form, Row, OverlayTrigger, Tooltip } from "react-bootstrap";
import parse from 'html-react-parser';

const Checkbox = ({ field, onFieldChange, fieldsResult, data }) => {

  useEffect(() => {
    const value = fieldsResult[field.name] || field.defaultValue
    onFieldChange(field.name, !!value);
    // eslint-disable-next-line
  }, []);

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {field.friendlyDescription}
    </Tooltip>
  );

  return (
    <Form.Group>
      <Row className="mx-n1 mb-3">
        <Form.Group className="mb-3 d-flex">
          <Form.Check
            className='custom-form'
            required={field.required}
            label={parse(field.friendlyName)}
            onChange={e => {
              onFieldChange(field.name, e.target.checked);
            }}
            checked={data[field.name]}
            id={field.name}
          />
          {field.friendlyDescription && (
            <OverlayTrigger placement="top" overlay={renderTooltip}>
              <i className="mt-1 ml-2 fa fa-info-circle" aria-hidden="true"></i>
            </OverlayTrigger>
          )}
        </Form.Group>
      </Row>
    </Form.Group>
  );
};

export default Checkbox;
