import React, { useState, useEffect } from "react";
import { Row, Col, Button, Card, Form } from "react-bootstrap";
import queryString from "query-string";
import Stepper from "./Stepper";
import SupplierCard from "./SupplierCard";
import { useHistory } from "react-router-dom";
import { Alert } from "react-bootstrap";
import {
  selectCustomerAccountsInfo,
  selectCustomerInfo,
  selectSteps,
  selectUSStates,
  selectEnrollmentFields,
  selectSelectedSupplier,
  selectCustomerLegalEntityName,
  selectFieldsResult,
  selectTaxExemptValue,
} from "./priceAndCustomerDetails.selectors";
import { useSelector, useDispatch } from "react-redux";
import { saveAllInformation } from "./priceAndCustomerDetails.asyncActions";
import CustomComponent from "../../widgets/CustomComponent";
import BusyIndicator, { REGIONS } from "../../widgets/busyIndicator";
import { actions } from "./priceAndCustomerDetails.slice";
import handleCloseWidget from "../../helper/handleCloseWidget";
import { selectPrice, CLASS, selectPortalSetting, COMMODITY } from "../GetPrice";
import { getUsage } from "../../helper/getUsage";
import { selectFilters } from "../ShowPrices/showPrices.selectors";
import { getMonthIndex } from "../ShowPrices";
import { unit } from "../PriceAndCustomerDetails/priceAndCustomerDetails.constant";
import analytics from "../../helper/analytics"
import { selectClientConfig } from "../clientContext";
import classNames from "classnames";
import { useUserContext } from "../utils/userContext";
import { monthNames } from "../ShowPrices";
import { mask, unMask } from "remask";
import { isEmpty } from "lodash";
import { SUPPLIERIDs, customDateDropdownMessage } from "../PriceAndCustomerDetails/priceAndCustomerDetails.constant";

const { NAT_GAS, ELECTRIC } = unit;

const { setFieldsResult, setSaveStatus } = actions;

const Enrollment = () => {
  const ctx = useUserContext();
  const steps = useSelector(selectSteps);
  const clientConfig = useSelector(selectClientConfig);
  const history = useHistory();
  const dispatch = useDispatch();
  const customerInfo = useSelector(selectCustomerInfo);
  const USStates = useSelector(selectUSStates);
  const selectedSupplier = useSelector(selectSelectedSupplier);
  const customerAccountInfo = useSelector(selectCustomerAccountsInfo);
  const portalSetting = useSelector(selectPortalSetting)
  const customerLegalEntityName = useSelector(selectCustomerLegalEntityName);
  const filters = useSelector(selectFilters)
  const fieldsResult = useSelector(selectFieldsResult)
  const price = useSelector(selectPrice);
  const fields = JSON.parse(JSON.stringify(useSelector(selectEnrollmentFields)));
  const taxExemptValue = useSelector(selectTaxExemptValue)
  const { oid: opportunityId, did: dealId } = queryString.parse(window.location.search);
  const [data, setData] = useState({});
  const [show, setShow] = useState(false);
  const [validated, setValidated] = useState(false);
  const portalSettings = useSelector(selectPortalSetting);
  const [moveType, setMoveType] = useState('');
  const { isFullScreen,brokerageId } = clientConfig;
  const {
    PlanId,
    SupplierId,
    SupplierName,
    Term,
    PlanName,
    ProductType,
    ProductName,
    Price,
    RateClass,
    PlanDescription,
    GreenPercentage,
    UDCCode,
    UDCID,
    Zone,
  } = selectedSupplier;

  const isElectricCommodity = price.commodity === COMMODITY.ELECTRIC;

  const quote = {
    planId: price.class === CLASS.RESIDENTIAL ? PlanId : null,
    supplierId: SupplierId,
    supplierName: SupplierName,
    term: Term,
    planName: PlanName,
    productType: ProductType,
    productName: ProductName,
    price: Price,
    notes: PlanDescription,
    rateClass: RateClass,
    Unit: isElectricCommodity
      ? ELECTRIC
      : NAT_GAS,
    GreenPercentage,
    UDCCode: price.class === CLASS.RESIDENTIAL ? UDCCode : undefined,
    UDCID: price.class === CLASS.RESIDENTIAL ? UDCID : undefined,
    LoadZoneName: Zone,

  };
  let supplierSpecificFields = data;

  const getStateNameByStateId = (stateId) => {
    const stateDataArray = USStates.find(
      (states) => states.id === stateId
    );
    const stateName = stateDataArray?.displayName;
    return stateName;
  };

  useEffect(() => setShow(false), [])

  const [constelDayValidate, setConstelDayValidate] = useState(false)

  const holidays = [
    new Date(2022, 0, 17),
    new Date(2022, 1, 21),
    new Date(2022, 3, 15),
    new Date(2022, 4, 30),
    new Date(2022, 5, 20),
    new Date(2022, 6, 4),
    new Date(2022, 8, 5),
    new Date(2022, 10, 24),
    new Date(2022, 11, 26),
    new Date(2023, 0, 2),
    new Date(2023, 0, 16),
    new Date(2023, 1, 20),
    new Date(2023, 3, 7),
    new Date(2023, 4, 29),
    new Date(2023, 5, 20),
    new Date(2023, 6, 4),
    new Date(2023, 8, 4),
    new Date(2023, 10, 23),
    new Date(2023, 11, 25),
    new Date(2024, 0, 1),
    new Date(2024, 0, 15),
    new Date(2024, 1, 19),
    new Date(2024, 3, 29),
    new Date(2024, 4, 27),
    new Date(2024, 5, 19),
    new Date(2024, 6, 4),
    new Date(2024, 8, 2),
    new Date(2024, 10, 28),
    new Date(2024, 11, 25),
    new Date(2025, 0, 1),
    new Date(2025, 0, 20),
    new Date(2025, 1, 17),
    new Date(2025, 3, 18),
    new Date(2025, 4, 26),
    new Date(2025, 5, 19),
    new Date(2025, 6, 4),
    new Date(2025, 8, 1),
    new Date(2025, 10, 27),
    new Date(2025, 11, 25),
  ];

  const getAvailabledates = async () => {

    const data = {
      "BrokerId": selectedSupplier.BrokerId,
      "SupplierId": selectedSupplier.SupplierId,
      "Commodity": selectedSupplier.ServiceType,
      "TDSPCode": selectedSupplier.PlanId,
      "SwitchMoveType": moveType,
      "AMSYN": "AMSR"
    }
    let days;

    await fetch("https://betaretail.sparkplug.energy/api/retail/availabledates", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=utf-8'
      },
      body: JSON.stringify(data)
    }).then(res => res.json())
      .then(data => days = data)
      .catch(error => console.error(error));

    return days;
  }

  let arrayDate = [];
  useEffect(() => {

    let resp = getAvailabledates();
    resp.then(res => res).then(data => {
      let longitud = data.Dates ? Object.keys(data.Dates).length : null;

      for (let i = 0; i < longitud; i++) {
        arrayDate.push(new Date(data.Dates[i].Date));
      }
    })

  }, [moveType, fields])

  let defaultDate;

  const selectdate = () => {

    for (let Key in fields) {
      if (fields[Key].type === "fieldGroup" && fields[Key]["fields"]) {
        let fieldEnter = fields[Key]["fields"];
        for (let obj of fieldEnter) {
          let { name, friendlyName, dataType, daysUpto } = obj
          if (name === "DateOfBirth") {
            obj['ranges'] = {
              allowWeekends: true,
            }
          }
          if (arrayDate.length !== 0) {
            obj['holidays'] = holidays
            obj['availableDates'] = arrayDate
          } else {

            if ((name === "SwitchDate" || name === "SwitchMoveDate") && (friendlyName === "Select a Date" || friendlyName === "Switch & Move-In Date") && dataType === "DateTime") {
              obj['ranges'] = {
                minDate: null,
                maxDate: null
              }

              obj['holidays'] = holidays

              const month = monthNames.indexOf(ctx.get());
              let date = new Date()


              if (month !== -1 && month !== new Date().getMonth()) {
                date.setDate(1)
                date.setMonth(month)

              }

              let offset = 0;
              if (date.getDay() === 0) {
                offset = 1
              } else if (date.getDay() === 6) {
                offset = 2
              }

              date.setDate(date.getDate() + offset)

              if (constelDayValidate === true && month === new Date().getMonth()) {
                date.setTime(date.getTime() + 7 * 86400000)
              }

              obj['date'] = new Date(date)
              const expiryDate = new Date(date)

              expiryDate.setTime(date.getTime() + (Number(daysUpto) || 90) * 86400000)

              obj.ranges['minDate'] = date;
              obj.ranges['maxDate'] = expiryDate;

              defaultDate = date;

            }
          }
        }
      }
    }

    for (let obj of fields) {
      let { name, friendlyName, dataType, daysUpto } = obj
      if (name === "DateOfBirth") {
        obj['ranges'] = {
          allowWeekends: true,
        }
      }
      if (arrayDate.length !== 0) {
        obj['holidays'] = holidays
        obj['availableDates'] = arrayDate
      } else {

        if ((name === "SwitchDate" || name === "SwitchMoveDate") && (friendlyName === "Select a Date" || friendlyName === "Switch & Move-In Date") && dataType === "DateTime") {

          obj['ranges'] = {
            minDate: null,
            maxDate: null
          }

          obj['holidays'] = holidays
          const month = monthNames.indexOf(ctx.get());
          let date = new Date()

          if (month !== -1 && month !== new Date().getMonth()) {
            date.setDate(1)
            date.setMonth(month)
          }

          let offset = 0;
          if (date.getDay() === 0) {
            offset = 1
          } else if (date.getDay() === 6) {
            offset = 2
          }

          date.setDate(date.getDate() + offset)

          if (constelDayValidate === true && month === new Date().getMonth()) {
            date.setTime(date.getTime() + 7 * 86400000)
          }

          obj['date'] = new Date(date)
          const expiryDate = new Date(date)

          expiryDate.setTime(date.getTime() + (Number(daysUpto) || 90) * 86400000)

          obj.ranges['minDate'] = date;
          obj.ranges['maxDate'] = expiryDate;

          defaultDate = date;

          /*date.setMonth(date.getMonth() + 1);

          const dateThreeMonths = new Date(date);
          if (selectedSupplier.SupplierId === "0770f405-d176-47c4-a8dd-dd6d71d98952") {
            dateThreeMonths.setDate(dateThreeMonths.getDate() + 30)

          } else if (selectedSupplier.SupplierId === "41c9c9c8-f2e6-4b82-bdbe-c55243d41803") {
            dateThreeMonths.setDate(dateThreeMonths.getDate() + 60)

          } else {
            dateThreeMonths.setDate(dateThreeMonths.getDate() + 90)
          }

          const strMinDate = `${date.getMonth()}/${date.getDate()}/${date.getFullYear()} 12:00:00 AM`

          const strMaxDate = `${dateThreeMonths.getMonth()}/${dateThreeMonths.getDate()}/${dateThreeMonths.getFullYear()} 12:00:00 AM`

          obj.ranges['minDate'] = strMinDate;
          obj.ranges['maxDate'] = strMaxDate;*/

        }
      }
    }
  }

  selectdate();

  const flagshipCase = (fields) => {
    
    fields.map((field) => {
      if(field.type === "fieldGroup"){flagshipCase(field.fields);}
      if(SUPPLIERIDs["FlAGSHIPPOWER"] === selectedSupplier.SupplierId && field.dataType === "DateTime" && field.friendlyName === "Switch & Move-In Date") {
        field.friendlyName = customDateDropdownMessage;
      }
    });
  }

  const getFormattedFields = () => {
    let fieldsToShow = []
    if (fields && fields.length > 0) {
      const fieldsWithoutAffirmation = fields.filter(each => !each.name?.includes("AffirmationStatement"))
      const fieldData = fieldsWithoutAffirmation.filter(each => each.friendlyName !== "Deposit")
      const fieldsWithAffirmation = fields.filter(each => each.name?.includes("AffirmationStatement"))
      flagshipCase(fields);

      fieldsToShow = [...fieldData, ...fieldsWithAffirmation]
    }
    else fieldsToShow = [...fields]
    if (selectedSupplier.Disclaimer) {
      const disclaimerField = { name: "Disclaimer", dataType: "Info", friendlyName: selectedSupplier.Disclaimer, type: "field", required: true }
      return [...fieldsToShow, disclaimerField]
    } else return fieldsToShow
  }
  

  const submitHandler = (event) => {
    const form = event.currentTarget;
    const meters = customerAccountInfo.map(({ utility, ...each }) => each);
    const usage = getUsage(portalSetting.keyValue, price);
    

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      const documents = customerInfo?.files?.map(({ size, ...file }) => ({
        data: file,        
      }));

      if (!isEmpty(supplierSpecificFields.SocialSecurityNumber)) {
        supplierSpecificFields.SocialSecurityNumber = unMask(supplierSpecificFields.SocialSecurityNumber)
      }
    
      if ((supplierSpecificFields.SwitchMoveType === "Moving")
        || (supplierSpecificFields.SwitchMoveType === "Move-In")
        || (supplierSpecificFields.SwitchMoveType === "move")
        || (supplierSpecificFields.SwitchMoveType === "MoveIn")) {

        if (supplierSpecificFields.SwitchMoveDate === undefined) {
          supplierSpecificFields.SwitchMoveDate = defaultDate.getFullYear() + "-" + (defaultDate.getMonth() + 1) + "-" + defaultDate.getDate();
        }
      } else if ((supplierSpecificFields.SwitchType === "Self-Selected Switch")
        || (supplierSpecificFields.SwitchSubType === "Self-Selected Switch")
        || (supplierSpecificFields.SwitchSubType === "switchDate")
        || (supplierSpecificFields.SwitchSubType === "forward_switch")
        || (supplierSpecificFields.SwitchSubType === "SwitchMoveDate")) {

        if (supplierSpecificFields.SwitchMoveDate === undefined) {
          supplierSpecificFields.SwitchMoveDate = defaultDate.getFullYear() + "-" + (defaultDate.getMonth() + 1) + "-" + defaultDate.getDate();
        }
      }

      const contact = { ...customerInfo }
      delete contact.files
      dispatch(
        saveAllInformation({
          customerData: {
            opportunityId: opportunityId,
            dealId: dealId,
            customerLegalEntityName,
            class: price.class,
            commodityTypeId: price.commodity,
            taxExemptValue,
            annualUsageOrMonthlyBill: usage,
            zipCode: price.zipCode,
            promoCode: price.referralCode,
            quote,
            contact,
            meters,
            documents,
            supplierSpecificFields,
            startMonth: getMonthIndex(filters.month),
            startYear: filters.year,
            stateId: price.state.id,
            brokerageId,
          },
        })
      ).then((res) => {
        if (res.payload)
          history.push("review")
        else
          setShow(true)
      });
      dispatch(setFieldsResult(data));
      analytics.sendEventWithData("Enrollment", data)
    }
    setValidated(!form.checkValidity());
  };
  
  const showMessage = (cssClass) => {
    const div = document.createElement('div');
    div.className = `alert alert-${cssClass} mt-2`;
    div.appendChild(document.createTextNode("Please verify your date of birth, you must be over 18 years old"))
    const container = document.querySelector('#container')
    const app = document.querySelector('#app')
    container.insertBefore(div, app)

    const time = setTimeout(function () {
      document.querySelector('.alert').remove();
    }, 5000)

    return time

  }
  const vardiv = document.querySelector('.react-datepicker-wrapper')
  if (vardiv) {
    vardiv.setAttribute("id", "datePiker")
    document.getElementById("datePiker").addEventListener('keydown', (e) => {
      e.preventDefault()
    })
  }
  return (
    <div className={classNames("inner-widget", { "px-20": !isFullScreen })}>
      <Card className={classNames("p-4", { "min-h-100": isFullScreen })}>
        <div className="mb-4 py-2">
          <div className="d-flex align-items-center justify-content-between">
            <Button
              variant="outline-primary"
              onClick={() => {
                dispatch(setSaveStatus())
                history.push("accounts")
              }}
            >
              Back to Accounts
            </Button>
            <div className='d-flex align-items-center'>
            {portalSettings.phoneWork && <h6 className="sub-heading-text f-14 text-muted">Need Assistance?: Call {portalSettings.phoneWork}</h6>}
            <Button
              variant="outline-light"
              onClick={() => handleCloseWidget(dispatch, history)}
            >
              <i className="fa fa-times fa-2x f-20"></i>
            </Button> </div>
          </div>
        </div>
        <Row className="justify-content-center mb-3">
          <SupplierCard />
          <Col lg={8}>
            <div className="wizard ">
              <Stepper pages={steps} currentPath="enrollment" />
              <BusyIndicator region={REGIONS.SAVE_ALL_INFORMATION}>
                <div id='container' className="wizard-content" data-step="step1">
                  {show && (
                    <Alert
                      variant="danger"
                      onClose={() => setShow(false)}
                      dismissible
                    >
                      <p>Unable To Save Details</p>
                    </Alert>
                  )}
                  <Form
                    id="app"
                    noValidate={!validated}
                    validated={validated}
                    onSubmit={submitHandler}
                  >
                    <CustomComponent
                      fields={getFormattedFields()}
                      data={data}
                      fieldsResult={fieldsResult}
                      onFieldChange={(field, value) => {
                        if (field === "SocialSecurityNumber" && value !== undefined) {

                          setData((data) => ({ ...data, [field]: SupplierId === "252023dd-af76-4daa-8d8b-ceb2a60b6a39" ? mask(unMask(value), "9999") : mask(unMask(value), "999-99-9999") }));
                          return
                        } 
                        else if (field === "RoutingNumber" && value !== undefined){
                          setData((data) => ({ ...data, [field]: mask(unMask(value), "999999999") }));
                          return
                        }
                        else if (field === "DateOfBirth" && value !== undefined) {

                          const fecha = new Date(value);
                          if ((new Date().getFullYear() - fecha.getFullYear()) > 18) {
                            setData((data) => ({ ...data, [field]: value }));
                          }
                          else if ((new Date().getFullYear() - fecha.getFullYear()) === 18) {
                            if (new Date().getMonth() > fecha.getMonth()) {
                              setData((data) => ({ ...data, [field]: value }));
                            }
                            else if (new Date().getMonth() === fecha.getMonth()) {
                              if (new Date().getDate() >= fecha.getDate()) {
                                setData((data) => ({ ...data, [field]: value }));
                              } else {
                                if (document.querySelector('.alert') !== null) {
                                  document.querySelector('.alert').remove()
                                  clearTimeout(showMessage('danger'))
                                } else {
                                  showMessage('danger')
                                }
                              }
                            } else {
                              if (document.querySelector('.alert') !== null) {
                                document.querySelector('.alert').remove()
                                clearTimeout(showMessage('danger'))
                              } else {
                                showMessage('danger')
                              }
                            }

                          }
                          else {
                            if (document.querySelector('.alert') !== null) {
                              document.querySelector('.alert').remove()
                              clearTimeout(showMessage('danger'))
                            } else {
                              showMessage('danger')
                            }
                          }

                        } else {
                          setData((data) => ({ ...data, [field]: value }));
                          if (field === "SwitchMoveType") {
                            setMoveType(value)
                          }
                          if ((field === "SwitchType" && value === "Self-Selected Switch")
                            || (field === "SwitchSubType" && value === "Self-Selected Switch")
                            || (field === "SwitchSubType" && value === "switchDate")
                            || (field === "SwitchSubType" && value === "forward_switch")
                            || (field === "SwitchSubType" && value === "SwitchMoveDate")) {
                            setConstelDayValidate(true)
                          } else if ((field === "SwitchMoveType" && value === "Moving")
                            || (field === "SwitchMoveType" && value === "Move-In")
                            || (field === "SwitchMoveType" && value === "move")
                            || (field === "SwitchMoveType" && value === "MoveIn")) {
                            setConstelDayValidate(false)
                          }
                        }
                      }}
                      removeKey={(keyToRemove) => {
                        delete data[keyToRemove]
                        setData(data)
                      }}
                      selectedClass={price.class}
                    />
                    <Button
                      type="submit"
                      className="wizard-footer d-flex ml-auto"
                    >
                      Next
                    </Button>
                  </Form>
                </div>
              </BusyIndicator>
            </div>
          </Col>
        </Row>
      </Card>
    </div>
  );
};

export default Enrollment;
