export const usStates = [
  "Alaska",
  "Alabama",
  "Arkansas",
  "American Samoa",
  "Arizona",
  "California",
  "Colorado",
  "Connecticut",
  "District of Columbia",
  "Delaware",
  "Florida",
  "Georgia",
  "Guam",
  "Hawaii",
  "Iowa",
  "Idaho",
  "Illinois",
  "Indiana",
  "Kansas",
  "Kentucky",
  "Louisiana",
  "Massachusetts",
  "Maryland",
  "Maine",
  "Michigan",
  "Minnesota",
  "Missouri",
  "Mississippi",
  "Montana",
  "North Carolina",
  " North Dakota",
  "Nebraska",
  "New Hampshire",
  "New Jersey",
  "New Mexico",
  "Nevada",
  "New York",
  "Ohio",
  "Oklahoma",
  "Oregon",
  "Pennsylvania",
  "Puerto Rico",
  "Rhode Island",
  "South Carolina",
  "South Dakota",
  "Tennessee",
  "Texas",
  "Utah",
  "Virginia",
  "Virgin Islands",
  "Vermont",
  "Washington",
  "Wisconsin",
  "West Virginia",
  "Wyoming",
];

export const ENEREX_STATES = ["texas", "ohio", "illinois", "maryland", "new jersey", "pennsylvania"];
export const ENEREX_STATESFORPRICE = ["texas"];
export const SUPPLIERIDs = { "FlAGSHIPPOWER" : "252023dd-af76-4daa-8d8b-ceb2a60b6a39"};

export const unit={
  ELECTRIC:'kWh',
  NAT_GAS:'thm'
}


export const customDateDropdownMessage = "Select a Date";

export const MESSAGE_ON_EMPTY_RESULT =
  "We don't have online offerings for your search criteria but you can contact us for custom pricing";

export const SUCCESS_WITH_ENROLLMENT =
  "Congratulations!  Your enrollment has successfully been completed with your supplier of choice and you will be getting an email confirmation shortly.  If you have any questions - don't hesitate to call us at [999-999-9999].";

export const FAILED_WITH_ENROLLMENT =
  "Unfortunately we were not able to complete your enrollment as we ran into an issue.  Don't worry!  Your information is saved in our database.  Please call us as a next step so we can look into the issue and discuss it with you to complete your enrollment.  Our phone number is [999-999-9999]";

export const SUCCESS_WITHOUT_ENROLLMENT =
  "Just one step left!  Check your email inbox!  You will be receiving an email shortly with your Retail Supply Contract. Please review and sign to complete your enrollment process. Call me at [broker phone number] if you have any questions.]";

export const SUCCESS_WITH_CUSTOM_PRICING =
  "Thank you!  Your information has been created in our system and one of our esteemed Energy Managers will reach out to you shortly to start your RFP process.  If you ever need to reach us - you can email us at [broker email address] or call us at [broker phone number]";
export const SIMPLE_FIELD = [
  {
    type: "field",
    name: "SocialSecurityNumber",
    dataType: "String",
    friendlyName: "Social security number",
    friendlyDescription:
      "Please enter your social security number in order to validate it",
    required: true,
  },
  {
    type: "field",
    name: "SMS",
    dataType: "Boolean",
    friendlyName: "SMS",
    friendlyDescription: "",
  },
  {
    type: "field",
    name: "EmailConsent",
    dataType: "Boolean",
    friendlyName: "EmailConsent",
    friendlyDescription: "",
  },
  {
    type: "field",
    name: "PaperlessBilling",
    dataType: "Boolean",
    friendlyName: "PaperlessBilling",
    friendlyDescription: "",
  },
  {
    type: "field",
    name: "AutoPay",
    dataType: "Boolean",
    friendlyName: "AutoPay",
    friendlyDescription: "",
  },
];

export const COMPLEX_FIELD = [
  {
    type: "field",
    name: "SocialSecurityNumber",
    dataType: "String",
    friendlyName: "Social security number",
    friendlyDescription:
      "Please enter your social security number in order to validate it",
    required: true,
  },
  {
    type: "field",
    name: "BillDeliever",
    dataType: "List",
    friendlyName: "Bill Delivery Method",
    friendlyDescription: "Please choose how you want to get your bill",
    values: [
      {
        name: "Email",
        value: "Email",
      },
      {
        name: "Mail",
        value: "Mail",
      },
      {
        name: "Email and Mail",
        value: "Email and Mail",
      },
    ],
  },
  {
    type: "fieldGroup",
    friendlyName: "Automatic Payments",
    fields: [
      {
        type: "field",
        name: "AutoPay",
        dataType: "Boolean",
        friendlyName: "AutoPay",
        friendlyDescription: "Do you want to automatically pay your bills?",
      },
      {
        type: "field",
        name: "AutopayType",
        dataType: "List",
        friendlyName: "Type",
        values: [
          {
            name: "Credit Card",
            value: "Credit Card",
          },
          {
            name: "E-Check",
            value: "E-Check",
          },
        ],
        conditionalVisibilityOnField: {
          name: "AutoPay",
          valueWhenShown: true,
        },
        required: true,
      },
      {
        type: "fieldGroup",
        friendlyName: "E-Check Information",
        conditionalVisibilityOnField: [
          {
            name: "AutopayType",
            valueWhenShown: "E-Check",
          },
          {
            name: "AutoPay",
            valueWhenShown: true,
          },
        ],
        fields: [
          {
            type: "field",
            name: "AccountType",
            dataType: "List",
            friendlyName: "Account Type",
            values: [
              {
                name: "Savings",
                value: "Savings",
              },
              {
                name: "Personal Checking",
                value: "Personal Checking",
              },
            ],
          },
          {
            type: "field",
            name: "NameOnAccount",
            dataType: "String",
            friendlyName: "Name on account",
            required: true,
          },
          {
            type: "field",
            name: "RoutingNumber",
            dataType: "String",
            friendlyName: "Routing number",
            required: true,
          },
          {
            type: "field",
            name: "PaymentAccountNumber",
            dataType: "String",
            friendlyName: "Account number",
            required: true,
          },
        ],
      },
      {
        type: "fieldGroup",
        friendlyName: "Credit Card Information",
        conditionalVisibilityOnField: [
          {
            name: "AutopayType",
            valueWhenShown: "Credit Card",
          },
          {
            name: "AutoPay",
            valueWhenShown: true,
          },
        ],
        fields: [
          {
            type: "field",
            name: "CreditCardName",
            dataType: "String",
            friendlyName: "Name",
            required: true,
          },
          {
            type: "field",
            name: "CreditCardNumber",
            dataType: "Number",
            friendlyName: "Number",
            required: true,
          },
          {
            type: "field",
            name: "CreditCardExpirationMonth",
            dataType: "String",
            friendlyName: "Expiration Month ",
            required: true,
          },
          {
            type: "field",
            name: "CreditCardExpirationYear",
            dataType: "Number",
            friendlyName: "Expiration Year",
            required: true,
          },
          {
            type: "field",
            name: "CreditCardExpirationCVC",
            dataType: "Number",
            friendlyName: "CVC",
            required: true,
          },
          {
            type: "field",
            name: "CreditCardBilllingAddressTypeID",
            dataType: "Boolean",
            friendlyName:
              "Use billing address as the address on file for this card?",
            required: true,
          },
          {
            type: "fieldGroup",
            friendlyName: "Billing Address",
            conditionalVisibilityOnField: {
              name: "CreditCardBilllingAddressTypeID",
              valueWhenShown: false,
            },
            fields: [
              {
                type: "field",
                name: "CreditCardAddress",
                dataType: "String",
                friendlyName: "Billing Address",
                required: true,
              },
              {
                type: "field",
                name: "CreditCardCity",
                dataType: "String",
                friendlyName: "City",
                required: true,
              },
              {
                type: "field",
                name: "CreditCardState",
                dataType: "KnownList:States",
                friendlyName: "State",
                required: true,
              },
              {
                type: "field",
                name: "CreditCardZipCode",
                dataType: "Number",
                friendlyName: "Zip Code",
                required: true,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    type: "fieldGroup",
    friendlyName: "Deposit",
    fields: [
      {
        type: "field",
        name: "PayDeposit",
        dataType: "Boolean",
        friendlyName: "Are you paying a deposit?",
      },
      {
        type: "fieldGroup",
        friendlyName: "Deposit Information",
        conditionalVisibilityOnField: {
          name: "PayDeposit",
          valueWhenShown: true,
        },
        fields: [
          {
            type: "field",
            name: "Deposit Card Number",
            dataType: "Number",
            friendlyName: "Number",
            required: true,
          },
          {
            type: "field",
            name: "Deposit Card Expiration Month",
            dataType: "String",
            friendlyName: "Expiration Month ",
            required: true,
          },
          {
            type: "field",
            name: "Deposit Card Expiration Year",
            dataType: "Number",
            friendlyName: "Expiration Year",
            required: true,
          },
          {
            type: "field",
            name: "Deposit Card Expiration CVC",
            dataType: "Number",
            friendlyName: "CVC",
            required: true,
          },
          {
            type: "field",
            name: "Deposit Billing Address",
            dataType: "String",
            friendlyName: "Billing Address",
            required: true,
          },
          {
            type: "field",
            name: "Deposit City",
            dataType: "String",
            friendlyName: "City",
            required: true,
          },
          {
            type: "field",
            name: "Deposit State",
            dataType: "KnownList:States",
            friendlyName: "State",
            required: true,
          },
          {
            type: "field",
            name: "Deposit Zip Code",
            dataType: "Number",
            friendlyName: "Zip Code",
            required: true,
          },
        ],
      },
      {
        type: "fieldGroup",
        friendlyName: "Waiver Information",
        conditionalVisibilityOnField: {
          name: "PayDeposit",
          valueWhenShown: false,
        },
        fields: [
          {
            type: "field",
            name: "WavierReason",
            dataType: "List",
            friendlyName: "What's the reason you are not paying a deposit?",
            values: [
              {
                name: "I can provide 12 months of good payment history",
                value: "goodPaymentHistory",
              },
              {
                name: "Senior Citizends in Good Standing",
                value: "seniorCitizens",
              },
              {
                name: "Texas Family Code §71.004",
                value: "texasSpecialZipCode",
              },
              {
                name: "Medically Indigent",
                value: "indigent",
              },
            ],
          },
          {
            type: "field",
            name: "SendViaEmail",
            dataType: "Boolean",
            friendlyName: "Would you like to send the waiver proof via email?",
            defaultValue: true,
          },
        ],
      },
    ],
  },
];
